import * as types from '@/store/mutationTypes';

export default {
  [types.SET_API_VERSION](state, apiVersion) {
    state.apiVersion = apiVersion;
  },
  setStbAxios(state, stbAxios) {
    state.stbAxios = stbAxios;
  },
  // @TOREVIEW: temporary solution to avoid showing error messages from axios interceptors
  setSilentAxiosError(state, silentMode) {
    state.silentAxiosError = silentMode;
  }
};
