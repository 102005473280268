<template>
  <div
    v-if="selectedriskClasses.length > 0"
    class="sub-category-section-margin sub-category-section-not-provider"
  >
    <div
      v-for="(riskType, index) in selectedriskClasses[0].types"
      :key="index"
      class="sub-category-section"
    >
      <v-layout align-center justify-space-between v-if="riskType.classes.length > 0">
        <v-flex xs6 v-if="riskTypeLabelDisplayed">
          <div class="name label-padding">{{ riskType.name }}</div>
        </v-flex>
        <v-flex v-if="selectedriskClasses[0].name === 'Activities'">
          <complete-checklist
            :categoryName="riskType.name"
            :riskClassName="selectedriskClasses[0].name"
            :formControlEnabled="formControlEnabled"
            :checklistFetchActionInProgress="false"
          />
          <view-policy :categoryName="riskType.name" :riskClassName="selectedriskClasses[0].name" />
        </v-flex>
      </v-layout>
      <v-layout justify-center v-for="(selectedRiskClass, index) in riskType.classes" :key="index">
        <v-select
          :id="getRiskClassId(selectedRiskClass)"
          class="type-dropdown risk-class-select excursion-selection"
          :items="
            listRiskClasses(selectedRiskClass.name, riskType.name, selectedriskClasses[0].name)
          "
          item-text="name"
          item-value="name"
          item-disabled="disabled"
          solo
          :value="selectedRiskClass.name"
          @click="showWarning"
          @change="
            changeSelectedClassId(
              riskType.name,
              selectedriskClasses[0].name,
              selectedRiskClass,
              $event
            )
          "
          :disabled="
            !selectedClassExistInItems(
              selectedRiskClass.name,
              riskType.name,
              selectedriskClasses[0].name
            ) || !formControlEnabled
          "
        >
          <template slot="item" slot-scope="data">
            <div :id="`risk-class--option-${String(data.item.name).replaceAll(' ', '')}`">
              {{ data.item.name }}
            </div>
          </template>
        </v-select>
        <div>
          <v-btn
            :id="getRiskClassDeleteId(selectedRiskClass)"
            class="remove-icon"
            icon
            :disabled="!formControlEnabled"
            @click.native="
              openRemoveSelectedClassDialog(
                riskType.name,
                selectedriskClasses[0].name,
                selectedRiskClass.name
              )
            "
          >
            <v-icon>delete</v-icon>
          </v-btn>
          <cs-form-dialog
            v-model="selectedRiskClass.dialog"
            id="dlg-selected-risk-class"
            bodyId="dlg-body-selected-risk-class"
            heading="Delete Risk"
            :primaryAction="{
              label: 'Delete',
              eventName: 'primary-click',
              disabled: !formControlEnabled
            }"
            @primary-click="
              removeSelectedClassId(riskType.name, selectedriskClasses[0].name, selectedRiskClass)
            "
            :secondaryAction1="{
              label: 'Cancel',
              eventName: 'secondary1-click'
            }"
            @secondary1-click="
              closeRemoveSelectedClassDialog(
                riskType.name,
                selectedriskClasses[0].name,
                selectedRiskClass.name
              )
            "
            @input="
              closeRemoveSelectedClassDialog(
                riskType.name,
                selectedriskClasses[0].name,
                selectedRiskClass.name
              )
            "
          >
            <template v-slot:cs-form-dialog-content>
              <v-card>
                <v-card-text v-if="isSelectedRiskClassHighRiskMedicalCondition"
                  >This action may populate a new checklist. Do you want to continue?
                </v-card-text>
                <v-card-text v-else>Are you sure?</v-card-text>
              </v-card>
            </template>
          </cs-form-dialog>
        </div>
      </v-layout>
    </div>
    <cs-form-dialog
      v-model="warningUpdateRiskClassDialogDisplayed"
      id="dlg-update-risk-class-warning"
      bodyId="dlg-body-update-risk-class-warning"
      heading="Update Student Medical Condition"
      :primaryAction="{
        label: 'Confirm',
        eventName: 'primary-click'
      }"
      @primary-click="closeWarningDialog"
      :secondaryAction1="{
        label: 'Cancel',
        eventName: 'secondary1-click'
      }"
      @secondary1-click="warningUpdateRiskClassDialogDisplayed = false"
    >
      <template v-slot:cs-form-dialog-content>
        <v-card>
          <v-card-text>
            This action may populate a new checklist. Do you want to continue?
          </v-card-text>
        </v-card>
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
import riskCategoryLabel from '@/lib/const/riskCategoryLabel';

export default {
  props: [
    'selectedriskClasses',
    'formControlEnabled',
    'riskTypeLabelDisplayed',
    'hasUpdatedMedicalCondition'
  ],
  data() {
    return {
      warningUpdateRiskClassDialogDisplayed: false
    };
  },
  computed: {
    placeholder() {
      return `Add ${this.selectedriskClasses[0].name} Type`;
    },
    allRisks() {
      return this.$store.state.common.allRisks;
    },
    selectedRisks() {
      const { categories } = this.$store.state.common.excursionDc;
      if (categories) {
        return this.$store.state.common.excursionDc.categories;
      }
      return [];
    },
    isSelectedRiskClassHighRiskMedicalCondition() {
      return (
        this.selectedriskClasses?.[0].name ===
        riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS
      );
    }
  },
  methods: {
    showWarning(event) {
      if (!this.hasUpdatedMedicalCondition && this.isSelectedRiskClassHighRiskMedicalCondition) {
        event.stopPropagation();
        this.warningUpdateRiskClassDialogDisplayed = true;
      }
    },
    closeWarningDialog() {
      this.$emit('hasUpdatedMedicalCondition', true);
      this.warningUpdateRiskClassDialogDisplayed = false;
    },
    listRiskClasses(selectedClassName, typeName, categoryName) {
      let classes = [];
      Object.keys(this.allRisks).forEach((categoryKey) => {
        if (this.allRisks[categoryKey].name === categoryName) {
          Object.keys(this.allRisks[categoryKey].types).forEach((typeKey) => {
            if (this.allRisks[categoryKey].types[typeKey].name === typeName) {
              classes = this.allRisks[categoryKey].types[typeKey].classes;
            }
          });
        }
      });
      if (selectedClassName) {
        if (classes.length === 0 || classes.findIndex((c) => c.name === selectedClassName) === -1) {
          return [{ name: selectedClassName }];
        }
      }
      return classes;
    },
    getClassFromAllRisks(typeName, categoryName, className) {
      let foundClass;
      Object.keys(this.allRisks).forEach((categoryKey) => {
        if (this.allRisks[categoryKey].name === categoryName) {
          Object.keys(this.allRisks[categoryKey].types).forEach((typeKey) => {
            if (this.allRisks[categoryKey].types[typeKey].name === typeName) {
              foundClass = this.allRisks[categoryKey].types[typeKey].classes.find(
                (c) => c.name === className
              );
            }
          });
        }
      });
      return foundClass;
    },
    getRiskClassId(riskClass) {
      return `risk-class--${riskClass.name.replace(/\s/g, '')}`;
    },
    getRiskClassDeleteId(riskClass) {
      return `risk-class--delete-${riskClass.name.replace(/\s/g, '')}`;
    },
    changeSelectedClassId(typeName, categoryName, oldRiskClass, newRiskClassName) {
      this.$store.dispatch('common/setRiskClassDisabled', {
        typeName,
        categoryName,
        className: newRiskClassName,
        disabled: true
      });
      this.$store.dispatch('common/setRiskClassDisabled', {
        typeName,
        categoryName,
        className: oldRiskClass.name,
        disabled: false
      });
      this.$store.dispatch('common/setExcursionSelectedCategoryTypeClass', {
        typeName,
        categoryName,
        oldClassName: oldRiskClass.name,
        newClassName: newRiskClassName
      });
      this.$emit('changeRiskCategoryDone');
    },
    openRemoveSelectedClassDialog(typeName, categoryName, className) {
      this.$store.dispatch('common/setExcursionSelectedCategoryTypeClassBoolDialog', {
        categoryName,
        typeName,
        className,
        boolDialog: true
      });
    },
    closeRemoveSelectedClassDialog(typeName, categoryName, className) {
      this.$store.dispatch('common/setExcursionSelectedCategoryTypeClassBoolDialog', {
        categoryName,
        typeName,
        className,
        boolDialog: false
      });
    },
    removeSelectedClassId(typeName, categoryName, selectedRiskClass) {
      if (!selectedRiskClass.dialog) {
        return false;
      }
      this.$store.dispatch('common/setRiskClassDisabled', {
        typeName,
        categoryName,
        className: selectedRiskClass.name,
        disabled: false
      });
      this.$store.dispatch('common/deleteExcursionSelectedCategoryTypeClass', {
        categoryName,
        typeName,
        className: selectedRiskClass.name
      });
      this.$emit('changeRiskCategoryDone');
      return true;
    },
    selectedClassExistInItems(selectedClassName, typeName, categoryName) {
      const items = this.listRiskClasses('', typeName, categoryName);
      if (items.findIndex((i) => i.name === selectedClassName) > -1) {
        return true;
      }
      return false;
    }
  }
};
</script>
