import stbAPIGateway from '../../../../services/stbAPIGateway';

// eslint-disable-next-line import/prefer-default-export
export const deleteRiskCaches = async ({ rootGetters }, contentEngine) => {
  const axios = rootGetters['app/stbAxios'];

  const stbApi = await stbAPIGateway(axios, process.env.API_URL);
  const response = await stbApi.deleteRiskCaches(contentEngine);

  return response;
};
