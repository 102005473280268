export default (axios, baseURL) => async (clientSid) => {
  const url = `/v1/clients/${encodeURIComponent(clientSid)}/content-engine`;

  const axiosConfig = {
    method: 'GET',
    baseURL,
    url
  };
  const axiosResponse = await axios(axiosConfig);

  if (!axiosResponse || !axiosResponse.data || typeof axiosResponse.data !== 'object') {
    throw new Error('Invalid response from gateway');
  }
  return axiosResponse.data;
};
