import { render, staticRenderFns } from "./ClientAdminGroupSetting.vue?vue&type=template&id=db902876&scoped=true"
import script from "./ClientAdminGroupSetting.vue?vue&type=script&lang=js"
export * from "./ClientAdminGroupSetting.vue?vue&type=script&lang=js"
import style0 from "./ClientAdminGroupSetting.vue?vue&type=style&index=0&id=db902876&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "db902876",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCardText,VContainer,VFlex,VForm,VLayout,VSpacer})
