<template>
  <v-card flat class="tab-card-client">
    <cs-button
      @click="displayClientIndividualEditor('new', '')"
      id="newClient"
      class="create-new-client-button"
      label="Create New Client"
      primary
    ></cs-button>
    <v-layout class="search">
      <v-flex xs2 class="filter-by">FILTER BY:</v-flex>
      <v-flex xs4>
        <v-text-field
          v-model="searchClientKeyword"
          label="Search"
          append-icon="search"
          class="excursion-filter-search excursion-input"
          single-line
          type="text"
          id="clientSearch"
          @click:append="getClientFilterData(true)"
          @keyup.enter="getClientFilterData(true)"
        ></v-text-field>
      </v-flex>
    </v-layout>
    <v-data-table
      :headers="clientHeaders"
      :items="clientList"
      :options.sync="pagination"
      class="elevation-2 excursion-table"
      :footer-props="footerProps"
      :server-items-length="clientListTotal"
      :loading="loading"
      no-data-text="No client was found"
    >
      <template slot="item" slot-scope="props">
        <tr>
          <td class="excursion-name-cell">
            <a
              @click="displayClientIndividualEditor('update', props.item.sid)"
              class="client-id-label"
              id="updateClient"
              >{{ props.item.sid }}</a
            >
          </td>
          <td class="text-xs-left">{{ props.item.metadata.name }}</td>
          <td class="text-xs-left">
            {{
              props.item.parent_client_metadata && props.item.parent_client_metadata.name
                ? props.item.parent_client_metadata.name
                : ''
            }}
          </td>
          <td class="text-xs-left">
            <v-tooltip left color="#ffffff">
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  :id="getClientEnableButtonId(props.item.sid)"
                  v-on="{ ...tooltip }"
                  icon
                  @click.native="
                    displayClientEnabledConfirmationDialog(
                      props.item.sid,
                      props.item.enabled,
                      props.item.metadata.name
                    )
                  "
                >
                  <i v-if="props.item.enabled" class="fas fa-check action-tooltip-icon"></i>
                  <i v-if="!props.item.enabled" class="fas fa-ban action-tooltip-icon"></i>
                </v-btn>
              </template>
              <span v-if="props.item.enabled" class="action-tooltip-content">Disable</span>
              <span v-if="!props.item.enabled" class="action-tooltip-content">Enable</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <cs-form-dialog
      v-model="clientEnablementConfirmationDialogDisplayed"
      id="dlg-enable-client-confirmation"
      bodyId="dlg-body-enable-client-confirmation"
      :heading="clientEnablementConfirmationDialogHeading"
      :primaryAction="{
        label: clientEnablementConfirmationDialogButtonText,
        eventName: 'primary-click'
      }"
      @primary-click="confirmClientEnabledToggle"
      :secondaryAction1="{
        label: 'Cancel',
        eventName: 'secondary1-click'
      }"
      @secondary1-click="clientEnablementConfirmationDialogDisplayed = false"
    >
      <template v-slot:cs-form-dialog-content>
        <v-card>
          <v-card-text>
            {{ clientEnablementConfirmationDialogContent }}
          </v-card-text>
        </v-card>
      </template>
    </cs-form-dialog>
  </v-card>
</template>

<script>
import * as types from '@/store/mutationTypes';

import clientTableColumn from '../lib/const/clientTableColumn';

export default {
  props: [],
  data() {
    return {
      clientEnablementConfirmationDialogDisplayed: false,
      clientEnablementConfirmationDialogContent: '',
      clientEnablementConfirmationDialogButtonText: '',
      clientEnablementConfirmationDialogClientSid: '',
      clientEnablementConfirmationDialogEnabled: true,
      querystringRefToTableColumnRefMap: {},
      tableColumnRefToQuerystringRefMap: {},
      search: '',
      footerProps: {
        'items-per-page-options': [10, 30, 50]
      },
      pagination: {
        itemsPerPage: 30,
        page: 1,
        sortDesc: [false],
        sortBy: ['']
      },
      clientHeaders: [
        {
          text: clientTableColumn.CLIENT_ID.label,
          value: clientTableColumn.CLIENT_ID.tableColumnRef,
          class: 'header'
        },
        {
          text: clientTableColumn.CLIENT_NAME.label,
          value: clientTableColumn.CLIENT_NAME.tableColumnRef,
          class: 'header'
        },
        {
          text: clientTableColumn.ORGANISATION_NAME.label,
          value: clientTableColumn.ORGANISATION_NAME.tableColumnRef,
          class: 'header',
          sortable: false
        },
        {
          text: clientTableColumn.ACTION.label,
          value: clientTableColumn.ACTION.tableColumnRef,
          class: 'header'
        }
      ],
      client: {},
      searchClientKeyword: '',
      clientModalType: 'new',
      clientModalClientSid: '',
      clientIndividualModalRefreshed: true
    };
  },
  methods: {
    async getClientFilterData(pageResetTriggered = false) {
      if (pageResetTriggered) {
        this.resetPage();
      }
      this.$store.commit(types.COMMON_SET_CLIENT_LIST_LOADING, true);
      const filterParam = {
        s: this.searchClientKeyword,
        page: this.pagination.page,
        length: this.pagination.itemsPerPage
      };

      Object.keys(filterParam).forEach((key) => {
        if (filterParam[key] === '') {
          delete filterParam[key];
        }
      });
      const store = this.$store;
      const argHash = {
        stbApiAdapter: this.$app.stbApiAdapter,
        filterParam,
        store
      };

      const searchQuery = JSON.parse(JSON.stringify(filterParam));

      if (this.tableColumnRefToQuerystringRefMap[this.pagination.sortBy[0]]) {
        const [firstSortBy] = this.pagination.sortBy;
        filterParam.order = firstSortBy;
        filterParam.direction = this.pagination.sortDesc[0] === false ? 'ASC' : 'DESC';
        searchQuery.order = this.tableColumnRefToQuerystringRefMap[this.pagination.sortBy[0]];
        searchQuery.direction = filterParam.direction;
      }

      await this.$store.dispatch('common/getClientList', argHash);
      this.$router.replace({ query: searchQuery }, () => {});
    },
    async confirmClientEnabledToggle() {
      const store = this.$store;
      const requestParam = {
        enabled: this.clientEnablementConfirmationDialogEnabled
      };
      const argHash = {
        sid: this.clientEnablementConfirmationDialogClientSid,
        payload: requestParam,
        store: this.$store
      };

      await this.$app.stbApiAdapter.putClientEnabled(argHash);

      store.dispatch(
        'common/toggleClientEnabled',
        this.clientEnablementConfirmationDialogClientSid
      );

      this.clientEnablementConfirmationDialogDisplayed = false;
    },
    async displayClientIndividualEditor(clientRecordExistenceType, clientSid) {
      const { axiosInstance, eventPluginCommonAdapter, stbApiAdapter } = this.$app;
      await this.$store.dispatch('authorisation/validateAndSetUserTokenOrLogout', {
        query: this.$route.query,
        axiosInstance
      });
      await this.$store.dispatch('common/initClientConfig', {
        eventPluginCommonAdapter,
        stbApiAdapter
      });
      if (clientRecordExistenceType === 'new') {
        window.location.href = '/admin/clients/new';
      } else {
        window.location.href = `/admin/clients/${clientSid}`;
      }
    },
    displayClientEnabledConfirmationDialog(sid, enabled, clientName) {
      if (enabled) {
        this.clientEnablementConfirmationDialogContent = `You are disabling the client: ${clientName}, would you like to continue?`;
        this.clientEnablementConfirmationDialogButtonText = 'Disable';
      } else {
        this.clientEnablementConfirmationDialogContent = `You are about to enable ${clientName} as a client, would you like to continue?`;
        this.clientEnablementConfirmationDialogButtonText = 'Enable';
      }
      this.clientEnablementConfirmationDialogClientSid = sid;
      this.clientEnablementConfirmationDialogDisplayed = true;
      this.clientEnablementConfirmationDialogEnabled = !enabled;
    },
    getClientEnableButtonId(sid) {
      return `client-list--client-enablement-confirmation-dialog-${sid}`;
    },
    resetPage() {
      this.pagination.itemsPerPage = 30;
      this.pagination.page = 1;
      this.pagination.sortDesc[0] = true;
    }
  },
  computed: {
    clientList() {
      return this.$store.state.common.clientList.data;
    },
    loading() {
      return this.$store.state.common.clientList.loading;
    },
    clientListTotal() {
      return this.$store.state.common.clientList.total;
    },
    clientEnablementConfirmationDialogHeading() {
      return `${this.clientEnablementConfirmationDialogButtonText} Client`;
    }
  },
  mounted() {
    Object.keys(clientTableColumn).forEach((key) => {
      this.querystringRefToTableColumnRefMap[clientTableColumn[key].querystringRef] =
        clientTableColumn[key].tableColumnRef;
      this.tableColumnRefToQuerystringRefMap[clientTableColumn[key].tableColumnRef] =
        clientTableColumn[key].querystringRef;
    });

    if (Object.keys(this.$route.query).length !== 0) {
      const {
        s: parsedSearchClientKeyword,
        page: parsedSearchPage,
        length: parsedSearchLength,
        direction: parsedSearchDirection,
        order: parsedSortBy
      } = this.$route.query;
      if (parsedSearchClientKeyword && String(parsedSearchClientKeyword).trim().length !== 0) {
        this.searchClientKeyword = parsedSearchClientKeyword;
      }
      if (
        parsedSearchLength === '10' ||
        parsedSearchLength === '30' ||
        parsedSearchLength === '50'
      ) {
        this.pagination.itemsPerPage = parseInt(parsedSearchLength, 10);
      } else {
        this.pagination.itemsPerPage = 30;
      }
      if (!Number.isNaN(parseInt(parsedSearchPage, 10)) && parseInt(parsedSearchPage, 10) > 0) {
        this.pagination.page = parseInt(parsedSearchPage, 10);
      }
      if (
        clientTableColumn.CLIENT_ID.querystringRef === parsedSortBy ||
        clientTableColumn.CLIENT_NAME.querystringRef === parsedSortBy ||
        clientTableColumn.ORGANISATION_NAME.querystringRef === parsedSortBy ||
        clientTableColumn.ACTION.querystringRef === parsedSortBy
      ) {
        this.pagination.sortBy[0] = this.querystringRefToTableColumnRefMap[parsedSortBy];
        if (parsedSearchDirection === 'DESC' || parsedSearchDirection === 'ASC') {
          this.pagination.sortDesc[0] = parsedSearchDirection === 'DESC';
        }
      }
    }
  },
  watch: {
    pagination: {
      handler() {
        this.getClientFilterData();
      }
    }
  }
};
</script>
