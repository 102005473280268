export const validateSubdomain = (value) => {
  if (!value || (value && value.length === 0)) {
    return true;
  }
  const regex = /^[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})*$/;
  const subdomainValid = regex.test(value);
  return subdomainValid;
};

export default { validateSubdomain };
