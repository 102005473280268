import { validatePrivilege } from '../../../../helpers/validatePrivilege';
import aclPrivilege from '../../../../lib/const/aclPrivilege';

// eslint-disable-next-line import/prefer-default-export
export const configurePrivileges = async (
  { commit },
  { systemAcl, clientAcl, parentClientAcl }
) => {
  const enabled = validatePrivilege(
    systemAcl,
    clientAcl,
    parentClientAcl,
    aclPrivilege.DELETE_RISK_CONTENT_CACHE
  );
  commit('setClearModuleCacheEnabled', enabled);
};
