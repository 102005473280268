const aclDomain = require('../lib/const/aclDomain');

// @TOREVIEW to decide where to put this function
const validatePrivilege = (systemAcl, clientAcl, parentClientAcl, item) => {
  let privilegeValid = false;
  if (
    (systemAcl && systemAcl[aclDomain.ANY] && systemAcl[aclDomain.ANY][item]) ||
    (clientAcl && clientAcl[aclDomain.SELF] && clientAcl[aclDomain.SELF][item]) ||
    (parentClientAcl && parentClientAcl[aclDomain.SELF] && parentClientAcl[aclDomain.SELF][item])
  ) {
    privilegeValid = true;
  }

  return privilegeValid;
};

module.exports = { validatePrivilege };
