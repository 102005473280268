<template>
  <div class="client-general">
    <v-row>
      <v-col xs12>
        <div>ce-api-uri <span class="red--text">*</span></div>
        <v-select
          class="type-dropdown excursion-selection"
          item-text="ceApiUri"
          :items="ceEnvironments"
          :return-object="true"
          solo
          v-model="ceEnvironmentSelection"
          placeholder="Select Content Engine environment"
          @change="onChangeCeApiUri"
          clearable
          id="ce-environment-selection"
          required
          :error-messages="ceApiUriErrors"
          @blur="$v.ceEnvironmentSelection.$touch()"
        >
          <template slot="item" slot-scope="data">
            <dir
              :id="`client-individual-modal--ce-api-uri--option-${
                String(data.item.ceApiUri).split('//')[1].split('.')[0]
              }`"
            >
              {{ data.item.ceApiUri }}
            </dir>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col xs12>
        <div>x-site-uri <span class="red--text">*</span></div>
        <v-text-field
          solo
          v-model="contentEngine.xSiteUri"
          placeholder="x-site-uri"
          class="name-textbox excursion-input"
          id="txtXSiteUri"
          required
          :error-messages="ceXSiteUriErrors"
          @keyup="autoPopulatexSiteUrl()"
          @input="onContentEngineChanged"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col xs12>
        <div>module-name <span class="red--text">*</span></div>
        <v-text-field
          solo
          v-model="contentEngine.moduleName"
          placeholder="module-name"
          class="name-textbox excursion-input"
          id="txtModuleName"
          required
          :error-messages="ceModuleNameErrors"
          @input="onContentEngineChanged"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col xs12 class="d-flex justify-end align-center">
        <cs-button
          v-if="clearModuleCacheEnabled"
          @click="prevDeleteCacheDialog = true"
          primary
          class="delete-module-cache"
          :disabled="clearModuleCacheButtonDisabled"
          id="btnPrevDeleteCache"
          label="Clear Module Cache"
        ></cs-button>
      </v-col>
    </v-row>
    <cs-form-dialog
      v-model="prevDeleteCacheDialog"
      id="dlg-client-content-engine-clear-cache"
      bodyId="dlg-client-content-engine-clear-cache-body"
      heading="Client Individual Editor"
      :primaryAction="{
        label: 'Yes',
        eventName: 'primary-click',
        loading: cacheClearActionInProgress
      }"
      @primary-click="deleteRiskCaches"
      :secondaryAction1="{ label: 'Cancel', eventName: 'secondary1-click' }"
      @secondary1-click="prevDeleteCacheDialog = false"
    >
      <template v-slot:cs-form-dialog-content>
        Clearing the cache may affect other clients using the same module. Are you sure you want to
        clear the cache?
      </template>
    </cs-form-dialog>
    <cs-form-dialog
      v-model="deleteCacheResult"
      id="dlg-client-ind-editor-clear-cache-result"
      bodyId="dlg-client-ind-editor-clear-cache-result-body"
      heading="Client Individual Editor"
      :primaryAction="{
        label: 'OK',
        eventName: 'primary-click',
        loading: cacheClearActionInProgress
      }"
      @primary-click="deleteCacheResult = false"
    >
      <template v-slot:cs-form-dialog-content>
        <div v-safe-html="resultMessage"></div>
        <v-spacer></v-spacer>
        <div class="warning-message" v-safe-html="warningMessage"></div>
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { mapGetters } from 'vuex';
import * as types from '@/store/mutationTypes';

export default {
  mixins: [validationMixin],

  name: 'ClientContentEngine',

  extends: CSBase,

  props: {
    clientSid: { type: String, required: false, default: '' },
    subdomain: { type: String, required: false, default: '' },
    // @TODO: temporary here as the save action is done in the parent component
    clientSaveActionInProgress: { type: Boolean, required: false, default: false }
  },

  watch: {
    async clientSid() {
      this.initPage(this.clientSid);
    },

    clientContentEngineView() {
      this.reloadData();
    },

    contentEngine: {
      handler() {
        this.$emit('contentEngineChanged', this.contentEngine);
      },
      deep: true
    },

    deleteCacheResult(boolShown) {
      if (!boolShown) {
        this.resetDeleteCacheErrors();
      }
    }
  },

  data() {
    return {
      editMode: { type: String, required: false, default: 'create' },
      contentEngine: {
        ceApiUri: '',
        xSiteUri: '',
        moduleName: ''
      },
      ceEnvironmentSelection: {},
      prevDeleteCacheDialog: false,
      deleteCacheResult: false,
      resultMessage: '',
      warningMessage: '',
      cacheClearActionInProgress: false,
      isClientSaved: false
    };
  },

  computed: {
    ...mapGetters({
      clientContentEngineView: 'clients/clientContentEngineView',
      clearModuleCacheEnabled: 'risks/clearModuleCacheEnabled',
      ceApiUris: 'risks/ceEnvironment'
    }),

    ceEnvironments() {
      const environments = [];
      if (!this.ceApiUris) {
        return environments;
      }
      Object.keys(this.ceApiUris).forEach((key) => {
        environments.push(this.ceApiUris[key]);
      });
      return environments;
    },

    ceApiUriErrors() {
      const errors = [];
      if (!this.$v.ceEnvironmentSelection.$dirty) return errors;
      if (!this.$v.ceEnvironmentSelection.ceApiUri.required) {
        errors.push('ce-api-uri is required');
      }
      return errors;
    },

    ceXSiteUriErrors() {
      const errors = [];
      if (!this.$v.contentEngine.$dirty) return errors;
      if (!this.$v.contentEngine.xSiteUri.required) {
        errors.push('x-site-uri is required');
      }
      return errors;
    },

    ceModuleNameErrors() {
      const errors = [];
      if (!this.$v.contentEngine.$dirty) return errors;
      if (!this.$v.contentEngine.moduleName.required) {
        errors.push('module-name is required');
      }
      return errors;
    },

    clearModuleCacheButtonDisabled() {
      let disabled = false;
      if (
        !this.contentEngine ||
        !this.contentEngine.xSiteUri ||
        !this.contentEngine.moduleName ||
        this.clientSaveActionInProgress
      ) {
        disabled = true;
      }
      return disabled;
    }
  },

  validations: {
    ceEnvironmentSelection: {
      ceApiUri: {
        required
      }
    },
    contentEngine: {
      xSiteUri: {
        required
      },
      moduleName: {
        required
      }
    }
  },

  created() {},

  async mounted() {
    this.initPage(this.clientSid);
  },

  methods: {
    async initPage(clientSid) {
      if (clientSid !== '') {
        this.editMode = 'update';
        await this.fetchClientContentEngine();
        this.reloadData();
      }
      if (!this.ceApiUris || Object.keys(this.ceApiUris).length === 0) {
        await this.$store.dispatch('app/fetchCeApiUris');
      }
    },

    async fetchClientContentEngine() {
      try {
        if (this.clientSid) {
          this.setLoading(true);
          await this.$store.dispatch('clients/fetchClientContentEngine', this.clientSid);
          this.clearLoading();
        }
      } catch (e) {
        this.clearLoading();
      }
    },

    onChangeCeApiUri() {
      if (this.ceEnvironmentSelection) {
        this.contentEngine.ceApiUri = this.ceEnvironmentSelection.ceApiUri;

        this.autoPopulatexSiteUrl(true);
      } else {
        this.contentEngine.ceApiUri = '';
      }
      this.onContentEngineChanged();
    },

    autoPopulatexSiteUrl(forceChanged) {
      if (
        (forceChanged || !this.contentEngine.xSiteUri) &&
        this.subdomain &&
        this.ceEnvironmentSelection
      ) {
        const selectedCeEnvironment = this.getSelectedCeEnvironment();
        if (selectedCeEnvironment) {
          if (selectedCeEnvironment === 'production') {
            this.contentEngine.xSiteUri = `https://${this.subdomain}.app.safetripbuilder.com`;
          } else {
            this.contentEngine.xSiteUri = `https://${this.subdomain}.app-${selectedCeEnvironment}.safetripbuilder.com`;
          }
        }
        this.contentEngine = JSON.parse(JSON.stringify(this.contentEngine));
      }
    },

    getSelectedCeEnvironment() {
      let environment = '';
      if (this.ceApiUris) {
        Object.keys(this.ceApiUris).forEach((key) => {
          if (this.ceEnvironmentSelection.ceApiUri === this.ceApiUris[key].ceApiUri) {
            environment = key;
          }
        });
      }
      return environment;
    },

    onDialogOkBtn() {
      this.noticeDialogDisplayed = false;
      this.isErrorNotice = false;
    },

    reloadData() {
      this.clear();
      const { ceApiUri, xSiteUri, moduleName } = this.clientContentEngineView;
      this.contentEngine = { ceApiUri, xSiteUri, moduleName };
      this.ceEnvironmentSelection = {};
      if (ceApiUri) {
        let selectedCeApiUri = ceApiUri;
        if (ceApiUri.substring(ceApiUri.length - 1) === '/') {
          selectedCeApiUri = ceApiUri.substring(0, ceApiUri.lastIndexOf('/'));
        }
        this.ceEnvironmentSelection = {
          ceApiUri: selectedCeApiUri
        };
      }
    },

    clear() {
      this.contentEngine = {
        clientId: '',
        ceApiUri: '',
        xSiteUri: '',
        moduleName: ''
      };
    },

    onContentEngineChanged() {
      this.$v.contentEngine.$touch();
      this.emitContentEngineChanged();
    },

    emitContentEngineChanged() {
      this.$emit('contentEngineChanged', this.contentEngine);
    },

    async deleteRiskCaches() {
      this.$store.commit('app/setSilentAxiosError', true);
      try {
        this.cacheClearActionInProgress = true;
        const response = await this.$store.dispatch('risks/deleteRiskCaches', this.contentEngine);

        this.prevDeleteCacheDialog = false;
        this.cacheClearActionInProgress = false;
        if (response && !response.error && response.data && response.data.isCleared) {
          this.alertSaveResult('success', '', '');
        } else {
          this.handleDeleteRiskCachesResult(response);
        }
      } catch (e) {
        this.resetDeleteCacheErrors();
      }
    },

    alertSaveResult(status, errorMessage, warningMessage) {
      if (status === 'success' && !errorMessage) {
        this.resultMessage = 'Content Cache has successfully been cleared.';
        this.deleteCacheResult = true;
        return;
      }
      if (status === 'success' && !errorMessage) {
        this.resultMessage = errorMessage;
        this.deleteCacheResult = true;
        return;
      }

      // failed
      this.resultMessage = errorMessage || `Failed to clear cache.`;
      this.warningMessage = warningMessage || '';

      this.deleteCacheResult = true;
    },

    handleDeleteRiskCachesResult(response) {
      this.$store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
      const deleteCacheErrorMessage =
        'Oops, Content Cache cannot be cleared. (It might be cleared already or please contact support for help)';

      if (response && response.data.isCleared === false) {
        this.alertSaveResult('success', response.data.message || deleteCacheErrorMessage);
        return;
      }

      let warningMessage = '';

      if (!response.error) return;
      const error = response.data;
      try {
        const errorMessages = error.message?.split(':') || [];
        warningMessage = `errorRefId: ${error.sentinelEventSid || errorMessages[0]}`;
        this.alertSaveResult('error', `Failed to clear cache.`, warningMessage);
      } catch {
        this.resetDeleteCacheErrors();
      }
    },

    resetDeleteCacheErrors() {
      this.$store.commit('app/setSilentAxiosError', false);
      this.prevDeleteCacheDialog = false;
      this.cacheClearActionInProgress = false;
      this.warningMessage = '';
    }
  }
};
</script>
<style scoped>
.warning-message {
  font-size: 10pt;
  padding-top: 12px;
  color: var(--v-error-base);
  font-style: italic;
}
</style>
