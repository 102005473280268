import aclDomain from './const/aclDomain';
import aclPrivilege from './const/aclPrivilege';
import authScheme from './const/authScheme';

export default {
  privilegeOptionHash: {
    excursionListClientColumnDisplayed: [aclPrivilege.DISPLAY_EXCURSION_LIST_CLIENT_COLUMN],
    excursionListFilterSectionDisplayed: [aclPrivilege.DISPLAY_EXCURSION_LIST_FILTER_SECTION],
    excursionListAllClientSuggestionDisplayed: [aclPrivilege.LIST_CLIENT],
    clientAdminSectionButtonDisplayed: [aclPrivilege.DISPLAY_CLIENT_ADMIN_SECTION],
    clientsTabDisplayed: [aclPrivilege.DISPLAY_CLIENT_MANAGEMENT],
    clientUsersTabDisplayed: [aclPrivilege.DISPLAY_CLIENT_USER_MANAGEMENT],
    usersTabDisplayed: [aclPrivilege.LIST_SYSTEM_USER],
    excursionBuilderButtonDisplayed: [aclPrivilege.DISPLAY_EXCURSION_BUILDER],
    excursionCreationButtonDisplayed: [aclPrivilege.CREATE_EXCURSION],
    excursionExportButtonDisplayed: [aclPrivilege.DISPLAY_EXCURSION_EXPORT],
    systemAdminSelectionOptionDisplayed: [aclPrivilege.LIST_SYSTEM_ADMIN],
    clientProviderSftpPathUpdateActionEnabled: [aclPrivilege.UPDATE_CLIENT_PROVIDER_SFTP_PATH],
    clientUserGroupRoleDisplayed: [aclPrivilege.DISPLAY_CLIENT_USER_GROUP_ROLE],
    excursionSyncButtonDisplayed: [aclPrivilege.SYNC_EXCURSION],
    copyExcursionButtonDisplayed: [aclPrivilege.COPY_EXCURSION],
    createAndUpdateExcursionEnabled: [aclPrivilege.CREATE_EXCURSION, aclPrivilege.UPDATE_EXCURSION],
    excursionHistoryDisplayed: [aclPrivilege.DISPLAY_EXCURSION_HISTORY],
    excursionApprovalButtonDisplayed: [aclPrivilege.APPROVE_EXCURSION],
    excursionUnapprovalButtonDisplayed: [aclPrivilege.UNAPPROVE_EXCURSION]
  },
  setPrivilegeComponentOptions(state, getClientSetupResponse) {
    const { computed } = getClientSetupResponse.data;
    const { systemAcl } = computed;
    const { clientAcl } = computed;
    const { parentClientAcl } = computed;

    Object.keys(this.privilegeOptionHash).forEach((privilegeKey) => {
      const privileges = this.privilegeOptionHash[privilegeKey];
      if (privileges) {
        let privilegesValid = true;
        privileges.forEach((p) => {
          let privilegeValid = false;
          if (
            (systemAcl && systemAcl[aclDomain.ANY] && systemAcl[aclDomain.ANY][p]) ||
            (clientAcl && clientAcl[aclDomain.SELF] && clientAcl[aclDomain.SELF][p]) ||
            (parentClientAcl &&
              parentClientAcl[aclDomain.SELF] &&
              parentClientAcl[aclDomain.SELF][p])
          ) {
            privilegeValid = true;
          }

          if (!privilegeValid) {
            privilegesValid = false;
          }
        });

        state[privilegeKey] = privilegesValid;
      }
    });
  },
  setComponentOptions(state, getClientSetupsResponse, eventPluginCommonAdapter) {
    if (getClientSetupsResponse.data && getClientSetupsResponse.data.computed) {
      const { computed } = getClientSetupsResponse.data;
      const optionParams = [
        'auth0LoginEnabled',
        'excursionArchivalButtonDiplayed',
        'clientParentId',
        'clientTimezone',
        'excursionAdditionalInformationEnabled',
        'excursionChecklistEnforcementBeforeSubmissionEnabled',
        'excursionCopyActionEnabled',
        'excursionDestinationContentEnabled',
        'excursionHighRiskStudentMedicalEnabled',
        'excursionPersonResponsibleEnabled',
        'excursionPolicyViewActionEnabled',
        'excursionInherentRiskRatingEnabled',
        'excursionUserRiskRatingEnabled',
        'impersonatedClientSid',
        'parentClientSiteFlag',
        'providerUpdateActionDisplayed',
        'providerUpdateActionEnabled',
        'publicSchoolFeatureSetEnabled',
        'selectedExcursionTypeLabel',
        'clientSiteSettingsButtonDiplayed',
        'riskCategoryManagementMenuItemDisplayed',
        'operationManagementMenuItemDisplayed',
        'studentManagementSubjectManagementDisplayed',
        'clientSelfGroupManagementDisplayed',
        'vendorGroupIdDisplayEnabled',
        'studentManagementExtraGroupFeatureSetEnabled',
        'studentManagementSubjectSetupDetected',
        'userClientSid',
        'userParentId',
        'vendorClientLogoEnabled',
        'vendorExcursionSidInputEnabled',
        'userDisplayName',
        'userEmail',
        'clientRelativeList'
      ];
      optionParams.forEach((key) => {
        state[key] = computed[key] ? computed[key] : false;
      });

      const userIdentificationTriggered =
        computed.userEmail &&
        computed.requestAuthScheme &&
        (authScheme.USER_AUTH_SCHEME === computed.requestAuthScheme ||
          authScheme.VENDOR_AUTH_SCHEME === computed.requestAuthScheme);

      if (userIdentificationTriggered) {
        const { userEmail } = computed;
        const userClientAclRole = computed.userClientAclRole ? computed.userClientAclRole : '';
        const vendorName = computed.vendorName ? computed.vendorName : '';
        const csClientId = computed.csClientId ? computed.csClientId : '';

        const trackableArgHash = {
          csClientId,
          userEmail,
          userClientAclRole,
          vendorName
        };

        eventPluginCommonAdapter.initTrackableVarBatch(trackableArgHash);
        eventPluginCommonAdapter.identifyUser();
        eventPluginCommonAdapter.registerLoginEvent();
      }

      // Set user initials
      if (computed.userDisplayName) {
        const names = computed.userDisplayName.split(' ');
        if (names && names.length > 0) {
          const firstLetters = names.slice(0, 2).map((item) => {
            return item.substring(0, 1);
          });
          state.userInitials = firstLetters.join('').toUpperCase();
        }
      }

      // Set client logo
      // @TODO: shall obsolete this and use the clients/general/clientLogo?
      if (!computed.logoAttachmentUrl) {
        state.logoAttachmentUrl = '';
      } else if (computed.logoAttachmentUrl && computed.logoAttachmentUrl !== '') {
        state.logoAttachmentUrl = computed.logoAttachmentUrl;
      }

      state.clientLogoBackgroundColor =
        computed && computed.clientLogoBackgroundColor
          ? computed.clientLogoBackgroundColor
          : '#fafafa';

      if (computed.proformaTemplateFile && computed.proformaTemplateFile.sid) {
        state.clientTemplate = {
          sid: computed.proformaTemplateFile.sid,
          filename: computed.proformaTemplateFile.metadata.filename
        };
      }

      this.setPrivilegeComponentOptions(state, getClientSetupsResponse);
    }
  }
};
