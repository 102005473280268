<template>
  <div>
    <div v-for="(category, index) in riskCategories" :key="index" class="category-div">
      <div v-if="categorySectionDisplayed(category.name)">
        <div>
          <v-layout align-center justify-space-between style="display: flex">
            <div>
              <v-layout align-center justify-space-between class="category-container">
                <v-icon
                  :id="getToggleCategoryIconId(category.name)"
                  class="icon"
                  @click.stop="toggleCategory(index)"
                >
                  {{ !category.displayed ? 'keyboard_arrow_left' : 'keyboard_arrow_down' }}
                </v-icon>
                <div
                  :id="getToggleCategoryId(category.name)"
                  class="name"
                  @click.stop="toggleCategory(index)"
                >
                  {{ category.name }}
                </div>
                <div class="quantity ml-3" v-if="0 > excludedRiskCategories.indexOf(category.name)">
                  {{ typesCount(category.name) }} Types
                </div>
                <div class="quantity ml-3" v-if="'Activities' === category.name">
                  {{ activityTypesCount }} Types
                </div>
                <div v-if="highRiskCategoryName !== category.name" class="quantity ml-3">
                  {{ approvedProvidersCount(category.name) }} Providers
                </div>
              </v-layout>
            </div>
            <div style="display: flex">
              <complete-checklist
                :categoryName="category.name"
                :checklistViewModeEnabled="false"
                :formControlEnabled="formControlEnabled"
                :associatedChecklistCompleted="getTicked(category)"
                :elementDisabled="
                  (0 >= typesCount(category.name) && category.name !== excludedRiskCategories[1]) ||
                  (0 >= approvedProvidersCount(category.name) &&
                    category.name === excludedRiskCategories[1]) ||
                  (checklistFetchActionInProgress &&
                    checklistFetchActionCategoryName === category.name)
                "
                :checklistFetchActionCategoryName="checklistFetchActionCategoryName"
                :checklistFetchActionInProgress="checklistFetchActionInProgress"
                v-if="'Activities' !== category.name"
                :saveDraft="saveDraft"
              />
              <view-policy
                :categoryName="category.name"
                :elementDisabled="
                  (0 >= typesCount(category.name) && category.name !== excludedRiskCategories[1]) ||
                  (0 >= approvedProvidersCount(category.name) &&
                    category.name === excludedRiskCategories[1])
                "
                v-if="
                  (excursionPolicyViewActionEnabled && 'Activities' !== category.name) ||
                  'Outdoor Education Providers' === category.name
                "
              />
              <view-policy
                :categoryName="category.name"
                :elementDisabled="0 >= activityTypesCount"
                :activitySupervisorScrollDisplayed="false"
                v-if="excursionPolicyViewActionEnabled && 'Activities' === category.name"
              />
            </div>
          </v-layout>
        </div>
        <div :hidden="!category.displayed">
          <v-layout>
            <select-control
              background-color="#EEEEEE"
              :name="category.name"
              :id="getTypeControlId(category.name)"
              v-if="
                0 > excludedRiskCategories.indexOf(category.name) &&
                category.name !== highRiskCategoryName
              "
              :formControlEnabled="formControlEnabled"
              @changeRiskCategoryDone="changeRiskCategory"
            ></select-control>
            <high-risk
              background-color="#EEEEEE"
              :name="category.name"
              :id="getTypeControlId(category.name)"
              v-if="category.name === highRiskCategoryName"
              :formControlEnabled="formControlEnabled"
              @changeRiskCategoryDone="changeRiskCategory"
            ></high-risk>
            <activity-risk
              v-if="'Activities' === category.name"
              :formControlEnabled="formControlEnabled"
              :checklistAndPolicyDisplayed="true"
              :saveDraft="saveDraft"
              @changeActivityDone="changeActivityDone"
            ></activity-risk>
          </v-layout>
          <v-layout class="sub-category-container">
            <v-flex
              xs12
              providers
              label-padding
              v-if="0 < getProvidersByCategoryCount(category.name)"
            >
              Providers
              <v-tooltip right color="#ffffff">
                <template v-slot:activator="{ on: tooltip }">
                  <v-btn
                    :id="getProviderTooltipBtnId(category.name)"
                    v-if="hasNegativeProviderAnswer(category.name)"
                    v-on="{ ...tooltip }"
                    class="provider-tooltip-icon"
                    icon
                  >
                    <i class="fas fa-info-circle"></i>
                  </v-btn>
                </template>
                <span class="provider-tooltip-msg">{{ negativeProviderAnswerHint }}</span>
              </v-tooltip>
            </v-flex>
          </v-layout>
          <v-layout
            class="move-top-risk-title"
            v-if="0 < getProvidersByCategoryCount(category.name)"
          >
            <providers
              :categoryName="category.name"
              :formControlEnabled="formControlEnabled"
            ></providers>
          </v-layout>
          <v-flex
            :id="`risk-category-txt-people-responsible-${getTypeControlId(category.name)}`"
            xs12
            v-if="excursionPersonResponsibleEnabled"
            class="text-field-title"
            :style="{
              'padding-top': 0 >= getProvidersByCategoryCount(category.name) ? '8px' : '0px'
            }"
            >People or Providers Responsible</v-flex
          >
          <v-flex
            xs12
            class="excursion-detail-text"
            style="width: calc(100% - 4px)"
            v-if="excursionPersonResponsibleEnabled"
          >
            <v-textarea
              solo
              :value="category.personResponsible"
              ref="items"
              :height="60"
              :no-resize="true"
              @input="updatePersonResponsible(category, $event)"
              :placeholder="personResponsiblePlaceholder"
              class="name-textbox excursion-input excursion-people-responsible-textarea"
              :disabled="
                !formControlEnabled ||
                (0 >= approvedProvidersCount(category.name) &&
                  'Outdoor Education Providers' === category.name)
              "
              :id="`personResponsible-${getTypeControlId(category.name)}`"
            />
            <div
              v-if="checkIfAnyElementVerticalScrollEnabled(category.name)"
              :id="`risk-category--person-responsible-show-more--button-${getTypeControlId(
                category.name
              )}`"
              @click="
                displayDetailsDialog('People or Providers Responsible', category.personResponsible)
              "
              class="show-more-link"
            >
              Show more
            </div>
          </v-flex>

          <!-- Additional Risk Control Mitigation Strategies Start -->
          <v-flex
            xs12
            v-if="additionalRiskControlMitigationStrategiesEnabled"
            class="text-field-title"
            :style="{
              'padding-top': 0 >= getProvidersByCategoryCount(category.name) ? '8px' : '0px'
            }"
            >Additional Risk Control Mitigation Strategies
          </v-flex>
          <v-flex
            xs12
            class="excursion-detail-text"
            style="width: calc(100% - 4px)"
            v-if="additionalRiskControlMitigationStrategiesEnabled"
          >
            <v-textarea
              solo
              :value="category.additionalRiskControlMitigationStrategies"
              ref="items"
              :height="60"
              :no-resize="true"
              @input="updateAdditionalRiskControlMitigationStrategies(category, $event)"
              :placeholder="additionalRiskControlPlaceholder"
              class="name-textbox excursion-input category-textarea"
              :disabled="
                !formControlEnabled ||
                (0 >= approvedProvidersCount(category.name) &&
                  'Outdoor Education Providers' === category.name)
              "
              :id="`additionalRiskControl-${getTypeControlId(category.name)}`"
            />
            <div
              v-if="checkIfAnyElementVerticalScrollEnabled(category.name)"
              :id="`risk-category--addition-risk-control-show-more--button-${getTypeControlId(
                category.name
              )}`"
              @click="
                displayDetailsDialog(
                  'Additional Risk Control Mitigation Strategies',
                  category.additionalRiskControlMitigationStrategies
                )
              "
              class="show-more-link"
            >
              Show more
            </div>
          </v-flex>
          <!-- Additional Risk Control Mitigation Strategies End -->

          <div
            v-if="
              0 >= getProvidersByCategoryCount(category.name) &&
              category.name !== highRiskCategoryName
            "
            style="padding-bottom: 18px"
          ></div>
        </div>
      </div>
    </div>
    <div
      v-if="(!riskCategories || 0 >= riskCategories.length) && !archivedExcursionDetected"
      class="excursion-waiting"
    >
      <v-progress-circular
        :size="30"
        color="rgb(29, 175, 236)"
        class="loading-img"
        indeterminate
      ></v-progress-circular>
    </div>
    <div>
      <cs-form-dialog
        v-model="genericDialogDisplayed"
        id="dlg-risk-category--generic"
        bodyId="dlg-risk-category--generic-body"
        heading="People or Providers Responsible"
        :primaryAction="{
          label: 'OK',
          eventName: 'primary-click'
        }"
        @primary-click="genericDialogDisplayed = false"
      >
        <template v-slot:cs-form-dialog-content>
          {{ genericDialogMessage }}
        </template>
      </cs-form-dialog>
    </div>
  </div>
</template>

<script>
import * as types from '@/store/mutationTypes';
import riskCategoryLabel from '../lib/const/riskCategoryLabel';
import excursionStatus from '../lib/const/excursionStatus';

export default {
  props: {
    saveDraft: {
      type: Function
    },
    riskCategories: Array,
    formControlEnabled: Boolean
  },
  data() {
    return {
      riskClasses: ['Swimming', 'Boarding'],
      personResponsiblePlaceholder:
        'Enter the full name or initials of the person or people responsible for this Risk Category, or any providers not included in the Approved Provider field',
      additionalRiskControlPlaceholder: 'Enter additional risk control mitigation strategies',
      excludedRiskCategories: ['Activities', 'Outdoor Education Providers'],
      negativeProviderAnswerHint:
        'There are approved providers that have not completed all requested information.\nPlease speak with your school’s administration staff for more information.',
      checklistFetchActionCategoryName: '',
      checklistFetchActionCount: 0,
      checklistFetchActionDoneCount: 0,
      highRiskCategoryName: '',
      genericDialogDisplayed: false,
      genericDialogMessage: '',
      genericDialogTitle: '',
      verticalScrollFunctionHash: {}
    };
  },
  computed: {
    resolvedExcursionStatus() {
      return this.$store.state.common.excursionDc.status;
    },
    archivedExcursionDetected() {
      return excursionStatus.ARCHIVED === this.resolvedExcursionStatus;
    },
    typesCount() {
      return (categoryName) => {
        return this.$store.getters['common/getTypesCountFromSelectedClasses'](categoryName);
      };
    },
    activityTypesCount() {
      return this.$store.getters['common/getTypesCountForActivity'];
    },
    approvedProvidersCount() {
      return (categoryName) => {
        return this.$store.getters['common/getSelectedApprovedProvidersCount'](categoryName);
      };
    },
    excursionPersonResponsibleEnabled() {
      return this.$store.state.common.excursionPersonResponsibleEnabled;
    },
    additionalRiskControlMitigationStrategiesEnabled() {
      return this.$store.state.common.excursionDc.additionalRiskControlMitigationStrategiesEnabled;
    },
    getTypeControlId() {
      return function (categoryName) {
        return categoryName.replace(/\s*/g, '');
      };
    },
    getProvidersByCategoryCount() {
      return (categoryName) => {
        return this.$store.getters['common/getProvidersByCategory'](categoryName).length;
      };
    },
    hasNegativeProviderAnswer() {
      return (categoryName) => {
        const providers = this.$store.getters['common/getProvidersByCategory'](categoryName);
        if (providers && providers.length > 0) {
          return providers.some((provider) => !provider.answer);
        }
        return false;
      };
    },
    excursionPolicyViewActionEnabled() {
      return this.$store.state.common.excursionPolicyViewActionEnabled;
    },
    categories() {
      return this.$store.state.common.excursionDc.categories;
    },
    checklistFetchActionInProgress() {
      return this.$store.state.common.checklistFetchActionInProgress;
    },
    checkIfAnyElementVerticalScrollEnabled() {
      return (categoryName) => {
        if (this.$refs.items) {
          Object.keys(this.$refs.items).forEach((item) => {
            const element = this.$refs.items[item].$el.getElementsByTagName('textarea')[0];
            if (element && element.id) {
              const name = String(element.id).replace('personResponsible-', '');
              this.verticalScrollFunctionHash[name] =
                this.checkIfElementVerticalScrollEnabled(element);
            }
          });
          const personResponsibleDisabled =
            !this.formControlEnabled ||
            (this.approvedProvidersCount(categoryName) <= 0 &&
              categoryName === 'Outdoor Education Providers');
          return (
            this.verticalScrollFunctionHash[String(categoryName).replaceAll(' ', '')] &&
            personResponsibleDisabled
          );
        }
        return null;
      };
    }
  },
  methods: {
    getToggleCategoryIconId(name) {
      return `risk-category--toggle-category--icon_${name}`;
    },
    getToggleCategoryId(name) {
      return `risk-category--toggle-category-label-${name}`;
    },
    getProviderTooltipBtnId(name) {
      return `risk-category--provider-tooltip--button-${name}`;
    },
    checkIfElementVerticalScrollEnabled(element) {
      let res = !!element.scrollTop;
      if (!res) {
        // eslint-disable-next-line no-param-reassign
        element.scrollTop = 1;
        res = !!element.scrollTop;
        // eslint-disable-next-line no-param-reassign
        element.scrollTop = 0;
      }
      return res;
    },
    displayDetailsDialog(title, text) {
      this.genericDialogTitle = title;
      this.genericDialogDisplayed = true;
      if (!text) {
        this.genericDialogMessage = text;
        return;
      }
      const stringifiedText = JSON.stringify(text);
      const showedText = stringifiedText.replaceAll('\\n', '<br/>').replaceAll('"', '');
      this.genericDialogMessage = showedText;
    },
    toggleCategory(index) {
      this.riskCategories[index].displayed = !this.riskCategories[index].displayed;
    },
    updatePersonResponsible(category, value) {
      this.$store.commit(types.COMMON_SET_PERSON_RESPONSIBLE, {
        name: category.name,
        personResponsible: value
      });
    },
    updateAdditionalRiskControlMitigationStrategies(category, value) {
      this.$store.commit(types.COMMON_SET_ADDITIONAL_RISK_CONTROL_MITIGATION_STRATEGIES, {
        name: category.name,
        additionalRiskControlMitigationStrategies: value
      });
    },
    getTicked(item) {
      const localItem = item;

      if (this.categories && this.categories.length > 0) {
        const categoryItem = this.categories.find(
          (category) => category.CompleteChecklist && category.name === localItem.name
        );
        if (categoryItem && categoryItem.CompleteChecklist) {
          localItem.CompleteChecklist = categoryItem.CompleteChecklist;
        }
      }

      let allBox = 0;
      let allChecked = 0;

      if (
        item &&
        localItem.CompleteChecklist &&
        localItem.CompleteChecklist.length > 0 &&
        localItem.CompleteChecklist[0].checklist &&
        localItem.CompleteChecklist[0].checklist.Strategy &&
        localItem.CompleteChecklist[0].checklist.Strategy.data
      ) {
        const { data } = localItem.CompleteChecklist[0].checklist.Strategy;
        const dataKey = Object.keys(data);

        if (data[dataKey] && data[dataKey].children && data[dataKey].children.length > 0) {
          data[dataKey].children.forEach((checkData) => {
            const checklist = checkData.children;

            if (checklist && checklist.length > 0) {
              checklist.forEach((box) => {
                if (box.children && box.children.length > 0) {
                  const boxes = box.children.filter((ck) => {
                    return ck.contentType === 'checkbox';
                  });
                  if (boxes && boxes.length > 0) {
                    allBox += boxes.length;
                  }

                  const checked = box.children.filter((ck) => {
                    return ck.ticked && ck.contentType === 'checkbox';
                  });

                  if (checked && checked.length > 0) {
                    allChecked += checked.length;
                  }
                } else if (box.contentType === 'checkbox') {
                  allBox += 1;
                  if (box.ticked) {
                    allChecked += 1;
                  }
                }
              });
            }
          });
        }
      } else {
        allChecked = -1;
      }
      if (
        localItem.CompleteChecklist &&
        localItem.CompleteChecklist.length > 0 &&
        localItem.CompleteChecklist[0].checklist
      ) {
        const { title } = localItem.CompleteChecklist[0].checklist;
        const { tickedRiskCategoryChecklists } = this.$store.state.common;

        const typesCount = this.typesCount(title);
        const ticked = allBox === allChecked;
        const isExcludedRiskCategories = this.excludedRiskCategories.indexOf(title) > 0;

        if (
          typesCount === 0 &&
          !isExcludedRiskCategories &&
          title in tickedRiskCategoryChecklists
        ) {
          this.deleteTickedRiskCategoryChecklists(title);
        } else if (tickedRiskCategoryChecklists[title] !== ticked) {
          this.updateTickedRiskCategoryChecklists(title, ticked);
        }
      }
      return allBox === allChecked;
    },
    updateTickedRiskCategoryChecklists(checklistTitle, ticked) {
      this.$store.commit(types.COMMON_SET_RISK_CATEGORY_CHECKLISTS_TICKED, {
        title: checklistTitle,
        ticked
      });
    },
    deleteTickedRiskCategoryChecklists(checklistTitle) {
      this.$store.commit(types.COMMON_DELETE_FROM_TICKED_RISK_CATEGORY_CHECKLISTS, {
        title: checklistTitle
      });
    },
    categorySectionDisplayed(categoryName) {
      let sectionDisplayed = false;

      if (this.highRiskCategoryName === categoryName) {
        sectionDisplayed = this.$store.state.common.excursionHighRiskStudentMedicalEnabled;
      } else {
        sectionDisplayed =
          categoryName !== this.excludedRiskCategories[1] ||
          this.getProvidersByCategoryCount(categoryName) > 0;
      }

      return sectionDisplayed;
    },
    changeActivityDone() {
      this.$emit('changeRiskCategoryDone');
    },
    async changeRiskCategory(tlrcName) {
      // tlrc stands for: top level risk category
      this.checklistFetchActionCategoryName = tlrcName;
      this.$store.commit(types.COMMON_SET_CHECKLIST_FETCH_ACTION_IN_PROGRESS, true);
      this.checklistFetchActionCount += 1;

      const queryParam = this.$route.query;
      const tlrc = this.$store.getters['common/getTlrc'](tlrcName);
      const excursionCommonAdapter = new this.$app.excursionCommon.Adapter({
        store: this.$store,
        app: this.$app
      });
      const allParams = excursionCommonAdapter.getRiskCategoryParams();
      const params = allParams.filter((p) => p.indexOf(tlrcName) >= 0);

      const that = this;

      this.$emit('changeRiskCategoryDone');
      const argHash = {
        stbApiAdapter: this.$app.stbApiAdapter,
        store: this.$store,
        queryParam,
        params,
        risk: tlrc,
        resolve() {
          that.checklistFetchActionDoneCount += 1;
          if (that.checklistFetchActionCount === that.checklistFetchActionDoneCount) {
            that.$store.commit(types.COMMON_SET_CHECKLIST_FETCH_ACTION_IN_PROGRESS, false);
            that.checklistFetchActionCount = 0;
            that.checklistFetchActionDoneCount = 0;
          }
        }
      };
      await this.$store.dispatch('common/changeRiskCategoryCompleteChecklist', argHash);
    }
  },
  async mounted() {
    this.highRiskCategoryName = riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS;
    const argHash = {
      stbApiAdapter: this.$app.stbApiAdapter,
      store: this.$store,
      queryParam: this.$route.query
    };
    // check archive status
    if (!this.archivedExcursionDetected) {
      await this.$store.dispatch('common/getProviders', argHash);
    }
  },
  updated() {
    this.$app.excursionCommon.setListItemId('.v-list__item__title');
  }
};
</script>
<style scoped>
.providers .label-padding {
  padding: 10px 0 0px 0;
}

.category-container {
  font-size: 1em;
  height: 48px;
  line-height: 48px;
}

.category-container .icon {
  font-size: 1.5em;
  font-weight: bold;
  color: #000000;
  cursor: pointer;
}

.category-container .name {
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.category-container .quantity {
  color: #808080;
}

.sub-category-container {
  font-size: 14px;
  color: #818080;
}

.sub-category-container .name {
  font-style: italic;
  padding-left: 2px;
}

.sub-category-container .section-btn {
  font-size: 0.8em;
}

.text-field-title {
  color: #818080;
  padding-bottom: 7px;
}

.provider-tooltip-icon {
  width: auto;
  height: auto;
}

.provider-tooltip-msg {
  white-space: pre-wrap;
  color: #000000;
}

.category-textarea .v-input__slot {
  padding-top: 7px !important;
  padding-right: 15px !important;
}

.show-more-link {
  cursor: pointer;
}
</style>
