import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
  apiVersion: '',
  name: '',
  stbAxios: null,
  silentAxiosError: false
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
