var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"pre-departure-check-list"},[_c('cs-button',{staticClass:"predeparture-checklist-button",attrs:{"primary":"","id":"btnViewOrExportPredepartureChecklist","testing-id":"btnViewOrExportPredepartureChecklist","disabled":_vm.predepartureChecklistExportActionEnabled,"leftIcon":_vm.predepartureChecklistCompleted ? 'fa fa-check' : '',"label":"Pre-Departure Checklist"},on:{"click":function($event){return _vm.getData()}}}),_c('cs-form-dialog',{attrs:{"id":"dlg-pre-departure-check-list","bodyId":"dlg-pre-departure-check-list-body","heading":"Pre-Departure Checklist","primaryAction":{
      label: 'Save Pre-departure',
      eventName: 'primary-click',
      disabled: _vm.predepartureSaveActionInProgress || _vm.archivedExcursionDetected,
      loading: _vm.predepartureSaveActionInProgress
    },"secondaryAction1":{
      label: 'Export',
      eventName: 'secondary1-click',
      disabled: _vm.predepartureDownloadActionInProgress && !_vm.predepartureExportActionCompleted,
      loading: _vm.predepartureDownloadActionInProgress && !_vm.predepartureExportActionCompleted
    },"secondaryAction2":{
      label: 'Close',
      eventName: 'secondary2-click'
    }},on:{"primary-click":function($event){return _vm.save()},"secondary1-click":_vm.displayPresaveConfirmationDialog,"secondary2-click":_vm.close,"input":_vm.onPredepartureDialogChange},scopedSlots:_vm._u([{key:"cs-form-dialog-content",fn:function(){return [_c('div',{staticClass:"predeparture-check-list"},[(_vm.checklistChildrenDisplayed())?_c('div',_vm._l((_vm.items),function(item,index){return _c('v-list',{key:index},[_c('v-subheader',{staticClass:"subhealder"},[_c('vue-markdown',{attrs:{"source":item.label,"typographer":false}})],1),_vm._l((item.children),function(option,index){return _c('div',{key:index},[_c('v-layout',[_c('v-checkbox',{staticClass:"pre-check",attrs:{"id":_vm.getPreCheckId(option, index, item.label),"color":"#c80063","checked":option.ticked,"disabled":_vm.archivedExcursionDetected},model:{value:(option.ticked),callback:function ($$v) {_vm.$set(option, "ticked", $$v)},expression:"option.ticked"}}),_c('v-flex',{staticClass:"pre-check-desc",attrs:{"id":_vm.getPreCheckLabelId(option, index, item.label),"testing-id":"clickItemTitle"},on:{"click":function($event){$event.stopPropagation();return _vm.clickItemTitle(option)}}},[_c('vue-markdown',{attrs:{"source":option.label,"typographer":false}})],1)],1),_c('checklist-item-comment',{staticClass:"pre-checklist-item-comment",attrs:{"checklistItem":option,"commentBtnId":("view-pre-departture-checklist--" + (String(item.label)
                  .replaceAll(' ', '-')
                  .replaceAll('*', '')) + "---comment--" + index)}})],1)})],2)}),1):_vm._e(),(!_vm.checklistChildrenDisplayed())?_c('div',_vm._l((_vm.items),function(item,index){return _c('v-list',{key:index},[_c('v-subheader',{staticClass:"subhealder"},[_c('h3',[_vm._v(_vm._s(item.item))])]),_c('v-layout',[_c('v-checkbox',{staticClass:"pre-check",attrs:{"id":_vm.getPreCheckId(item, index, item.item),"color":"#c80063","checked":item.ticked,"disabled":_vm.archivedExcursionDetected},model:{value:(item.ticked),callback:function ($$v) {_vm.$set(item, "ticked", $$v)},expression:"item.ticked"}}),_c('v-flex',{staticClass:"pre-check-desc",attrs:{"id":_vm.getPreCheckLabelId(item, index, item.item),"testing-id":"clickItemTitle"},on:{"click":function($event){$event.stopPropagation();return _vm.clickItemTitle(item)}}},[_vm._v(" "+_vm._s(item.description)+" ")])],1),_c('checklist-item-comment',{attrs:{"checklistItem":item,"commentBtnId":("view-pre-departture-checklist--" + (String(item.item)
                .replaceAll(' ', '-')
                .replaceAll('*', '')) + "---comment--" + index)}})],1)}),1):_vm._e()])]},proxy:true}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c('cs-form-dialog',{attrs:{"id":"dlg-pre-departure-check-list-confirm","bodyId":"dlg-pre-departure-check-list-confirm-body","heading":"Pre-Departure Checklist Export","primaryAction":{
      label: 'Save',
      eventName: 'primary-click',
      disabled: !_vm.formControlEnabled
    },"secondaryAction1":{
      label: 'Close',
      eventName: 'secondary1-click'
    }},on:{"primary-click":function($event){return _vm.onConfirmExportSave(true)},"secondary1-click":function($event){return _vm.onConfirmExportSave(false)}},scopedSlots:_vm._u([{key:"cs-form-dialog-content",fn:function(){return [_vm._v(" Changes have been made to this excursion. Do you want to save? ")]},proxy:true}]),model:{value:(_vm.presaveConfirmationDialogDisplayed),callback:function ($$v) {_vm.presaveConfirmationDialogDisplayed=$$v},expression:"presaveConfirmationDialogDisplayed"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }