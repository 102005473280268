export const validateUrl = (value) => {
  if (!value || (value && value.length === 0)) {
    return true;
  }
  const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w-]+)+[\w\-_~:/?#[\]@!&',;=.]+$/;
  const urlValid = regex.test(value);
  return urlValid;
};

export default { validateUrl };
