// app -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
export const SET_API_PARAM = 'setApiParam';
export const SET_IS_RESPONSE_WITH_ERROR = 'setIsResponseWithError';
export const SET_CE_COMMIT_OID = 'setCeCommitOid';
export const SET_API_VERSION = 'setApiVersion';
export const SET_PREV_ROUTE_NAME = 'setPrevRouteName';
export const SET_MENU = 'setMenu';
export const SET_API_ERROR_MESSAGE = 'setApiErrorMessage';
export const RESET_ALL_STATE = 'resetAllState';
export const RESET_STORE_MODULE_STATE = 'resetStoreModuleState';
export const SET_AUTH_SCHEME_API_PARAM = 'setAuthSchemeApiParam';
export const SET_CLIENT_ACL_ROLE_INFERIORITY_HASH = 'setClientAclRoleInferiorityHash';

export const COMMON_SET_API_PARAM = `common/${SET_API_PARAM}`;
export const COMMON_SET_IS_RESPONSE_WITH_ERROR = `common/${SET_IS_RESPONSE_WITH_ERROR}`;
export const COMMON_SET_CE_COMMIT_OID = `common/${SET_CE_COMMIT_OID}`;
export const COMMON_SET_API_VERSION = `common/${SET_API_VERSION}`;
export const COMMON_SET_PREV_ROUTE_NAME = `common/${SET_PREV_ROUTE_NAME}`;
export const COMMON_SET_MENU = `common/${SET_MENU}`;
export const COMMON_SET_API_ERROR_MESSAGE = `common/${SET_API_ERROR_MESSAGE}`;
export const COMMON_RESET_ALL_STATE = `common/${RESET_ALL_STATE}`;
export const COMMON_RESET_STORE_MODULE_STATE = `common/${RESET_STORE_MODULE_STATE}`;
export const COMMON_SET_AUTH_SCHEME_API_PARAM = `common/${SET_AUTH_SCHEME_API_PARAM}`;
export const COMMON_SET_CLIENT_ACL_ROLE_INFERIORITY_HASH = `common/${SET_CLIENT_ACL_ROLE_INFERIORITY_HASH}`;
// auth -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
export const SET_IS_INIT_CLIENT_CONFIG_IN_PROGRESS = 'setIsInitClientConfigInProgress';
export const SET_USER_PROFILE = 'setUserProfile';
export const SET_TOKEN_EXPIRY_IN_MS = 'setTokenExpiryInMS';
export const SET_IS_AUTHENTICATED = 'setIsAuthenticated';
export const SET_IDP_URL = 'setIdpUrl';
export const SET_TOKEN = 'setToken';

export const AUTHORISATION_SET_IS_INIT_CLIENT_CONFIG_IN_PROGRESS = `authorisation/${SET_IS_INIT_CLIENT_CONFIG_IN_PROGRESS}`;
export const AUTHORISATION_SET_USER_PROFILE = `authorisation/${SET_USER_PROFILE}`;
export const AUTHORISATION_SET_IS_AUTHENTICATED = `authorisation/${SET_IS_AUTHENTICATED}`;
export const SESSION_SET_TOKEN_EXPIRY_IN_MS = `session/${SET_TOKEN_EXPIRY_IN_MS}`;
export const SESSION_SET_IDP_URL = `session/${SET_IDP_URL}`;
export const SESSION_SET_TOKEN = `session/${SET_TOKEN}`;
// cliet -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
export const SET_CLIENT_LIST_LOADING = 'setClientListLoading';
export const SET_PARENT_CLIENT_LIST_LOADING = 'setParentClientListLoading';
export const SET_CLIENT_USER_LIST_LOADING = 'setClientUserListLoading';
export const SET_CLIENT_CLIENT_LIST_LOADING = 'setClientClientListLoading';
export const ADD_PARENT_CLIENT_LIST = 'addParentClientList';
export const SET_PARENT_CLIENT_ITEMS = 'setParentClientItems';
export const RESET_PARENT_CLIENT = 'resetParentClient';
export const SET_CLIENT_CLIENT_LIST = 'setClientClientList';
export const SET_CLIENT_CLIENT_LIST_ITEMS = 'setClientClientListItems';
export const SET_COMPONENT_OPTIONS = 'setComponentOptions';
export const RESET_CLIENT_LIST = 'resetClientList';
export const RESET_PARENT_CLIENT_LIST = 'resetParentClientList';
export const RESET_CLIENT_CLIENT_LIST = 'resetClientClientList';
export const RESET_CLIENT_USER_LIST = 'resetClientUserList';
export const RESET_USER_LIST = 'resetUserList';
export const TOGGLE_CLIENT_USER_ENABLED = 'toggleClientUserEnabled';
export const TOGGLE_USER_ENABLED = 'toggleUserEnabled';
export const TOGGLE_CLIENT_ENABLED = 'toggleClientEnabled';

export const COMMON_SET_CLIENT_LIST_LOADING = `common/${SET_CLIENT_LIST_LOADING}`;
export const COMMON_SET_PARENT_CLIENT_LIST_LOADING = `common/${SET_PARENT_CLIENT_LIST_LOADING}`;
export const COMMON_SET_CLIENT_USER_LIST_LOADING = `common/${SET_CLIENT_USER_LIST_LOADING}`;
export const COMMON_SET_CLIENT_CLIENT_LIST_LOADING = `common/${SET_CLIENT_CLIENT_LIST_LOADING}`;
export const COMMON_ADD_PARENT_CLIENT_LIST = `common/${ADD_PARENT_CLIENT_LIST}`;
export const COMMON_SET_PARENT_CLIENT_ITEMS = `common/${SET_PARENT_CLIENT_ITEMS}`;
export const COMMON_RESET_PARENT_CLIENT = `common/${RESET_PARENT_CLIENT}`;
export const COMMON_SET_CLIENT_CLIENT_LIST = `common/${SET_CLIENT_CLIENT_LIST}`;
export const COMMON_SET_CLIENT_CLIENT_LIST_ITEMS = `common/${SET_CLIENT_CLIENT_LIST_ITEMS}`;
export const COMMON_SET_COMPONENT_OPTIONS = `common/${SET_COMPONENT_OPTIONS}`;
export const COMMON_RESET_CLIENT_LIST = `common/${RESET_CLIENT_LIST}`;
export const COMMON_RESET_PARENT_CLIENT_LIST = `common/${RESET_PARENT_CLIENT_LIST}`;
export const COMMON_RESET_CLIENT_CLIENT_LIST = `common/${RESET_CLIENT_CLIENT_LIST}`;
export const COMMON_RESET_CLIENT_USER_LIST = `common/${RESET_CLIENT_USER_LIST}`;
export const COMMON_RESET_USER_LIST = `common/${RESET_USER_LIST}`;
export const COMMON_TOGGLE_CLIENT_USER_ENABLED = `common/${TOGGLE_CLIENT_USER_ENABLED}`;
export const COMMON_TOGGLE_USER_ENABLED = `common/${TOGGLE_USER_ENABLED}`;
export const COMMON_TOGGLE_CLIENT_ENABLED = `common/${TOGGLE_CLIENT_ENABLED}`;
// user -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
export const SET_USER_LIST_LOADING = 'setUserListLoading';

export const COMMON_SET_USER_LIST_LOADING = `common/${SET_USER_LIST_LOADING}`;
// excursion -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
export const SET_EXCURSIONS_LIST_LOADING = 'setExcursionsListLoading';
export const SET_EXCURSION_TYPE_LOADED = 'setExcursionTypeLoaded';
export const SET_EXCURSION_DC_CATEGORIES = 'setExcursionDcCategories';
export const SET_EXCURSION_DC_STATUS = 'setExcursionDcStatus';
export const SET_EXCURSION_CONFIRMATION_TICKED = 'setExcursionConfirmationTicked';
export const SET_EXCURSION_DC_CLIENT_TEMPLATE = 'setExcursionDcClientTemplate';
export const SET_EXCURSION_DC_ADDITIONAL_INFORMATION_NOTES =
  'setExcursionDcAdditionalInformationNotes';
export const SET_EXCURSION_DC_STAFF_SUPERVISOR_ATTENDANCE_COUNT =
  'setExcursionDcStaffSupervisorAttendanceCount';
export const SET_EXCURSION_DC_STUDENT_ATTENDANCE_COUNT = 'setExcursionDcStudentAttendanceCount';
export const SET_EXCURSION_DC_CATEGORY_COMPLETE_CHECKLIST =
  'setExcursionDcCategoryCompleteChecklist';
export const SET_EXCURSION_DC_CATEGORY_TYPE_CLASS_COMPLETE_CHECKLIST =
  'setExcursionDcCategoryTypeClassCompleteChecklist';
export const SET_EXCURSION_INITIALIZED = 'setExcursionInitialized';
export const SET_SHOW_EXCURSION_LOGO = 'setShowExcursionLogo';
export const SET_EXCURSION_STEP = 'setExcursionStep';
export const SET_EXCURSION_NAME = 'setExcursionName';
export const SET_EXCURSION_RISK_ASSESSMENT_NOTES = 'setExcursionRiskAssessmentNotes';
export const SET_RISK_MATRIX_SELF_DEFINED_DETECTED = 'setRiskMatrixSelfDefinedDetected';
export const SET_EXCURSION_RISK_MATRIX = 'setExcursionRiskMatrix';
export const SET_CLIENT_SETTING_RISK_MATRIX = 'setClientSettingRiskMatrix';
export const SET_RISK_MATRIX_LIKELIHOOD = 'setRiskMatrixLikelihood';
export const SET_RISK_MATRIX_CONSEQUENCE = 'setRiskMatrixConsequence';
export const SET_CLIENT_SETTING_RISK_MATRIX_RISK_RATING = 'setClientSettingRiskMatrixRiskRating';
export const SET_STAFF_TO_STUDENT_GENDER_RATIO_INFO = 'setStaffToStudentGenderRatioInfo';
export const SET_MANDATORY_CHECKLIST_BATCH_CONFIRMATION_AGREEMENT =
  'setMandatoryChecklistBatchConfirmationAgreement';
export const SET_VENDOR_EXCURSION_ID = 'setVendorExcursionId';
export const SET_EXCURSION_TYPE = 'setExcursionType';
export const SET_EXCURSION_JURISDICTION = 'setExcursionJurisdiction';
export const SET_EXCURSION_START_DATE = 'setExcursionStartDate';
export const SET_EXCURSION_END_DATE = 'setExcursionEndDate';
export const SET_EXCURSION_CONFIRMATION = 'setExcursionConfirmation';
export const RESET_CATEGORIES = 'resetCategories';
export const SET_EXCURSION_TYPE_CHOICE_LIST = 'setExcursionTypeChoiceList';
export const SET_DEFAULT_JURISDICTION = 'setDefaultJurisdiction';
export const SET_MANDATORY_CHECKLIST = 'setMandatoryChecklist';
export const RESET_EXCURSION_STORE_STATE = 'resetExcursionStoreState';
export const SET_EXCURSION = 'setExcursion';
export const SET_EXCURSION_CLIENT = 'setExcursionClient';
export const SET_EXCURSION_STUDENT_FETCH_TRIGGERED = 'setExcursionStudentFetchTriggered';
export const SET_EXCURSION_STUDENT_MEDICAL_CONDITIONS = 'setExcursionStudentMedicalConditions';
export const SET_EXCURSION_STUDENT_MEDICAL_CONDITIONS_DISPLAY_READY =
  'setExcursionStudentMedicalConditionsDisplayReady';
export const SET_EXCURSION_SELECTED_PROVIDERS = 'setExcursionSelectedProviders';
export const RESET_EXCURSION_LIST = 'resetExcursionList';
export const SET_INIT_MANDATORY_CHECKLIST = 'setInitMandatoryChecklist';
export const SET_PERSON_RESPONSIBLE = 'setPersonResponsible';
export const SET_ADDITIONAL_RISK_CONTROL_MITIGATION_STRATEGIES =
  'setAdditionalRiskControlMitigationStrategies';
export const SET_PREDEPARTURE = 'setPredeparture';
export const SET_EXCURSION_PREDEPARTURE_STRATEGIES = 'setExcursionPredepartureStrategies';
export const SET_ACTIVITY_SELECTION_VALID = 'setActivitySelectionValid';
export const SET_EXCURSION_AUDIT_LIST = 'setExcursionAuditList';
export const SET_STUDENT_MANAGEMENT_AUDIT_LIST = 'setStudentManagementAuditList';
export const SET_EXCURSION_CATEGORIES = 'setExcursionCategories';
export const SET_EXCURSION_STUDENT_INFO = 'setExcursionStudentInfo';
export const SET_STUDENT_ATTENDANCE_COUNT = 'setStudentAttendanceCount';

export const COMMON_SET_EXCURSIONS_LIST_LOADING = `common/${SET_EXCURSIONS_LIST_LOADING}`;
export const COMMON_SET_EXCURSION_TYPE_LOADED = `common/${SET_EXCURSION_TYPE_LOADED}`;
export const COMMON_SET_EXCURSION_DC_CATEGORIES = `common/${SET_EXCURSION_DC_CATEGORIES}`;
export const COMMON_SET_EXCURSION_DC_STATUS = `common/${SET_EXCURSION_DC_STATUS}`;
export const COMMON_SET_EXCURSION_CONFIRMATION_TICKED = `common/${SET_EXCURSION_CONFIRMATION_TICKED}`;
export const COMMON_SET_EXCURSION_DC_CLIENT_TEMPLATE = `common/${SET_EXCURSION_DC_CLIENT_TEMPLATE}`;
export const COMMON_SET_EXCURSION_DC_ADDITIONAL_INFORMATION_NOTES = `common/${SET_EXCURSION_DC_ADDITIONAL_INFORMATION_NOTES}`;
export const COMMON_SET_EXCURSION_DC_STAFF_SUPERVISOR_ATTENDANCE_COUNT = `common/${SET_EXCURSION_DC_STAFF_SUPERVISOR_ATTENDANCE_COUNT}`;
export const COMMON_SET_EXCURSION_DC_STUDENT_ATTENDANCE_COUNT = `common/${SET_EXCURSION_DC_STUDENT_ATTENDANCE_COUNT}`;
export const COMMON_SET_EXCURSION_DC_CATEGORY_COMPLETE_CHECKLIST = `common/${SET_EXCURSION_DC_CATEGORY_COMPLETE_CHECKLIST}`;
export const COMMON_SET_EXCURSION_DC_CATEGORY_TYPE_CLASS_COMPLETE_CHECKLIST = `common/${SET_EXCURSION_DC_CATEGORY_TYPE_CLASS_COMPLETE_CHECKLIST}`;
export const COMMON_SET_EXCURSION_INITIALIZED = `common/${SET_EXCURSION_INITIALIZED}`;
export const COMMON_SET_SHOW_EXCURSION_LOGO = `common/${SET_SHOW_EXCURSION_LOGO}`;
export const COMMON_SET_EXCURSION_STEP = `common/${SET_EXCURSION_STEP}`;
export const COMMON_SET_EXCURSION_NAME = `common/${SET_EXCURSION_NAME}`;
export const COMMON_SET_EXCURSION_RISK_ASSESSMENT_NOTES = `common/${SET_EXCURSION_RISK_ASSESSMENT_NOTES}`;
export const COMMON_SET_RISK_MATRIX_SELF_DEFINED_DETECTED = `common/${SET_RISK_MATRIX_SELF_DEFINED_DETECTED}`;
export const COMMON_SET_EXCURSION_RISK_MATRIX = `common/${SET_EXCURSION_RISK_MATRIX}`;
export const COMMON_SET_CLIENT_SETTING_RISK_MATRIX = `common/${SET_CLIENT_SETTING_RISK_MATRIX}`;
export const COMMON_SET_RISK_MATRIX_LIKELIHOOD = `common/${SET_RISK_MATRIX_LIKELIHOOD}`;
export const COMMON_SET_RISK_MATRIX_CONSEQUENCE = `common/${SET_RISK_MATRIX_CONSEQUENCE}`;
export const COMMON_SET_CLIENT_SETTING_RISK_MATRIX_RISK_RATING = `common/${SET_CLIENT_SETTING_RISK_MATRIX_RISK_RATING}`;
export const COMMON_SET_STAFF_TO_STUDENT_GENDER_RATIO_INFO = `common/${SET_STAFF_TO_STUDENT_GENDER_RATIO_INFO}`;
export const COMMON_SET_MANDATORY_CHECKLIST_BATCH_CONFIRMATION_AGREEMENT = `common/${SET_MANDATORY_CHECKLIST_BATCH_CONFIRMATION_AGREEMENT}`;
export const COMMON_SET_VENDOR_EXCURSION_ID = `common/${SET_VENDOR_EXCURSION_ID}`;
export const COMMON_SET_EXCURSION_TYPE = `common/${SET_EXCURSION_TYPE}`;
export const COMMON_SET_EXCURSION_JURISDICTION = `common/${SET_EXCURSION_JURISDICTION}`;
export const COMMON_SET_EXCURSION_START_DATE = `common/${SET_EXCURSION_START_DATE}`;
export const COMMON_SET_EXCURSION_END_DATE = `common/${SET_EXCURSION_END_DATE}`;
export const COMMON_SET_EXCURSION_CONFIRMATION = `common/${SET_EXCURSION_CONFIRMATION}`;
export const COMMON_RESET_CATEGORIES = `common/${RESET_CATEGORIES}`;
export const COMMON_SET_EXCURSION_TYPE_CHOICE_LIST = `common/${SET_EXCURSION_TYPE_CHOICE_LIST}`;
export const COMMON_SET_DEFAULT_JURISDICTION = `common/${SET_DEFAULT_JURISDICTION}`;
export const COMMON_SET_MANDATORY_CHECKLIST = `common/${SET_MANDATORY_CHECKLIST}`;
export const COMMON_RESET_EXCURSION_STORE_STATE = `common/${RESET_EXCURSION_STORE_STATE}`;
export const COMMON_SET_EXCURSION = `common/${SET_EXCURSION}`;
export const COMMON_SET_EXCURSION_CLIENT = `common/${SET_EXCURSION_CLIENT}`;
export const COMMON_SET_EXCURSION_STUDENT_FETCH_TRIGGERED = `common/${SET_EXCURSION_STUDENT_FETCH_TRIGGERED}`;
export const COMMON_SET_EXCURSION_STUDENT_MEDICAL_CONDITIONS = `common/${SET_EXCURSION_STUDENT_MEDICAL_CONDITIONS}`;
export const COMMON_SET_EXCURSION_STUDENT_MEDICAL_CONDITIONS_DISPLAY_READY = `common/${SET_EXCURSION_STUDENT_MEDICAL_CONDITIONS_DISPLAY_READY}`;
export const COMMON_SET_EXCURSION_SELECTED_PROVIDERS = `common/${SET_EXCURSION_SELECTED_PROVIDERS}`;
export const COMMON_RESET_EXCURSION_LIST = `common/${RESET_EXCURSION_LIST}`;
export const COMMON_SET_INIT_MANDATORY_CHECKLIST = `common/${SET_INIT_MANDATORY_CHECKLIST}`;
export const COMMON_SET_PERSON_RESPONSIBLE = `common/${SET_PERSON_RESPONSIBLE}`;
export const COMMON_SET_ADDITIONAL_RISK_CONTROL_MITIGATION_STRATEGIES = `common/${SET_ADDITIONAL_RISK_CONTROL_MITIGATION_STRATEGIES}`;
export const COMMON_SET_PREDEPARTURE = `common/${SET_PREDEPARTURE}`;
export const COMMON_SET_EXCURSION_PREDEPARTURE_STRATEGIES = `common/${SET_EXCURSION_PREDEPARTURE_STRATEGIES}`;
export const COMMON_SET_ACTIVITY_SELECTION_VALID = `common/${SET_ACTIVITY_SELECTION_VALID}`;
export const COMMON_SET_EXCURSION_AUDIT_LIST = `common/${SET_EXCURSION_AUDIT_LIST}`;
export const COMMON_SET_STUDENT_MANAGEMENT_AUDIT_LIST = `common/${SET_STUDENT_MANAGEMENT_AUDIT_LIST}`;
export const COMMON_SET_EXCURSION_CATEGORIES = `common/${SET_EXCURSION_CATEGORIES}`;
export const COMMON_SET_EXCURSION_STUDENT_INFO = `common/${SET_EXCURSION_STUDENT_INFO}`;
export const COMMON_SET_STUDENT_ATTENDANCE_COUNT = `common/${SET_STUDENT_ATTENDANCE_COUNT}`;
// student -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
export const SET_STUDENT_MANAGEMENT_SUBJECT_SETUP_DETECTED =
  'setStudentManagementSubjectSetupDetected';

export const COMMON_SET_STUDENT_MANAGEMENT_SUBJECT_SETUP_DETECTED = `common/${SET_STUDENT_MANAGEMENT_SUBJECT_SETUP_DETECTED}`;
// risk -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
export const ADD_TICKED_MANDATORY_CHECKLISTS = 'addTickedMandatoryChecklists';
export const DELETE_FROM_TICKED_RISK_CATEGORY_CHECKLISTS = 'deleteFromTickedRiskCategoryChecklists';
export const SET_RISK_CATEGORY_CHECKLISTS_TICKED = 'setRiskCategoryChecklistsTicked';
export const SET_ALL_RISKS = 'setAllRisks';
export const SET_CHECKLIST_FETCH_ACTION_IN_PROGRESS = 'setChecklistFetchActionInProgress';
export const SET_ALL_MANDATORY_CHECKLISTS_TICKED = 'setAllMandatoryChecklistsTicked';
export const SET_MANDATORY_CHECKLISTS_TICKED_BY_TITLE = 'setMandatoryChecklistsTickedByTitle';
export const SET_ALL_RISKS_INITIALIZED = 'setAllRisksInitialized';
export const SET_RISKS = 'setRisks';
export const RESET_RISKS_DISABLED = 'resetRisksDisabled';
export const SET_SELECT_RISK_CLASS = 'setSelectRiskClass';
export const SET_ACTIVITY_SELECT_RISK = 'setActivitySelectRisk';
export const SET_HIGH_RISK_TYPE = 'setHighRiskType';
export const SET_CLEAR_CREATE_RISK_ASSESSMENT = 'setClearCreateRiskAssessment';
export const SET_SELECTED_ACTIVITY_TYPE_FILTER = 'setSelectedActivityTypeFilter';
export const SET_SELECTED_ACTIVITY_CLASS_FILTER = 'setSelectedActivityClassFilter';
export const SET_SELECTED_LIKELIHOOD = 'setSelectedLikelihood';
export const SET_SELECTED_CONSEQUENCE = 'setSelectedConsequence';
export const SET_SELECTED_RATING = 'setSelectedRating';
export const SET_ADDITIONAL_INFORMATIONS = 'setAdditionalInformations';
export const SET_SHOW_EXCURSION_STEPPER = 'setShowExcursionStepper';
export const SET_MANDATORY_LOADING_COMPLETED = 'setMandatoryLoadingCompleted';
export const DELETE_RISK_COMPLETE_CHECKLIST = 'deleteRiskCompleteChecklist';
export const SET_RISK_COMPLETE_CHECKLIST = 'setRiskCompleteChecklist';

export const COMMON_ADD_TICKED_MANDATORY_CHECKLISTS = `common/${ADD_TICKED_MANDATORY_CHECKLISTS}`;
export const COMMON_DELETE_FROM_TICKED_RISK_CATEGORY_CHECKLISTS = `common/${DELETE_FROM_TICKED_RISK_CATEGORY_CHECKLISTS}`;
export const COMMON_SET_RISK_CATEGORY_CHECKLISTS_TICKED = `common/${SET_RISK_CATEGORY_CHECKLISTS_TICKED}`;
export const COMMON_SET_ALL_RISKS = `common/${SET_ALL_RISKS}`;
export const COMMON_SET_CHECKLIST_FETCH_ACTION_IN_PROGRESS = `common/${SET_CHECKLIST_FETCH_ACTION_IN_PROGRESS}`;
export const COMMON_SET_ALL_MANDATORY_CHECKLISTS_TICKED = `common/${SET_ALL_MANDATORY_CHECKLISTS_TICKED}`;
export const COMMON_SET_MANDATORY_CHECKLISTS_TICKED_BY_TITLE = `common/${SET_MANDATORY_CHECKLISTS_TICKED_BY_TITLE}`;
export const COMMON_SET_ALL_RISKS_INITIALIZED = `common/${SET_ALL_RISKS_INITIALIZED}`;
export const COMMON_SET_RISKS = `common/${SET_RISKS}`;
export const COMMON_RESET_RISKS_DISABLED = `common/${RESET_RISKS_DISABLED}`;
export const COMMON_SET_SELECT_RISK_CLASS = `common/${SET_SELECT_RISK_CLASS}`;
export const COMMON_SET_ACTIVITY_SELECT_RISK = `common/${SET_ACTIVITY_SELECT_RISK}`;
export const COMMON_SET_HIGH_RISK_TYPE = `common/${SET_HIGH_RISK_TYPE}`;
export const COMMON_SET_CLEAR_CREATE_RISK_ASSESSMENT = `common/${SET_CLEAR_CREATE_RISK_ASSESSMENT}`;
export const COMMON_SET_SELECTED_ACTIVITY_TYPE_FILTER = `common/${SET_SELECTED_ACTIVITY_TYPE_FILTER}`;
export const COMMON_SET_SELECTED_ACTIVITY_CLASS_FILTER = `common/${SET_SELECTED_ACTIVITY_CLASS_FILTER}`;
export const COMMON_SET_SELECTED_LIKELIHOOD = `common/${SET_SELECTED_LIKELIHOOD}`;
export const COMMON_SET_SELECTED_CONSEQUENCE = `common/${SET_SELECTED_CONSEQUENCE}`;
export const COMMON_SET_SELECTED_RATING = `common/${SET_SELECTED_RATING}`;
export const COMMON_SET_ADDITIONAL_INFORMATIONS = `common/${SET_ADDITIONAL_INFORMATIONS}`;
export const COMMON_SET_SHOW_EXCURSION_STEPPER = `common/${SET_SHOW_EXCURSION_STEPPER}`;
export const COMMON_SET_MANDATORY_LOADING_COMPLETED = `common/${SET_MANDATORY_LOADING_COMPLETED}`;
export const COMMON_DELETE_RISK_COMPLETE_CHECKLIST = `common/${DELETE_RISK_COMPLETE_CHECKLIST}`;
export const COMMON_SET_RISK_COMPLETE_CHECKLIST = `common/${SET_RISK_COMPLETE_CHECKLIST}`;
// provider -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
export const SET_ALL_PROVIDERS_INITIALIZED = 'setAllProvidersInitialized';
export const SET_PROVIDERS = 'setProviders';

export const COMMON_SET_ALL_PROVIDERS_INITIALIZED = `common/${SET_ALL_PROVIDERS_INITIALIZED}`;
export const COMMON_SET_PROVIDERS = `common/${SET_PROVIDERS}`;

// risks -=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-
export const SET_CLEAR_MODULE_CACHE_ENABLED = 'setClearModuleCacheEnabled';

export const RISKS_SET_CLEAR_MODULE_CACHE_ENABLED = `risks/${SET_CLEAR_MODULE_CACHE_ENABLED}`;
