import * as types from '@/store/mutationTypes';
import stbAPIGateway from '../../../services/stbAPIGateway/index';

export default {
  async setApiVersion({ rootGetters, commit }) {
    try {
      const axios = rootGetters['app/stbAxios'];

      const { version } = await stbAPIGateway(axios, process.env.API_URL).getHealthAlive();
      commit(types.SET_API_VERSION, version);
    } catch (err) {
      console.error(err);
    }
  },

  async setStbAxios({ commit }, axios) {
    commit('setStbAxios', axios);
  },

  setSilentAxiosError({ commit }, silentMode) {
    commit('setSilentAxiosError', silentMode);
  }
};
