export default (axios, baseURL) => async (contentEngine) => {
  const cbResponse = {
    error: true
  };
  const apiParam = {
    'ce-api-uri': contentEngine.ceApiUri,
    'x-site-uri': contentEngine.xSiteUri,
    'module-name': contentEngine.moduleName
  };

  const querystring = Object.keys(apiParam)
    .map((key) => `${key}=${encodeURIComponent(apiParam[key])}`)
    .join('&');

  const url = `/v1/cascading-risks/caches?${querystring}`;
  const axiosConfig = {
    method: 'DELETE',
    baseURL,
    url
  };
  try {
    const axiosResponse = await axios(axiosConfig);
    if (axiosResponse && axiosResponse.data.data) {
      cbResponse.error = false;
      cbResponse.data = axiosResponse.data.data;
    }
  } catch (apiErr) {
    if (
      apiErr &&
      apiErr.response &&
      apiErr.response.data &&
      `${apiErr.response.data.statusCode}` === '400' &&
      !apiErr.response.data.error
    ) {
      cbResponse.error = false;
    } else {
      cbResponse.error = true;
    }
    cbResponse.data = apiErr.response?.data;
  }
  return cbResponse;
};
