import { render, staticRenderFns } from "./ExportExcursionPolicy.vue?vue&type=template&id=844836e0&scoped=true"
import script from "./ExportExcursionPolicy.vue?vue&type=script&lang=js"
export * from "./ExportExcursionPolicy.vue?vue&type=script&lang=js"
import style0 from "./ExportExcursionPolicy.vue?vue&type=style&index=0&id=844836e0&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "844836e0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCheckbox,VContainer,VFlex,VLayout})
