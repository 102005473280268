import { render, staticRenderFns } from "./ViewPredepartureChecklist.vue?vue&type=template&id=45b65650&scoped=true"
import script from "./ViewPredepartureChecklist.vue?vue&type=script&lang=js"
export * from "./ViewPredepartureChecklist.vue?vue&type=script&lang=js"
import style0 from "./ViewPredepartureChecklist.vue?vue&type=style&index=0&id=45b65650&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45b65650",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VCheckbox,VFlex,VLayout,VList,VSubheader})
