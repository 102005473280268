<template>
  <div class="client-general">
    <div class="client-general-information">
      <p class="client-general-information_title">STB Client ID</p>
      <p class="client-general-information_content">{{ clientSidLabel }}</p>
    </div>
    <div class="client-general-information">
      <p class="client-general-information_title">CS Client ID</p>
      <p id="client-general-cs-client-id" class="client-general-information_content">
        {{ csClientId }}
      </p>
    </div>

    <v-list v-if="clientGeneralData.subdomain != ''">
      <v-list-item class="client-site-link-item">
        <a
          id="client-general-site-link"
          class="client-site-link"
          :href="getClientSiteUrl(clientGeneralData.subdomain)"
          target="_blank"
        >
          Go to the client site &nbsp;<v-icon
            class="client-site-link-icon"
            color="#c80063"
            size="small"
            >fa fa-external-link-alt</v-icon
          >
        </a>
      </v-list-item>
    </v-list>
    <v-spacer></v-spacer>
    <v-row v-if="clientSid != ''">
      <v-col>
        <div>Client Logo</div>
        <div class="crop-note">You can click the button below to select another Logo to crop</div>
      </v-col>
    </v-row>

    <image-upload
      id="client-general-image-upload"
      :clientSid="clientSid"
      :imageUrl="logo ? logo.logoAttachmentUrl : ''"
      :resetView="isClientSaved"
      :disableCropButton="cropButtonDisabled || saveActionInProgress"
      @imageChanged="onImageChanged"
      @imageBlobChanged="onImageBlobChanged"
    ></image-upload>
    <v-spacer></v-spacer>
    <v-row>
      <v-col xs12>
        <div>Client Logo Background Colour</div>
        <v-text-field
          solo
          v-model="logo.clientLogoBackgroundColor"
          class="excursion-input"
          id="txtClientLogoBackgroundColour"
          placeholder="Leave blank for default or enter in a hexadecimal color value"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col xs12>
        <div>Client Name <span class="red--text">*</span></div>
        <v-text-field
          solo
          v-model="clientGeneralData.clientName"
          class="excursion-input"
          placeholder="Client Name"
          required
          :error-messages="clientNameErrors"
          @input="$v.clientGeneralData.clientName.$touch()"
          @blur="$v.clientGeneralData.clientName.$touch()"
          id="txtClientName"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col xs12>
        <div>Client Subdomain <span class="red--text">*</span></div>
        <v-text-field
          solo
          v-model="clientGeneralData.subdomain"
          class="excursion-input"
          placeholder="Client Subdomain"
          required
          :error-messages="subdomainErrors"
          @input="onSubdomainChange"
          @blur="onSubdomainChange"
          id="txtSubdomain"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col xs12>
        <div>IdP URL</div>
        <v-text-field
          solo
          v-model="clientGeneralData.idpUrl"
          class="excursion-input"
          placeholder="IdP URL"
          :error-messages="idpUrlErrors"
          @input="$v.clientGeneralData.idpUrl.$touch()"
          @blur="$v.clientGeneralData.idpUrl.$touch()"
          id="txtIdpUrl"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col xs12>
        <div>Time Zone <span class="red--text">*</span></div>
        <timezone-selector
          id="client-general-time-zone-selector"
          v-model="clientGeneralData.timezone"
          :errorMessages="timezoneErrors"
          :hide-selected="hideSelected"
          @input="$v.clientGeneralData.timezone.$touch()"
          @blur="$v.clientGeneralData.timezone.$touch()"
        >
        </timezone-selector>
        <div
          id="client-general-timezone-error"
          class="pre-clients-error"
          v-if="0 < timezoneErrors.length"
        >
          Time Zone is required
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col xs12 class="client-individual-client-options">
        <div>Client Options</div>
        <v-card class="mx-auto" outlined>
          <v-card-text>
            <v-layout class="client-individual-checkbox-layout">
              <div class="client-individual-checkbox">
                <v-checkbox
                  :disabled="disableAdditional"
                  v-model="clientOptions.excursionAdditionalInformationEnabled"
                  class="client-individual-checkbox"
                  id="cbxEnableAdditionalInformation"
                ></v-checkbox>
              </div>
              <div
                id="client-individual-modal--enable-additional-information--label"
                class="client-individual-checkbox-label"
                @click="enableAdditionalInformation()"
              >
                Enable Additional Information
              </div>
            </v-layout>
            <v-layout class="client-individual-checkbox-layout">
              <div class="client-individual-checkbox">
                <v-checkbox
                  v-model="clientOptions.excursionHighRiskStudentMedicalEnabled"
                  id="cbxEnableHighRiskStudentMedical"
                  class="client-individual-checkbox"
                ></v-checkbox>
              </div>
              <div
                id="client-individual-modal--enable-high-risk-student-medical--label"
                class="client-individual-checkbox-label"
                @click="
                  clientOptions.excursionHighRiskStudentMedicalEnabled =
                    !clientOptions.excursionHighRiskStudentMedicalEnabled
                "
              >
                Enable High-Risk Student Medical
              </div>
            </v-layout>
            <v-layout class="client-individual-checkbox-layout">
              <div class="client-individual-checkbox">
                <v-checkbox
                  v-model="clientOptions.excursionPersonResponsibleEnabled"
                  id="cbxEnablePersonResponsible"
                  class="client-individual-checkbox"
                ></v-checkbox>
              </div>
              <div
                id="client-individual-modal--enable-person-responsible--label"
                class="client-individual-checkbox-label"
                @click="
                  clientOptions.excursionPersonResponsibleEnabled =
                    !clientOptions.excursionPersonResponsibleEnabled
                "
              >
                Enable Person Responsible
              </div>
            </v-layout>
            <v-layout class="client-individual-checkbox-layout">
              <div class="client-individual-checkbox">
                <v-checkbox
                  v-model="clientOptions.excursionPolicyViewActionEnabled"
                  class="client-individual-checkbox"
                  id="cbxEnablePolicyView"
                ></v-checkbox>
              </div>
              <div
                id="client-individual-modal--enable-policy-view--label"
                class="client-individual-checkbox-label"
                @click="
                  clientOptions.excursionPolicyViewActionEnabled =
                    !clientOptions.excursionPolicyViewActionEnabled
                "
              >
                Enable Policy View
              </div>
            </v-layout>
            <v-layout class="client-individual-checkbox-layout">
              <div class="client-individual-checkbox">
                <v-checkbox
                  v-model="clientOptions.excursionChecklistEnforcementBeforeSubmissionEnabled"
                  class="client-individual-checkbox"
                  id="cbxEnablePolicyView"
                ></v-checkbox>
              </div>
              <div
                id="client-individual-modal--enable-checklist-enforcement-before-submission--label"
                class="client-individual-checkbox-label"
                @click="
                  clientOptions.excursionChecklistEnforcementBeforeSubmissionEnabled =
                    !clientOptions.excursionChecklistEnforcementBeforeSubmissionEnabled
                "
              >
                Enable Checklist Enforcement Before Submission
              </div>
            </v-layout>
            <v-layout class="client-individual-checkbox-layout">
              <div class="client-individual-checkbox">
                <v-checkbox
                  v-model="clientOptions.excursionCopyActionEnabled"
                  class="client-individual-checkbox"
                  id="cbxExcursionCopyActionEnabled"
                ></v-checkbox>
              </div>
              <div
                id="client-individual-modal--enable-copy-excursion--label"
                class="client-individual-checkbox-label"
                @click="
                  clientOptions.excursionCopyActionEnabled =
                    !clientOptions.excursionCopyActionEnabled
                "
              >
                Enable Copy Excursion
              </div>
            </v-layout>
            <v-layout class="client-individual-checkbox-layout">
              <div class="client-individual-checkbox">
                <v-checkbox
                  v-model="clientOptions.idpRbaclEnabled"
                  class="client-individual-checkbox"
                  id="cbxIdpRbaclEnabled"
                ></v-checkbox>
              </div>
              <div
                id="client-individual-modal--enable-idp-rbacl--label"
                class="client-individual-checkbox-label"
                @click="clientOptions.idpRbaclEnabled = !clientOptions.idpRbaclEnabled"
              >
                Enable IdP RBACL
              </div>
            </v-layout>
            <v-layout class="client-individual-checkbox-layout">
              <div class="client-individual-checkbox">
                <v-checkbox
                  v-model="clientOptions.auth0LoginEnabled"
                  class="client-individual-checkbox"
                  id="cbxAuth0LoginEnabled"
                ></v-checkbox>
              </div>
              <div
                id="client-individual-modal--enable-idp-rbacl--label"
                class="client-individual-checkbox-label"
                @click="clientOptions.auth0LoginEnabled = !clientOptions.auth0LoginEnabled"
              >
                Enable Auth0 Login
              </div>
            </v-layout>
            <v-layout class="client-individual-checkbox-layout">
              <div class="client-individual-checkbox">
                <v-checkbox
                  v-model="clientOptions.publicSchoolFeatureSetEnabled"
                  class="client-individual-checkbox"
                  id="cbxPublicSchoolFeaturesetEnabled"
                ></v-checkbox>
              </div>
              <div
                id="client-individual-modal--enable-public-school-feature-set--label"
                class="client-individual-checkbox-label"
                for="client-individual-modal--public-school-feature-set-enabled"
              >
                Enable Public School Feature Set
              </div>
            </v-layout>
            <v-layout class="client-individual-checkbox-layout">
              <div class="client-individual-checkbox">
                <v-checkbox
                  v-model="clientOptions.excursionInherentRiskRatingEnabled"
                  class="client-individual-checkbox"
                  id="cbxExcursionInherentRiskRatingEnabled"
                ></v-checkbox>
              </div>
              <div
                id="client-individual-modal--enable-excursion-inherent-risk-rating--label"
                class="client-individual-checkbox-label"
                for="client-individual-modal--excursion-inherent-risk-rating-enabled"
              >
                Enable Excursion Inherent Risk Rating
              </div>
            </v-layout>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-spacer></v-spacer>
    <v-row>
      <v-col xs12 class="d-flex justify-end align-center">
        <cs-button
          v-if="editMode === 'update'"
          id="btnSaveGeneral"
          primary
          label="Save General"
          @click="onSave()"
          :disabled="saveButtonDisabled"
          :loading="saveActionInProgress"
        ></cs-button>
      </v-col>
    </v-row>
    <cs-form-dialog
      v-model="noticeDialogDisplayed"
      id="dlg-client-ind-editor-generic"
      bodyId="dlg-client-ind-editor-generic-body"
      heading="Client General Editor"
      :primaryAction="{
        label: 'OK',
        eventName: 'primary-click'
      }"
      @primary-click="onDialogOkBtn"
    >
      <template v-slot:cs-form-dialog-content>
        <div v-safe-html="noticeDialogMessage"></div>
        <v-spacer></v-spacer>
        <div class="warning-message" v-safe-html="noticeDialogWarningMessage"></div>
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
import { CSBase } from '@complispace/cs-design-system';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { mapGetters } from 'vuex';
import * as types from '@/store/mutationTypes';
import { validateUrl, validateSubdomain } from './validation';
import ImageUpload from './ImageUpload';
import attachmentCategory from '../lib/const/attachmentCategory';
import TimezoneSelector from './TimezoneSelector';

export default {
  mixins: [validationMixin],

  name: 'ClientGeneral',

  components: {
    ImageUpload,
    TimezoneSelector
  },

  extends: CSBase,

  props: {
    clientSid: { type: String, required: false, default: '' },
    // @TODO to allow SaveAll to show domain conflict. To be removed after consolidation
    showDomainConflict: { type: Boolean, required: false, default: false }
  },

  watch: {
    async clientSid() {
      this.initPage(this.clientSid);
    },
    clientGeneralView() {
      this.reloadData();
    },

    clientGeneralData: {
      handler() {
        this.$emit('clientGeneralChanged', this.clientGeneralData);
      },
      deep: true
    },

    logo: {
      handler() {
        this.$emit('clientLogoChanged', this.logo);
      },
      deep: true
    },

    clientOptions: {
      handler() {
        this.$emit('clientOptionsChanged', this.clientOptions);
      },
      deep: true
    },
    // @TODO to allow SaveAll to show domain conflict. To be removed after consolidation
    showDomainConflict() {
      this.subdomainConflicted = this.showDomainConflict;
    }
  },

  data() {
    return {
      editMode: { type: String, required: false, default: 'create' },
      csClientId: '-',
      subdomainConflicted: false,
      clientGeneralData: {
        clientId: '',
        clientName: '',
        subdomain: '',
        idpUrl: '',
        timezone: ''
      },
      clientOptions: {
        excursionAdditionalInformationEnabled: false,
        excursionChecklistEnforcementBeforeSubmissionEnabled: false,
        excursionCopyActionEnabled: false,
        excursionHighRiskStudentMedicalEnabled: false,
        excursionPersonResponsibleEnabled: false,
        excursionPolicyViewActionEnabled: false,
        excursionInherentRiskRatingEnabled: false,
        idpRbaclEnabled: false,
        auth0LoginEnabled: false,
        publicSchoolFeatureSetEnabled: false
      },
      logo: {
        imgSrc: '',
        logoFileChanged: false,
        clientLogoBackgroundColor: '',
        logoAttachmentUrl: '',
        logoFile: undefined
      },
      cropButtonDisabled: false,
      saveActionInProgress: false,
      noticeDialogDisplayed: false,
      noticeDialogMessage: '',
      noticeDialogWarningMessage: '',
      isErrorNotice: false,
      isClientSaved: false,
      // @TODO to check how this is used
      hideSelected: false
    };
  },

  computed: {
    clientSidLabel() {
      return this.clientSid > 0 ? this.clientSid : '-';
    },
    ...mapGetters({
      clientGeneralView: 'clients/clientGeneralView'
    }),
    clientNameErrors() {
      const errors = [];
      if (!this.$v.clientGeneralData.clientName.$dirty) return errors;
      if (!this.$v.clientGeneralData.clientName.required) {
        errors.push('Client Name is required');
      }
      return errors;
    },
    timezoneErrors() {
      const errors = [];
      if (!this.$v.clientGeneralData.timezone.$dirty) return errors;
      if (!this.$v.clientGeneralData.timezone.required) {
        errors.push('Time Zone is required');
      }
      return errors;
    },
    subdomainErrors() {
      const errors = [];
      if (!this.$v.clientGeneralData.subdomain.$dirty) return errors;

      if (!this.$v.clientGeneralData.subdomain.required) {
        errors.push('Client Subdomain is required');
      } else if (!this.$v.clientGeneralData.subdomain.validateSubdomain) {
        errors.push('Invalid Client Subdomain');
      } else if (this.subdomainConflicted) {
        errors.push('Client Subdomain conflicted');
      }
      return errors;
    },
    idpUrlErrors() {
      const errors = [];
      if (!this.$v.clientGeneralData.idpUrl.$dirty) return errors;
      if (!this.$v.clientGeneralData.idpUrl.validateIdpUrl) {
        errors.push('Invalid IdP URL');
      }
      return errors;
    },
    saveButtonDisabled() {
      return this.$v.$invalid || this.saveActionInProgress;
    },
    disableAdditional() {
      return this.editMode === 'update' && this.clientOptions.excursionAdditionalInformationEnabled;
    }
  },

  validations: {
    clientGeneralData: {
      clientName: {
        required
      },
      timezone: {
        required
      },
      subdomain: {
        required,
        validateSubdomain
      },
      idpUrl: {
        validateIdpUrl: validateUrl
      }
    }
  },

  created() {},

  async mounted() {
    this.initPage(this.clientSid);
  },

  methods: {
    async initPage(clientSid) {
      if (clientSid !== '') {
        this.editMode = 'update';
        await this.fetchClientGeneral();
        this.reloadData();
      }
    },

    getClientSiteUrl(subdomain) {
      const { protocol, host } = window.location;

      return `${protocol}//${subdomain}.${host}`;
    },

    async fetchClientGeneral() {
      try {
        if (this.clientSid) {
          this.setLoading(true);
          await this.$store.dispatch('clients/fetchClientGeneral', this.clientSid);
          this.clearLoading();
        }
      } catch (e) {
        this.clearLoading();
      }
    },

    onImageChanged(logoFile) {
      this.logo.logoFileChanged = true;
      this.logo.logoFile = logoFile;
    },

    onImageBlobChanged(logoFile, fileBlob) {
      this.logo.logoFileChanged = true;
      this.logo.logoFile = logoFile;
      this.logo.blob = fileBlob;
    },

    async saveLogo(clientSid) {
      if (!this.logo.logoFileChanged) {
        return {};
      }
      // post image
      const requestFormData = new FormData();
      requestFormData.append('category', attachmentCategory.LOGO);

      if (!this.logo.blob && this.logo.logoFile !== '') {
        requestFormData.append('file', this.logo.logoFile);
      } else {
        requestFormData.append('file', this.logo.blob);
      }
      const argHash = {
        sid: this.clientSid || clientSid,
        payload: requestFormData,
        store: this.$store
      };
      const cbResponse = await this.$app.stbApiAdapter.postClientAttachmentCategories(argHash);
      return cbResponse?.data;
    },

    enableAdditionalInformation() {
      if (!this.disableAdditional) {
        this.clientOptions.excursionAdditionalInformationEnabled =
          !this.clientOptions.excursionAdditionalInformationEnabled;
      }
    },

    async saveClientGeneral() {
      const clientGeneral = { ...this.clientGeneralData };
      clientGeneral.clientSid = this.clientSid;
      clientGeneral.clientOptions = { ...this.clientOptions };
      clientGeneral.clientLogo = {
        clientLogoBackgroundColor: this.logo.clientLogoBackgroundColor,
        logoAttachmentUrl: this.logo.logoAttachmentUrl
      };
      const patchRes = await this.$store.dispatch('clients/patchClientGeneral', clientGeneral);

      return patchRes;
    },

    async onSave() {
      this.saveActionInProgress = true;
      this.subdomainConflicted = false;
      try {
        this.$store.commit('app/setSilentAxiosError', true);
        const saveGeneralRes = await this.saveClientGeneral();
        if (!saveGeneralRes || saveGeneralRes.status !== 'success') {
          this.saveActionInProgress = false;
          this.handleSaveError(saveGeneralRes.error);
          return;
        }
        if (this.logo.logoFileChanged) {
          const saveLogoRes = await this.saveLogo();
          if (!saveLogoRes || saveLogoRes.error) {
            this.alertSaveResult('error', 'General Setting saved, but logo failed to be saved.');
            this.reloadData();
            this.saveActionInProgress = false;
            return;
          }
        }
        this.alertSaveResult('success');
        this.fetchClientGeneral();
        this.$emit('clientGeneralSaved', true);
      } catch (e) {
        this.handleSaveError(e);
      } finally {
        this.cropButtonDisabled = false;
        this.saveActionInProgress = false;
      }
    },

    handleSaveError(error) {
      let displayMessage = error.message || '';
      let warningMessage = '';
      try {
        const errorMessages = error.message?.split(':') || [];
        if (errorMessages.length > 3) {
          // eslint-disable-next-line prefer-destructuring
          displayMessage = `Subdomain Conflict: ${errorMessages[2]}`;
          warningMessage = `errorRefId: ${errorMessages[1]}`;
        }
        if (error.statusCode === 409) {
          this.subdomainConflicted = true;
        }
        this.alertSaveResult(
          'error',
          `Failed to save Client information. <br/> ${displayMessage}`,
          warningMessage
        );
      } finally {
        this.$store.commit('app/setSilentAxiosError', true);
      }
    },

    alertSaveResult(status, errorMessage, warningMessage) {
      this.$store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
      this.isErrorNotice = false;
      this.noticeDialogWarningMessage = '';
      if (status === 'success') {
        // success
        this.noticeDialogMessage = 'General Setting has been saved.';
        this.noticeDialogWarningMessage = warningMessage || '';
        this.isClientSaved = true;
      } else {
        // failed
        this.isClientSaved = false;
        this.isErrorNotice = true;
        this.noticeDialogMessage = errorMessage || `Failed to save Client information.`;
        this.noticeDialogWarningMessage = warningMessage || '';
      }
      this.noticeDialogDisplayed = true;
    },

    onDialogOkBtn() {
      this.noticeDialogDisplayed = false;
      this.isErrorNotice = false;
    },

    reloadData() {
      this.clear();
      const { clientName, subdomain, idpUrl, timezone } = this.clientGeneralView;
      this.clientGeneralData = { clientName, subdomain, idpUrl, timezone };
      this.logo = { ...this.logo, ...this.clientGeneralView.clientLogo };
      this.clientOptions = { ...this.clientOptions, ...this.clientGeneralView.clientOptions };
    },

    clear() {
      this.cropButtonDisabled = false;
    },

    onSubdomainChange() {
      this.$v.clientGeneralData.subdomain.$touch();
      this.subdomainConflicted = false;
      this.$emit('subdomainChanged', this.clientGeneralData.subdomain);
    }
  }
};
</script>
<style scoped>
.client-general-information {
  display: flex;
}

.client-general-information p {
  margin-bottom: 0;
}

.client-general-information_title {
  width: 15%;
}

.client-general-information_content {
  width: 85%;
}

.warning-message {
  font-size: 10pt;
  padding-top: 12px;
  color: var(--v-error-base);
  font-style: italic;
}
</style>
