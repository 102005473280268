<template>
  <div>
    <cs-button
      class="safe-update-provider"
      :disabled="!providerUpdateActionEnabled || actionDisabled || !buttonUpdateProvidersEnabled"
      @click="updateProviders"
      id="btnUpdateProviders"
      v-if="providerUpdateActionDisplayed"
      :loading="loaderDisplayed"
      label="Update Providers"
    ></cs-button>

    <cs-form-dialog
      v-model="dialog"
      id="dlg-update-providers"
      bodyId="dlg-update-providers-body"
      heading="Update Providers"
      :primaryAction="{
        label: 'OK',
        eventName: 'primary-click'
      }"
      @primary-click="dialog = false"
    >
      <template v-slot:cs-form-dialog-content>
        {{ updateProvidersStatusText }}
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
import * as types from '@/store/mutationTypes';

export default {
  props: {
    buttonUpdateProvidersEnabled: Boolean
  },
  data() {
    return {
      dialog: false,
      actionDisabled: false,
      loaderDisplayed: false,
      updateProvidersStatusText: ''
    };
  },
  computed: {
    providerUpdateActionEnabled() {
      return this.$store.state.common.providerUpdateActionEnabled;
    },
    providerUpdateActionDisplayed() {
      return this.$store.state.common.providerUpdateActionDisplayed;
    }
  },
  methods: {
    async updateProviders() {
      this.actionDisabled = true;
      this.loaderDisplayed = true;

      const argHash = {
        store: this.$store,
        queryParam: this.$route.query
      };

      const providers = await this.$app.stbApiAdapter.postProviders(argHash);
      this.dialog = true;
      this.actionDisabled = false;
      this.loaderDisplayed = false;

      if (typeof providers.data !== 'undefined') {
        this.$store.commit(types.COMMON_SET_PROVIDERS, providers.data);
        this.updateProvidersStatusText = 'Update success';
      } else {
        this.updateProvidersStatusText = 'Update failed';
      }
    }
  }
};
</script>
