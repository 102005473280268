<template>
  <div>
    <cs-app
      class="stb-app"
      :showHeader="showHeader"
      :appLogoUri="stbHorizontalLogo"
      :headerProfile="headerProfile"
      :feedbackBtn="{ id: 'btn-feedback', toolTip: 'Feedback' }"
      @feedback-btn-click="registerFeedbackLinkClickEvent($event, '/feedback')"
      :helpBtn="helpBtn"
      :settingsBtn="settingsBtn"
      :sideNavWidth="sideNavWidth"
      :version="version"
      homeRoute="/excursions"
    >
      <template v-slot:header-left>
        <div class="topbar-actions">
          <v-icon size="18" color="white" class="user-info-icon" @click="userInfoDisplayed = true"
            >fas fa-user</v-icon
          >
          <cs-button
            v-if="textButtonDisplayed(excursionCreationTextButton)"
            :id="excursionCreationTextButton.name"
            :href="excursionCreationTextButton.to"
            :label="excursionCreationTextButton.label"
            primary
          ></cs-button>
        </div>
      </template>

      <template v-slot:side-nav>
        <app-side-nav></app-side-nav>
      </template>

      <template v-slot:router-view>
        <v-slide-y-transition>
          <div :class="contentHtmlClassConcat">
            <router-view @updateNav="NavTreeUpdate"></router-view>
          </div>
        </v-slide-y-transition>
      </template>

      <template v-slot:footer>
        <div :class="footerClass">
          <v-layout class="demo-note" v-if="isDemoEnvironment">
            PLEASE NOTE: This is a demo site only, please do not enter any personal information.
            Information entered here may be deleted at any time.
          </v-layout>
          <v-layout align-center justify-center column fill-height>
            <v-img :src="complispaceLogo" max-width="200px" max-height="55px" class="img"> </v-img>
            <span>
              &copy;{{ getCopyrightYearRange() }} CompliSpace (v{{ version }}). All rights reserved.
            </span>
          </v-layout>
        </div>
      </template>
    </cs-app>
    <cs-form-dialog
      v-model="isResponseWithError"
      id="dlg-response-with-error"
      bodyId="dlg-body-response-with-error"
      heading="Server Error"
      :primaryAction="{
        label: 'Close',
        eventName: 'primary-click'
      }"
      @primary-click="closeErrorMessageDialogBtnClick"
    >
      <template v-slot:cs-form-dialog-content>
        <v-card>
          <v-card-text
            >There seems to be an issue with the server, please try again later or log a support
            request.
            <div v-safe-html="debugReferenceMessage"></div>
          </v-card-text>
        </v-card>
      </template>
    </cs-form-dialog>
    <cs-form-dialog
      v-model="userInfoDisplayed"
      id="dlg-user-info"
      bodyId="dlg-body-user-info"
      heading="User Information"
      :primaryAction="{
        label: 'Close',
        eventName: 'primary-click'
      }"
      @primary-click="userInfoDisplayed = false"
    >
      <template v-slot:cs-form-dialog-content>
        <v-card>
          <v-card-text>
            <div v-safe-html="userInfo"></div>
          </v-card-text>
        </v-card>
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import capitalize from 'lodash/capitalize';
import sessionManager from '@/store/modules/session/sessionManager';
import * as types from './store/mutationTypes';
import defaultClientLogo from '../public/YourSchoolLogo.png';
import trackableEvent from './lib/const/trackableEvent';
import complispaceLogo from '../public/ComplispaceLogo.png';
import stbHorizontalLogo from '../public/StbHorizontalNavLogo.png';
import { populateErrorMessage } from './lib/errorMessages';
import topToolbarTextButtons from './lib/const/topToolbarTextButtons';
// Downloadable file names can have spaces and/or special characters
// since they are the default names saved by users
require('../public/Risk_Assessment_Guide.pdf');
require('../public/SafeTripBuilder - Risk Categories Manifest.pdf');
require('../public/User_Import_Template.csv');

const packageParam = require('../package.json');

export default {
  data() {
    return {
      vendorIntegrationWindowViewEnabled: false,
      fixed: true,
      excursionTypes: ['Type1', 'Type2'],
      riskCategories: [
        'High-Risk Student Medical Conditions',
        'Transport',
        'Venues',
        'Food and Beverage',
        'Activity'
      ],
      riskClasses: ['Swimming', 'Boarding'],
      excursionActive: false,
      complispaceLogo,
      stbHorizontalLogo,
      defaultClientLogo,
      userInfoDisplayed: false
    };
  },

  computed: {
    ...mapState({
      userProfile: (state) => state.authorisation.userProfile,
      userDisplayName: (state) => state.common.userDisplayName,
      userEmail: (state) => state.common.userEmail,
      excursionCreationButtonDisplayed: (state) => state.common.excursionCreationButtonDisplayed,
      excursionBuilderButtonDisplayed: (state) => state.common.excursionBuilderButtonDisplayed,
      userInitials: (state) => state.common.userInitials,
      isInitClientConfigInProgress: (state) => state.authorisation.isInitClientConfigInProgress
    }),
    ...mapGetters({
      isLoggedIn: 'authorisation/isLoggedIn',
      auth0Enabled: 'authorisation/auth0Enabled',
      silentAxiosError: 'app/silentAxiosError'
    }),
    userInfo() {
      return `<div class="profile-tooltip-content">${JSON.stringify(
        this.userProfile,
        null,
        2
      )}</div>`;
    },
    headerProfile() {
      const userNames = (this.userDisplayName && this.userDisplayName.split(' ')) || [''];
      return {
        user: {
          givenName: capitalize(userNames[0]),
          surname: capitalize(userNames[1] || userNames[0]),
          email: this.userEmail
        },
        actions: {
          logout: {
            action: this.logout
          }
        }
      };
    },
    sideNavWidth() {
      if (this.isLoggedIn && !this.vendorIntegrationWindowViewEnabled) {
        return 400;
      }

      return 0;
    },
    helpBtn() {
      return {
        action: this.registerSupportLinkClickEvent
      };
    },

    settingsBtn() {
      const { clientSiteSettingsButtonDiplayed } = this.$store.state.common;
      if (clientSiteSettingsButtonDiplayed) {
        return {
          action: this.handleSettingsAction
        };
      }
      return null;
    },

    version() {
      const { bundleVersion } = packageParam;
      const uiVersion = `${packageParam.version}${packageParam.hotfixIndicator}`;
      const dashApiVersion = this.$store.state.app.apiVersion
        ? `-${this.$store.state.app.apiVersion}`
        : '';

      return `${bundleVersion}#${uiVersion}${dashApiVersion}`;
    },

    topToolbarTextButtonList() {
      return (
        topToolbarTextButtons &&
        topToolbarTextButtons.filter((item) => {
          return `${item.name}` !== 'Clients' && `${item.name}` !== 'SafeTripBuilder';
        })
      );
    },

    excursionCreationTextButton() {
      return (
        topToolbarTextButtons &&
        topToolbarTextButtons.find((item) => {
          return `${item.name}` === 'SafeTripBuilder';
        })
      );
    },

    isResponseWithError: {
      get() {
        return this.$store.state.common.isResponseWithError && !this.silentAxiosError;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, value);
      }
    },
    debugReferenceMessage() {
      let dialogMessage = '';
      dialogMessage = populateErrorMessage({ message: dialogMessage, store: this.$store });
      if (dialogMessage === '') {
        dialogMessage += `<p class="v-card__text debug-error-message mt-3">Unknown Error!</p>`;
      }
      return dialogMessage;
    },
    isDemoEnvironment() {
      return this.$app.env.APP_ENV === 'demo';
    },
    contentHtmlClassConcat() {
      if (!this.vendorIntegrationWindowViewEnabled || this.isOnLoginPage) {
        return '';
      }
      let localContentHtmlClassConcat = 'content';

      switch (this.$route.name) {
        case 'ClientAdminLayout':
          localContentHtmlClassConcat += ' full-width-layout';
          break;

        case 'SupportFeedbackLayout':
          localContentHtmlClassConcat += ' full-width-layout iframe-container-layout';
          break;

        default:
          break;
      }

      return localContentHtmlClassConcat;
    },
    showHeader() {
      return this.isLoggedIn && !this.vendorIntegrationWindowViewEnabled;
    },
    isOnLoginPage() {
      return this.$route.path.toLowerCase() === '/login';
    },
    footerClass() {
      return this.isOnLoginPage && !this.auth0Enabled
        ? 'content stb-login-footer'
        : 'content stb-auth0-login-footer';
    }
  },
  created() {
    this.$store.dispatch('session/init', this.$app.axiosInstance);
  },
  async mounted() {
    const { eventPluginCommonAdapter, stbApiAdapter } = this.$app;
    const argHash = {
      queryParam: {},
      eventPluginCommonAdapter,
      stbApiAdapter
    };

    if (this.isOnLoginPage) {
      await this.$store.dispatch('common/initClientConfig', argHash);
    }
    await this.$store.dispatch('app/setApiVersion');

    this.$app.eventPluginCommonAdapter.initPluginBatch();
    const { query } = this.$route;

    // TODO: Use different variable to show/hide menu
    this.vendorIntegrationWindowViewEnabled = false;

    if (query.referrer || query['vendor-sid'] || query['oauth-signature'] || this.isOnLoginPage) {
      this.vendorIntegrationWindowViewEnabled = true;
    }
  },

  methods: {
    closeErrorMessageDialogBtnClick() {
      this.isResponseWithError = false;
      this.responseWithErrorMessage = '';
    },
    NavTreeUpdate(details) {
      this.$refs.nav.treeUpdate(details);
    },
    getLinkItemId(linkItem) {
      return `client-admin-layout--menu-item--link-${linkItem}`;
    },
    logout() {
      this.$store.dispatch('authorisation/logout', this.$route.query);
    },

    redirect(path) {
      if (this.$router.currentRoute.path !== path) {
        this.$router.push(path);
      }
    },

    handleSettingsAction() {
      if (sessionManager.getClientSubdomain() !== '') {
        this.redirect('/admin/clients/users');
      } else {
        this.redirect('/admin/clients');
      }
    },

    registerFeedbackLinkClickEvent(jsEvent, href) {
      jsEvent?.preventDefault();
      const that = this;
      const trackableEventBaseArgHash = {
        defaultParams: true
      };
      const trackableEventOptionArgHash = {
        sentImmediately: true
      };
      const registerFeedbackLinkClickEventCb = () => {
        // This callback is used to prevent tracking interruption by page redirection
        that.$app.window.location = href;
      };

      this.$app.eventPluginCommonAdapter.registerTrackableEvent(
        trackableEvent.FEEDBACK_LINK_CLICK,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash,
        registerFeedbackLinkClickEventCb
      );

      this.redirect(href);
    },

    registerSupportLinkClickEvent() {
      const trackableEventBaseArgHash = {
        defaultParams: true
      };

      this.$app.eventPluginCommonAdapter.registerTrackableEvent(
        trackableEvent.SUPPORT_LINK_CLICK,
        trackableEventBaseArgHash
      );

      window.open(
        'https://complispace.zendesk.com/hc/en-us/categories/900001334623-Safe-Excursions-PlanCheckGo-SafeTripBuilder-'
      );
    },

    resetStoreState() {
      this.$store.commit(types.COMMON_SET_EXCURSION_TYPE, '');
      const excludedAttributeList = [
        'allProviders',
        'allRisks',
        'apiDataDefaultJurisdiction',
        'excursionTypeChoiceList',
        'apiParam',
        'excursionDc',
        'excursionAdditionalInformationEnabled',
        'logoAttachmentUrl',
        'vendorExcursionSidInputEnabled',
        'clientUsersTabDisplayed',
        'usersTabDisplayed',
        'clientsTabDisplayed',
        'clientSiteSettingsButtonDiplayed',
        'clientSelfGroupManagementDisplayed',
        'vendorGroupIdDisplayEnabled',
        'publicSchoolFeatureSetEnabled',
        'riskCategoryManagementMenuItemDisplayed',
        'operationManagementMenuItemDisplayed',
        'studentManagementSubjectManagementDisplayed'
      ];
      const argHash = {
        excludedAttributeList,
        storeModuleName: ''
      };
      this.$store.commit(types.COMMON_RESET_STORE_MODULE_STATE, argHash);
      window.location.reload();
    },
    textButtonDisplayed(textButton) {
      let localTextButtonDisplayed = false;

      if (
        textButton &&
        ((this.excursionBuilderButtonDisplayed && textButton.name === 'ExcursionBuilder') ||
          (this.excursionCreationButtonDisplayed && textButton.name === 'SafeTripBuilder') ||
          (textButton.name !== 'ExcursionBuilder' && textButton.name !== 'SafeTripBuilder'))
      ) {
        localTextButtonDisplayed = true;
      }

      return localTextButtonDisplayed;
    },
    redirectToUrl(url, newWindowEnabled) {
      if (newWindowEnabled) {
        window.open(url);
      } else {
        window.location.href = url;
      }
    },
    getCopyrightYearRange() {
      const startYear = '2018';
      return `${startYear}-${new Date().getFullYear().toString()}`;
    }
  },

  watch: {
    async version() {
      if (!this.$store.state.app.apiVersion) {
        await this.$store.dispatch('app/setApiVersion');
      }
    },
    async isInitClientConfigInProgress(newVal, oldVal) {
      if (oldVal && !newVal) {
        const isVendorAuthScheme =
          !!this.$route.query['vendor-sid'] && !!this.$route.query['oauth-signature'];
        if (!this.isLoggedIn || isVendorAuthScheme) return;
        await this.$store.dispatch('authorisation/getUserProfile', this.$app.stbApiAdapter);
      }
    }
  }
};
</script>
<style>
.admin-management-container {
  margin: 50px;
}
.debug-error-message {
  color: red;
  margin: 0px 0px !important;
  padding: 0px 0px !important;
}
.topbar-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.user-info-icon {
  margin-top: 10px;
  margin-right: 15px;
}
.profile-tooltip-content {
  max-width: 420px;
  padding-top: 2px;
  padding-left: 2px;
  padding-right: 2px;
  word-wrap: break-word;
  clear: right;
  white-space: pre;
  line-height: 1;
}
.stb-login-footer {
  min-width: 100%;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stb-app .cs-footer {
  padding: 0px;
}
.stb-auth0-login-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100%;
}
</style>
<style scoped>
.demo-note {
  padding: 10px;
}
</style>
