<template>
  <div style="width: 100%">
    <div v-if="studentMedicalConditions && 0 < studentMedicalConditions.length">
      <v-alert color="#ff7f32" outlined type="warning" style="margin-bottom: 15px">
        <div>
          The selected students for this excursion have the following medical conditions:
          <br /><br />
          <ul class="high-risk-student-medical-conditions">
            <li
              :class="medical.supported ? 'high-risk-student-medical-supported' : ''"
              :key="medicalIndex"
              v-for="(medical, medicalIndex) in studentMedicalConditions"
            >
              {{ medical.name }}
            </li>
          </ul>
        </div>
      </v-alert>
      <v-alert color="#c80063" icon="info" outlined style="margin-bottom: 15px">
        <div>
          Only the highlighted medical conditions are supported and available in the High-Risk
          Medical Conditions drop-down. For medical conditions that are not supported, please follow
          your School's process for managing student high-risk medical conditions.
        </div>
      </v-alert>
    </div>
    <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
    <select
      class="js-example-basic-single safe-select2-dropdown"
      name="selectControl"
      :id="objId"
      :disabled="!formControlEnabled"
    >
      <option value></option>
      <optgroup label="High-Risk Student Medical">
        <option
          v-for="(riskClass, cindex) in getRiskClasses()"
          :key="cindex"
          :value="riskClass.name"
          :disabled="riskClass.disabled"
          :title="riskClass.typeName"
        >
          {{ riskClass.name }}
        </option>
      </optgroup>
    </select>
    <risk-class
      :selectedriskClasses="selectedRiskClasses"
      :formControlEnabled="formControlEnabled"
      :riskTypeLabelDisplayed="false"
      :hasUpdatedMedicalCondition="hasUpdatedMedicalCondition"
      @changeRiskCategoryDone="changeRiskCategory"
      @hasUpdatedMedicalCondition="hasUpdatedMedicalCondition = true"
    ></risk-class>
    <cs-form-dialog
      v-model="warningUpdateMedicalConditionDialogDisplayed"
      id="dlg-update-medical-condition-warning"
      bodyId="dlg-body-update-medical-condition-warning"
      heading="Update Student Medical Condition"
      :primaryAction="{
        label: 'Confirm',
        eventName: 'primary-click'
      }"
      @primary-click="updateRiskClass"
      :secondaryAction1="{
        label: 'Cancel',
        eventName: 'secondary1-click'
      }"
      @secondary1-click="warningUpdateMedicalConditionDialogDisplayed = false"
    >
      <template v-slot:cs-form-dialog-content>
        <v-card>
          <v-card-text>
            This action may populate a new checklist. Do you want to continue?
          </v-card-text>
        </v-card>
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
// TODO: Use dependency injections instead of directly importing dependencies
import 'select2';

export default {
  props: ['name', 'id', 'formControlEnabled'],
  data() {
    return {
      warningUpdateMedicalConditionDialogDisplayed: false,
      selectedRiskClass: '',
      selectedRiskTypeName: '',
      hasUpdatedMedicalCondition: false
    };
  },
  computed: {
    placeholder() {
      return `Add ${this.name} Type`;
    },
    objId() {
      return `selectControl${this.id}`;
    },
    categoryName() {
      return this.name;
    },
    selectedRiskClasses() {
      return this.$store.getters['common/selectedClassByCategoryName'](this.name);
    },
    riskTypes() {
      return this.$store.getters['common/getListRiskTypesByCategoryName'](this.name);
    },
    studentMedicalConditions() {
      return this.$store.state.common.excursionDc.computed.studentMedicalConditions;
    }
  },
  methods: {
    changeRiskCategory() {
      this.$emit('changeRiskCategoryDone', this.name);
    },
    getRiskClasses() {
      const riskClasses = [];
      if (this.riskTypes && this.riskTypes.length > 0) {
        this.riskTypes.forEach((t) => {
          if (t.classes && t.classes.length > 0) {
            t.classes.forEach((c) => {
              const localC = c;
              localC.typeName = t.name;
              riskClasses.push(localC);
            });
          }
        });
      }
      riskClasses.sort((a, b) => a.name.localeCompare(b.name));
      return riskClasses;
    },
    updateRiskClass() {
      const $selector = $(`#${this.objId}`);
      $selector.trigger('change.select2');
      this.$store.dispatch('common/changeSelectRiskClass', {
        riskClass: {
          name: this.selectedRiskClass
        },
        riskTypeName: this.selectedRiskTypeName,
        riskCategory: {
          name: this.categoryName
        }
      });
      this.selectedRiskClass = '';
      this.selectedRiskTypeName = '';
      this.hasUpdatedMedicalCondition = true;
      this.warningUpdateMedicalConditionDialogDisplayed = false;
      this.$emit('changeRiskCategoryDone', this.name);
    }
  },
  mounted() {
    const $selector = $(`#${this.objId}`);
    if (
      typeof $selector.data('select2') === 'undefined' &&
      $selector.next().hasClass('select2-container')
    ) {
      $selector.select2('destroy');
    }

    $selector.select2({
      matcher: this.$app.selectCustom.matchCustom,
      placeholder: this.placeholder
    });

    const that = this;

    $selector.on('select2:select', function (e) {
      that.selectedRiskClass = e.params.data.text?.trim();
      that.selectedRiskTypeName = e.params.data.title;
      $(this).val('');

      if (that.selectedRiskClasses.length > 0 && !that.hasUpdatedMedicalCondition) {
        that.warningUpdateMedicalConditionDialogDisplayed = true;
        return;
      }
      that.updateRiskClass();
    });
  },
  updated() {
    const $selector = $(`#${this.objId}`);
    if (
      typeof $selector.data('select2') !== 'undefined' &&
      $selector.next().hasClass('select2-container')
    ) {
      $selector.next().remove();
    }
    $selector.select2({
      matcher: this.$app.selectCustom.matchCustom,
      placeholder: this.placeholder
    });
  },
  destroyed() {
    const $selector = $(`#${this.objId}`);
    $selector.select2('destroy');
  }
};
</script>
