import stbAPIGateway from '../../../../services/stbAPIGateway';

// eslint-disable-next-line import/prefer-default-export
export const patchClientGeneral = async ({ rootGetters, dispatch }, clientGeneral) => {
  const axios = rootGetters['app/stbAxios'];

  const stbApi = await stbAPIGateway(axios, process.env.API_URL);

  const response = await stbApi.patchClientGeneralBySid(clientGeneral.clientSid, clientGeneral);
  if (response.status === 'success') {
    await dispatch('fetchClientGeneral', clientGeneral.clientSid);
  }
  return response;
};
