import app from './app/store';
import common from './common/store';
import session from './session/store';
import authorisation from './authorisation/store';
import clients from './clients/store';
import risks from './risks/store';

const modules = {
  app,
  common,
  session,
  authorisation,
  clients,
  risks
};

export default modules;
