import { render, staticRenderFns } from "./ViewPolicy.vue?vue&type=template&id=1ed28bbd&scoped=true"
import script from "./ViewPolicy.vue?vue&type=script&lang=js"
export * from "./ViewPolicy.vue?vue&type=script&lang=js"
import style0 from "./ViewPolicy.vue?vue&type=style&index=0&id=1ed28bbd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ed28bbd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
installComponents(component, {VCard,VCardText,VCardTitle,VChip,VDivider,VFlex,VList,VProgressCircular})
