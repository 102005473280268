<template>
  <div class="timezone-selector">
    <v-autocomplete
      v-model="timezone"
      :items="timezones"
      label="Select Time Zone"
      item-text="text"
      item-value="value"
      :search-input.sync="searchTimezone"
      :cache-items="false"
      clearable
      :multiple="false"
      ref="selectTimezone"
      hide-details
      :hide-selected="hideSelected"
      :deletable-chips="false"
      solo
      required
      :error-messages="errorMessages"
      @input="onTimezoneChange"
      id="timezone-selector-time-zone"
    >
      <template slot="item" slot-scope="data">
        <span :id="`client-individual-modal--time-zones-item-${data.item.value}`">
          {{ data.item.text }}
        </span>
      </template>
    </v-autocomplete>
  </div>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';

export default {
  name: 'TimezoneSelector',

  props: {
    value: { type: String, required: false, default: '' },
    errorMessages: { type: Array, required: false },
    hideSelected: { type: Boolean, required: false, default: false }
  },
  watch: {
    value(val) {
      this.timezone = val;
    },
    searchTimezone(val) {
      let localVal = val;
      localVal = val ? val.toLowerCase() : '';
      this.timezones = this.allTimezones.filter((t) => t.text.toLowerCase().indexOf(localVal) > -1);
    }
  },
  data() {
    return {
      timezone: this.value,
      searchTimezone: null,
      timezones: [],
      allTimezones: []
    };
  },
  async mounted() {
    this.setTimezones();
  },
  methods: {
    setTimezones() {
      const timezoneNames = moment.tz.names();
      timezoneNames.forEach((name) => {
        const text = `${name.replace(/_/g, ' ')} (UTC${moment.tz(name).format('Z')})`;
        this.timezones.push({ value: name, text });
      });
      this.allTimezones = cloneDeep(this.timezones);
    },
    onTimezoneChange(newTimezone) {
      this.$emit('input', newTimezone);
    }
  }
};
</script>
