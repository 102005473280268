<template>
  <div class="complete-check-list">
    <v-btn
      :disabled="elementDisabled"
      @click.native="getData()"
      :loading="checklistModalDisplayed"
      depressed
      class="normal-btn mandatory-checklist-button"
      :id="completeChecklistBtnId"
      v-if="!checklistViewModeEnabled"
    >
      <v-icon
        v-if="!getChecklistFetchActionInProgress()"
        :class="
          checklistCompletionIndicatorEnabled ? 'complete-green' : 'complete-green incompleted'
        "
        :style="{
          color: checklistCompletionIndicatorEnabled ? '#28a745' : 'lightgray'
        }"
        >{{ checklistCommentIconDisplayed ? 'fa fa-comment' : 'fa fa-check' }}</v-icon
      >

      <v-icon v-if="getChecklistFetchActionInProgress()">fa fa-spinner fa-pulse</v-icon>
      Complete Checklist
    </v-btn>
    <cs-button
      :id="completeChecklistBtnId"
      v-if="checklistViewModeEnabled"
      label="View Checklist"
      :disabled="checklistModalDisplayed || checklistViewActionEnabled"
      @click.stop="getData()"
      :loading="checklistModalDisplayed"
    ></cs-button>
    <cs-form-dialog
      v-model="checklistModalDisplayed"
      id="dlg-complete-check-list"
      :bodyId="getBodyId"
      heading="Complete Checklist"
      :primaryAction="{
        label: 'Save',
        eventName: 'primary-click',
        disabled: checklistViewModeEnabled || archivedExcursionDetected
      }"
      @primary-click="save(true)"
      :secondaryAction1="{
        label: 'Close',
        eventName: 'secondary1-click'
      }"
      @secondary1-click="close"
    >
      <template v-slot:cs-form-dialog-content>
        <div class="checklist-content">
          <div v-if="!checklist || checklist.length <= 0" class="loading-div">
            <v-progress-circular
              :size="70"
              color="rgb(29, 175, 236)"
              class="loading-img"
              indeterminate
            ></v-progress-circular>
          </div>
          <v-list v-for="(section, index) in checklist" :key="index">
            <v-subheader v-if="checklistReadOnly"
              ><h1>{{ section.name }}</h1></v-subheader
            >
            <v-subheader v-if="!checklistReadOnly">
              <h1>{{ riskClassName ? riskClassName : categoryName }}</h1>
            </v-subheader>
            <v-list v-for="(item, index) in getItemsByTitle(section.checklist)" :key="index">
              <div v-if="'form-group' === item.contentType">
                <v-subheader class="checklist-item-subtitle">
                  <vue-markdown
                    :source="item.label"
                    :typographer="false"
                    :id="getChecklistTitleId(item)"
                  ></vue-markdown>
                </v-subheader>
                <div
                  v-for="(option, index) in item.children"
                  :key="index"
                  :id="getChecklistItemId(item)"
                >
                  <v-list-item>
                    <div v-if="'checkbox' === option.contentType" style="display: flex">
                      <v-list-item-action>
                        <v-checkbox
                          color="#c80063"
                          v-model="option.ticked"
                          :id="`complete-checklist--${String(item.label)
                            .replaceAll(' ', '-')
                            .replaceAll('#', '')}--checkbox--${index}`"
                          :disabled="!formControlEnabled"
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <vue-markdown :source="option.label" :typographer="false"></vue-markdown>
                      </v-list-item-content>
                    </div>
                    <div v-if="'form-group' === option.contentType" style="margin-left: -15px">
                      <v-subheader>
                        <vue-markdown :source="option.label" :typographer="false"></vue-markdown>
                      </v-subheader>
                      <div v-for="(childOption, index) in option.children" :key="index">
                        <v-list-item>
                          <v-list-item-action v-if="'checkbox' === childOption.contentType">
                            <v-checkbox
                              color="#c80063"
                              v-model="childOption.ticked"
                              :id="`complete-checklist--${String(item.label)
                                .replaceAll(' ', '-')
                                .replaceAll('#', '')}--${String(option.label)
                                .replaceAll(' ', '-')
                                .replaceAll('#', '')}--form-group-content-type-checkbox--${index}`"
                              :disabled="!formControlEnabled"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <vue-markdown
                              :source="childOption.label"
                              :typographer="false"
                            ></vue-markdown>
                          </v-list-item-content>
                        </v-list-item>
                        <checklist-item-comment
                          :checklistItem="childOption"
                          :commentBtnId="`complete-checklist--${String(item.label)
                            .replaceAll(' ', '-')
                            .replaceAll('#', '')}--${String(option.label)
                            .replaceAll(' ', '-')
                            .replaceAll('#', '')}--form-group-comment--${index}`"
                        >
                        </checklist-item-comment>
                      </div>
                    </div>
                  </v-list-item>
                  <checklist-item-comment
                    v-if="'checkbox' === option.contentType"
                    :checklistItem="option"
                    :commentBtnId="`complete-checklist--${String(
                      riskClassName ? riskClassName : categoryName
                    ).replaceAll(' ', '-')}--${String(item.label)
                      .replaceAll(' ', '-')
                      .replaceAll('#', '')}--content-type-comment--${index}`"
                  >
                  </checklist-item-comment>
                </div>
              </div>
              <div v-else>
                <div v-if="item.children != undefined">
                  <v-list-item-content v-for="(checklistItem, index) in item.children" :key="index">
                    <div v-if="'form-group' === checklistItem.contentType">
                      <v-subheader class="checklist-item-subtitle">
                        <vue-markdown
                          :source="checklistItem.label"
                          :typographer="false"
                          :id="getChecklistTitleId(checklistItem)"
                        >
                        </vue-markdown>
                      </v-subheader>
                      <div
                        v-for="(option, index) in checklistItem.children"
                        :key="index"
                        :id="getChecklistItemId(checklistItem)"
                      >
                        <v-list-item>
                          <v-list-item-action v-if="'checkbox' === option.contentType">
                            <v-checkbox
                              color="#c80063"
                              v-model="option.ticked"
                              :id="`complete-checklist--${String(checklistItem.label)
                                .replaceAll(' ', '-')
                                .replaceAll('#', '')}--form-group-checkbox--${index}`"
                              :disabled="!formControlEnabled"
                            ></v-checkbox>
                          </v-list-item-action>
                          <v-list-item-content>
                            <vue-markdown
                              :source="option.label"
                              :typographer="false"
                            ></vue-markdown>
                          </v-list-item-content>
                        </v-list-item>
                        <checklist-item-comment
                          :checklistItem="option"
                          :commentBtnId="`complete-checklist--${String(checklistItem.label)
                            .replaceAll(' ', '-')
                            .replaceAll('#', '')}--form-group-checkbox-comment--${index}`"
                        >
                        </checklist-item-comment>
                      </div>
                    </div>
                  </v-list-item-content>
                </div>
                <div v-else>
                  <v-subheader>
                    <vue-markdown :source="item.label" :typographer="false"></vue-markdown>
                  </v-subheader>
                  <checklist-item-comment
                    :checklistItem="item"
                    :commentBtnId="`complete-checklist--${String(item.label)
                      .replaceAll(' ', '-')
                      .replaceAll('#', '')}--${String(
                      riskClassName ? riskClassName : categoryName
                    ).replaceAll(' ', '-')}--comment--${index}`"
                  >
                  </checklist-item-comment>
                </div>
              </div>
            </v-list>
          </v-list>
        </div>
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
import * as types from '@/store/mutationTypes';
import riskCategoryLabel from '../lib/const/riskCategoryLabel';
import excursionStatus from '../lib/const/excursionStatus';

export default {
  props: [
    'categoryName',
    'riskTypeName',
    'riskClassName',
    'riskName',
    'elementDisabled',
    'associatedChecklistCompleted',
    'checklistViewModeEnabled',
    'formControlEnabled',
    'checklistReadOnly',
    'checklistViewActionEnabled',
    'checklistFetchActionCategoryName',
    'checklistFetchActionRiskTypeName',
    'checklistFetchActionRiskClassName',
    'checklistFetchActionInProgress',
    'saveDraft'
  ],
  data: () => ({
    checklistModalDisplayed: false,
    checklist: [],
    activityCheckList: {},
    checklistCompletionIndicatorEnabled: false,
    originalChecklist: [],
    checklistCommentIconDisplayed: false
  }),
  computed: {
    allSelectedActivityRisk() {
      return this.$store.getters['common/getSelectedActivityClasses'][0];
    },
    completeChecklistBtnId() {
      let typeName = '';
      let className = '';

      if (this.riskTypeName) {
        typeName = this.riskTypeName;
      } else if (this.categoryName) {
        typeName = this.categoryName;
      }
      typeName = typeName.replace(/\s/g, '');

      if (this.riskClassName) {
        className = this.riskClassName;
      } else if (this.categoryName) {
        className = this.categoryName;
      }
      className = className.replace(/\s/g, '');

      return `btnChecklist${typeName}${className}`;
    },

    archivedExcursionDetected() {
      return excursionStatus.ARCHIVED === this.$store.state.common.excursionDc.status;
    },
    getBodyId() {
      let className = '';

      if (this.riskClassName) {
        className = this.riskClassName;
      } else if (this.categoryName) {
        className = this.categoryName;
      }
      className = className.replace(/\s*/g, '');

      return `dlg-complete-checklist-${className}`;
    }
  },
  methods: {
    async getData() {
      let result = [];
      this.checklist = [];
      this.checklistModalDisplayed = true;

      if (this.checklistReadOnly) {
        this.getCategoryData();
      } else {
        const excursionCommonAdapter = new this.$app.excursionCommon.Adapter({
          store: this.$store,
          app: this.$app
        });

        if (
          typeof this.categoryName !== 'undefined' &&
          typeof this.riskTypeName === 'undefined' &&
          typeof this.riskClassName === 'undefined'
        ) {
          result = this.getRiskClassCheckList();

          if (result.length === 0) {
            const { categories } = this.$store.state.common.excursionDc;

            if (categories && categories.length > 0) {
              // frc stands for: factor risk category
              const frcs = excursionCommonAdapter.getRiskCategoryParams();
              const argHash = {
                store: this.$store,
                queryParam: this.$route.query,
                frcs
              };
              // check status
              const response = await this.$app.stbApiAdapter.getCompleteChecklist(argHash);
              result.push({
                checklist: response.optional.find((o) => o.title === this.categoryName)
              });
              this.checklist = result;
              this.$store.commit(types.COMMON_SET_CE_COMMIT_OID, response.ceCommitOid);
              this.save();
            }
          } else {
            this.checklist = result;
          }
        } else {
          result = this.getRiskClassCheckList();
          if (result.length === 0) {
            const allParams = excursionCommonAdapter.getActivityParams();
            // frc stands for: factor risk category
            const frcs = allParams.filter((p) => p.indexOf(this.riskClassName) >= 0);
            const argHash = {
              store: this.$store,
              queryParam: this.$route.query,
              frcs
            };

            const response = await this.$app.stbApiAdapter.getCompleteChecklist(argHash);
            result.push({
              checklist: response.optional.find((o) => o.title === this.categoryName)
            });
            this.$store.commit(types.COMMON_SET_CE_COMMIT_OID, response.ceCommitOid);
            this.checklist = result;
            this.save();
          } else {
            this.checklist = result;
          }
        }
        this.originalChecklist = this.$app.lodash.cloneDeep(this.checklist);
      }
    },
    save(modalClosed) {
      console.log('save called');
      const { excursionDc } = this.$store.state.common;
      let checklistModified = false;

      if (typeof this.riskTypeName === 'undefined' && typeof this.riskClassName === 'undefined') {
        const category = this.$app.enumerable
          .from(excursionDc.categories)
          .firstOrDefault((x) => `${x.name}` === `${this.categoryName}`);

        if (category) {
          checklistModified = !this.$app.lodash.isEqual(category.CompleteChecklist, this.checklist);
          this.$store.commit(types.COMMON_SET_EXCURSION_DC_CATEGORY_COMPLETE_CHECKLIST, {
            categoryIndex: this.$app.enumerable.from(excursionDc.categories).indexOf(category),
            completeChecklist: this.checklist
          });
        }
      } else {
        const category = this.$app.enumerable
          .from(excursionDc.categories)
          .firstOrDefault((x) => x.name === this.categoryName);

        const type = this.$app.enumerable
          .from(category.types)
          .firstOrDefault((x) => x.name === this.riskTypeName);

        const riskClass = this.$app.enumerable
          .from(type.classes)
          .firstOrDefault((x) => x.name === this.riskClassName);

        if (riskClass) {
          checklistModified = !this.$app.lodash.isEqual(category.CompleteChecklist, this.checklist);
          this.$store.commit(types.COMMON_SET_EXCURSION_DC_CATEGORY_TYPE_CLASS_COMPLETE_CHECKLIST, {
            categoryIndex: this.$app.enumerable.from(excursionDc.categories).indexOf(category),
            typeIndex: this.$app.enumerable.from(category.types).indexOf(type),
            riskClassIndex: this.$app.enumerable.from(type.classes).indexOf(riskClass),
            completeChecklist: this.checklist
          });
        }
      }

      this.getNewTicked();

      this.checklistCommentIconDisplayed = this.getChecklistCommentIconDisplayed();

      if (modalClosed) {
        this.checklistModalDisplayed = false;
        this.$app.eventBus.$emit('saveCompleteChecklist');
        if (this.saveDraft && typeof this.saveDraft === 'function' && checklistModified) {
          this.$app.eventBus.$emit('setExcursionValuesChanged', true);
          this.saveDraft();
        }
      }
    },
    getItemsByTitle(object) {
      if (object && object.Strategy) {
        const keys = Object.keys(object.Strategy.data);
        if (keys && keys.length > 0) {
          const data = object.Strategy.data[keys[0]];
          if (data && data.children) {
            return data.children;
          }
        }
      }

      return [];
    },
    getRiskClassCheckList() {
      const clonedExcursionDc = this.$app.lodash.cloneDeep(this.$store.state.common.excursionDc);

      if (
        typeof this.categoryName !== 'undefined' &&
        this.riskTypeName === undefined &&
        this.riskClassName === undefined
      ) {
        const category = this.$app.enumerable
          .from(clonedExcursionDc.categories)
          .firstOrDefault((x) => x.name === this.categoryName);

        if (category && category.CompleteChecklist) {
          return category.CompleteChecklist;
        }
      } else {
        const category = this.$app.enumerable
          .from(clonedExcursionDc.categories)
          .firstOrDefault((x) => x.name === this.categoryName);

        if (category && category.types.length > 0) {
          const type = this.$app.enumerable
            .from(category.types)
            .firstOrDefault((x) => x.name === this.riskTypeName);

          const riskClass = this.$app.enumerable
            .from(type.classes)
            .firstOrDefault((x) => x.name === this.riskClassName);

          if (riskClass.CompleteChecklist) {
            return riskClass.CompleteChecklist;
          }
        }
      }

      return [];
    },
    getTicked(item) {
      let allBox = 0;
      let allChecked = 0;

      if (
        item &&
        item.CompleteChecklist &&
        item.CompleteChecklist.length > 0 &&
        item.CompleteChecklist[0].checklist &&
        item.CompleteChecklist[0].checklist.Strategy &&
        item.CompleteChecklist[0].checklist.Strategy.data
      ) {
        const { data } = item.CompleteChecklist[0].checklist.Strategy;
        const dataKey = Object.keys(data);

        if (data[dataKey] && data[dataKey].children && data[dataKey].children.length > 0) {
          data[dataKey].children.forEach((checkData) => {
            const checklist = checkData.children;

            if (checklist && checklist.length > 0) {
              checklist.forEach((box) => {
                if (box.children && box.children.length > 0) {
                  const boxes = box.children.filter((ck) => {
                    return ck.contentType === 'checkbox';
                  });
                  if (boxes && boxes.length > 0) {
                    allBox += boxes.length;
                  }

                  const checked = box.children.filter((ck) => {
                    return ck.ticked && ck.contentType === 'checkbox';
                  });

                  if (checked && checked.length > 0) {
                    allChecked += checked.length;
                  }
                } else if (box.contentType === 'checkbox') {
                  allBox += 1;
                  if (box.ticked) {
                    allChecked += 1;
                  }
                }
              });
            }
          });
        }
      } else {
        allChecked = -1;
      }
      if (item && item.CompleteChecklist) {
        const { title } = item.CompleteChecklist[0].checklist;
        this.updateTickedRiskCategoryChecklists(title, allBox === allChecked);
      }
      return allBox === allChecked;
    },
    getTickItem() {
      const { excursionDc } = this.$store.state.common;
      let tickItem;

      if (
        typeof this.categoryName !== 'undefined' &&
        typeof this.riskTypeName === 'undefined' &&
        typeof this.riskClassName === 'undefined'
      ) {
        tickItem = this.$app.enumerable
          .from(excursionDc.categories)
          .firstOrDefault((x) => x.name === this.categoryName);
      } else {
        const category = this.$app.enumerable
          .from(excursionDc.categories)
          .firstOrDefault((x) => x.name === this.categoryName);

        if (category && category.types.length > 0) {
          const type = this.$app.enumerable
            .from(category.types)
            .firstOrDefault((x) => x.name === this.riskTypeName);

          tickItem = this.$app.enumerable
            .from(type.classes)
            .firstOrDefault((x) => x.name === this.riskClassName);
        }
      }

      return tickItem;
    },
    getNewTicked() {
      const tickItem = this.getTickItem();
      this.checklistCompletionIndicatorEnabled = this.getTicked(tickItem);
    },
    close() {
      this.checklist = [];
      if (!this.checklistReadOnly) {
        const { excursionDc } = this.$store.state.common;

        if (
          typeof this.categoryName !== 'undefined' &&
          this.riskTypeName === undefined &&
          this.riskClassName === undefined
        ) {
          const category = this.$app.enumerable
            .from(excursionDc.categories)
            .firstOrDefault((x) => `${x.name}` === `${this.categoryName}`);

          if (category && category.CompleteChecklist) {
            this.$store.commit(types.COMMON_SET_EXCURSION_DC_CATEGORY_COMPLETE_CHECKLIST, {
              categoryIndex: this.$app.enumerable.from(excursionDc.categories).indexOf(category),
              completeChecklist: this.originalChecklist
            });
          }
        } else {
          const category = this.$app.enumerable
            .from(excursionDc.categories)
            .firstOrDefault((x) => x.name === this.categoryName);

          if (category && category.types.length > 0) {
            const type = this.$app.enumerable
              .from(category.types)
              .firstOrDefault((x) => x.name === this.riskTypeName);

            const riskClass = this.$app.enumerable
              .from(type.classes)
              .firstOrDefault((x) => x.name === this.riskClassName);

            if (riskClass) {
              this.$store.commit(
                types.COMMON_SET_EXCURSION_DC_CATEGORY_TYPE_CLASS_COMPLETE_CHECKLIST,
                {
                  categoryIndex: this.$app.enumerable
                    .from(excursionDc.categories)
                    .indexOf(category),
                  typeIndex: this.$app.enumerable.from(category.types).indexOf(type),
                  riskClassIndex: this.$app.enumerable.from(type.classes).indexOf(riskClass),
                  completeChecklist: this.originalChecklist
                }
              );
            }
          }
        }
      }

      this.checklistModalDisplayed = false;
    },
    getCategoryData() {
      this.checklist = [];
      const selectedExcursion = this.$store.state.common.excursionDc;

      const { categories } = selectedExcursion;
      if (categories && categories.length > 0) {
        const categoryComplete = selectedExcursion.categories.filter(
          (c) =>
            c.name.toLowerCase() !== 'activities' &&
            c.CompleteChecklist &&
            c.CompleteChecklist.length > 0
        );
        if (categoryComplete && categoryComplete.length > 0) {
          categoryComplete.forEach((category) => {
            if (category.CompleteChecklist && category.CompleteChecklist.length > 0) {
              this.checklist.push({
                name: category.name,
                checklist: category.CompleteChecklist[0].checklist
              });
            }
          });
        }
      }
    },
    updateTickedRiskCategoryChecklists(checklistTitle, ticked) {
      let title = checklistTitle;
      if (title === riskCategoryLabel.ACTIVITIES) {
        title = `${this.categoryName}-${this.riskTypeName}-${this.riskClassName}`;
      }
      this.$store.commit(types.COMMON_SET_RISK_CATEGORY_CHECKLISTS_TICKED, {
        title,
        ticked
      });
    },
    deleteTickedRiskCategoryChecklists(checklistTitle) {
      let title = checklistTitle;
      if (title === riskCategoryLabel.ACTIVITIES) {
        title = `${this.categoryName}-${this.riskTypeName}-${this.riskClassName}`;
      }
      this.$store.commit(types.COMMON_DELETE_FROM_TICKED_RISK_CATEGORY_CHECKLISTS, { title });
    },
    resetCompleteButton() {
      this.checklistCompletionIndicatorEnabled = false;
      this.checklistCommentIconDisplayed = false;
    },
    getChecklistCommentIconDisplayed() {
      const tickItem = this.getTickItem();

      let filledCommentCount = 0;
      if (tickItem) {
        const checklist = tickItem.CompleteChecklist;
        const checklistString = JSON.stringify(checklist);
        if (checklist) {
          const emptyCommentCount = (checklistString.match(/"comment":""/g) || []).length;
          const commentCount = (checklistString.match(/"comment":/g) || []).length;
          filledCommentCount = commentCount - emptyCommentCount;
        }
      }
      return filledCommentCount > 0;
    },
    getChecklistFetchActionInProgress() {
      let actionInProgress = false;
      if (!this.riskTypeName && !this.riskClassName) {
        if (
          this.categoryName === this.checklistFetchActionCategoryName &&
          this.checklistFetchActionInProgress
        ) {
          actionInProgress = true;
        }
      }

      if (this.riskTypeName && this.riskClassName) {
        if (
          this.categoryName === this.checklistFetchActionCategoryName &&
          this.riskTypeName === this.checklistFetchActionRiskTypeName &&
          this.riskClassName === this.checklistFetchActionRiskClassName &&
          this.checklistFetchActionInProgress
        ) {
          actionInProgress = true;
        }
      }

      this.checklistCommentIconDisplayed = this.getChecklistCommentIconDisplayed();

      return actionInProgress;
    },
    getChecklistTitleId(item) {
      return `checklist-title--${item.id}`;
    },
    getChecklistItemId(item) {
      return `checklist-item--${item.id}`;
    }
  },
  mounted() {
    this.checklistCompletionIndicatorEnabled = this.associatedChecklistCompleted;
    this.checklistCommentIconDisplayed = this.getChecklistCommentIconDisplayed();

    // Set the already completed checklists to prevent incomplete checklist warning
    const tickItem = this.getTickItem();
    const ticked = this.getTicked(tickItem);

    if (ticked) {
      this.updateTickedRiskCategoryChecklists(this.categoryName, ticked);
    }
  },
  watch: {
    elementDisabled() {
      if (this.categoryName === this.checklistFetchActionCategoryName) {
        if (this.elementDisabled) {
          this.checklistCompletionIndicatorEnabled = false;
          this.deleteTickedRiskCategoryChecklists(this.categoryName);
        } else {
          let ticked = false;

          if (riskCategoryLabel.ACTIVITIES === this.categoryName) {
            const tickItem = this.getTickItem();
            ticked = this.getTicked(tickItem);
          }

          this.updateTickedRiskCategoryChecklists(this.categoryName, ticked);
          this.checklistCompletionIndicatorEnabled = this.associatedChecklistCompleted;
        }
      }
    }
  },
  beforeDestroy() {
    this.$app.eventBus.$off('saveCompleteChecklist');
  }
};
</script>
<style scoped>
.checklist-content .header {
  color: #c80063;
}

.checklist-content .v-list-item {
  height: auto;
  align-items: flex-start;
  padding-bottom: 12px;
}

.checklist-content .content {
  white-space: normal;
  height: auto;
}

.checklist-content .v-list-item__action {
  min-width: 30px;
  margin: 0 0 0 0 !important;
  padding: 0 !important;
}

.checklist-content .v-list-item__content {
  margin: 0 !important;
  padding: 0 !important;
}

.checklist-content .v-input__slot {
  min-height: auto;
  height: auto;
}

.checklist-content .v-subheader {
  font-size: 16px;
}

.checklist-content .v-input--selection-controls__ripple:before {
  left: unset;
  right: unset;
  top: unset;
}

.loading-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.checklist-content h1 {
  color: gray;
  border-bottom: 3px solid #ff7f32;
  width: 100%;
}

.checklist-item-subtitle {
  color: #ff7f32 !important;
}

.checklist-content .content {
  max-width: 98%;
}

.checklist-content .v-list-item__content label {
  margin-bottom: 5px;
}
</style>
