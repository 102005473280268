const getters = {
  clientGeneralView(state) {
    return state.client.general;
  },

  clientContentEngineView(state) {
    return state.client.contentEngine;
  }
};

export default getters;
