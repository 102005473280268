<template>
  <div>
    <div class="excursion-history" v-if="historyDisplayed">
      <hr class="mt-4 hr" />
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header id="excursion-history--expansion--header">
            Revision Points
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <template>
              <v-list-item v-for="(item, index) in historyItems" :key="index">
                <v-list-item-content>
                  <div class="revision-btn-container">
                    <cs-button
                      primary
                      class="revision-btn"
                      @click="openRevisionDialog(item.computed.reversionIndex)"
                      label="Select Revision"
                    ></cs-button>
                  </div>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>{{
                    item['audit-metadata']['userDisplayName']
                  }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>{{ item.timestamp }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <cs-form-dialog
      v-model="groupRevertGenericDialogDisplayed"
      id="dlg-student-management-revert"
      heading="Student Management - Select Revision"
      bodyId="group-import-modal--generic-dialog"
      :primaryAction="{
        label: 'Revert',
        eventName: 'primary-click',
        disabled: revertInProgress
      }"
      :secondaryAction1="{ label: 'Cancel', eventName: 'secondary1-click' }"
      @primary-click="studentMangementRevert"
      @secondary1-click="cancelRevisionBtnClick"
    >
      <template v-slot:cs-form-dialog-content>
        <v-spacer></v-spacer>
        <v-card-text>{{ groupRevertGenericDialogMessage }}</v-card-text>
      </template>
    </cs-form-dialog>
    <cs-form-dialog
      v-model="genericDialogDisplayed"
      id="dlg-student-management-generic"
      heading="Student Management - Select Revision Status"
      bodyId="group-import-modal--generic-dialog"
      :primaryAction="{ label: 'OK', eventName: 'primary-click' }"
      @primary-click="genericDialogOkBtnClick"
    >
      <template v-slot:cs-form-dialog-content>
        <v-spacer></v-spacer>
        <v-card-text>
          <div v-safe-html="genericDialogMessage"></div>
        </v-card-text>
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
import * as types from '@/store/mutationTypes';
import { populateErrorMessage } from '../lib/errorMessages';

export default {
  data() {
    return {
      groupRevertGenericDialogMessage: 'Are you sure you want to revert the group mapping?',
      groupRevertGenericDialogDisplayed: false,
      reversionIndex: '',
      genericDialogMessage: '',
      successRevisionMessage: 'Revert student management successfully.',
      revisionErrorMessage: 'Revision failed, please try again.',
      genericDialogDisplayed: false,
      revertInProgress: false
    };
  },
  computed: {
    historyDisplayed() {
      return this.$store.state.common.studentManagementAuditList.length > 0;
    },
    historyItems() {
      return this.$store.state.common.studentManagementAuditList;
    }
  },
  methods: {
    async studentMangementRevert() {
      this.groupRevertGenericDialogDisplayed = false;
      this.revertInProgress = true;
      const argHash = {
        index: this.reversionIndex,
        store: this.$store
      };
      const cbResponse = await this.$app.stbApiAdapter.putStudentGroupManagementReversion(argHash);
      this.revertInProgress = false;
      if (!cbResponse.error) {
        this.genericDialogMessage = this.successRevisionMessage;
      } else {
        this.genericDialogMessage = `${this.revisionErrorMessage}: ${cbResponse.message}.`;

        this.genericDialogMessage = populateErrorMessage({
          message: this.genericDialogMessage,
          store: this.$store
        });
      }
      setTimeout(() => {
        this.genericDialogDisplayed = true;
      }, 200);
    },
    openRevisionDialog(index) {
      this.groupRevertGenericDialogDisplayed = true;
      this.reversionIndex = index;
    },
    cancelRevisionBtnClick() {
      this.groupRevertGenericDialogDisplayed = false;
      this.reversionIndex = '';
    },
    genericDialogOkBtnClick() {
      this.genericDialogDisplayed = false;
      this.$app.window.location.reload();
    }
  },
  async mounted() {
    const cbResponse = await this.$app.stbApiAdapter.getAuditStudentMangement({
      store: this.$store
    });
    if (!cbResponse.error) {
      this.$store.commit(types.COMMON_SET_STUDENT_MANAGEMENT_AUDIT_LIST, cbResponse);
    }
  }
};
</script>
<style scoped>
.revision-btn-container {
  display: flex;
}
.revision-btn {
  width: 150px;
}
</style>
