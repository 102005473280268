import stbAPIGateway from '../../../../services/stbAPIGateway';

// eslint-disable-next-line import/prefer-default-export
export const fetchClientContentEngine = async ({ rootGetters, commit }, clientSid) => {
  const axios = rootGetters['app/stbAxios'];

  const stbApi = await stbAPIGateway(axios, process.env.API_URL);
  const response = await stbApi.getClientContentEngine(clientSid);
  if (response.data) {
    commit('setClientContentEngine', response.data);
  }
  return response;
};
