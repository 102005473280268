<template>
  <div class="client-row">
    <v-card>
      <v-card-text class="clientEditForm client-edit-form">
        <v-container grid-list-md>
          <v-tabs
            color="#fff"
            slider-color="#c80063"
            class="client-row"
            v-model="activeSectionIndex"
          >
            <v-tab :key="'General'" ripple id="tabGeneral"> General </v-tab>
            <v-tab :key="'Group'" ripple id="tabGroup"> Group </v-tab>
            <v-tab :key="'Vendors'" ripple id="tabVendors"> Excursion Management </v-tab>
            <v-tab :key="'Providers'" ripple id="tabPoviders"> Providers </v-tab>
            <v-tab :key="'ContentEngine'" ripple id="tabContentEngine"> Content Engine </v-tab>
            <v-tab :key="'studentManagement'" ripple id="tabstudentManagement">
              Student Management
            </v-tab>
            <v-tab :key="'Templates'" ripple id="tabTemplates"> Templates </v-tab>
            <v-tab-item>
              <div class="client-row client-modal-text">
                <client-general
                  ref="refClientGeneral"
                  :clientSid="clientSid"
                  :showDomainConflict="subdomainConflicted"
                  @clientGeneralChanged="onClientGeneralChanged"
                  @clientLogoChanged="onClientLogoChanged"
                  @clientOptionsChanged="onClientOptionsChanged"
                  @clientGeneralSaved="onClientGeneralSaved"
                  @subdomainChanged="onSubdomainChanged"
                />
              </div>
            </v-tab-item>
            <v-tab-item>
              <v-flex> Parent Client SID </v-flex>
              <v-flex xs12>
                <v-autocomplete
                  v-model="parentClientSid"
                  :items="parentClientItems"
                  :loading="parentClientLoadActionInProgress"
                  :search-input.sync="searchParentClients"
                  id="parentClientSid"
                  item-value="sid"
                  item-text="name"
                  :cache-items="true"
                  clearable
                  hide-details
                  :hide-selected="hideSelected"
                  label="Parent Client SID"
                  solo
                  class="excursion-selection parent-client-sid"
                >
                  <template slot="item" slot-scope="data">
                    <span
                      :id="`client-individual-modal--parent-client-sid--option--${String(
                        data.item.name
                      ).replaceAll(' ', '')}`"
                    >
                      {{ data.item.name }}
                    </span>
                  </template>
                </v-autocomplete>
              </v-flex>
            </v-tab-item>
            <v-tab-item>
              <v-flex xs12>
                Vendor Name
                <span class="red--text">*</span>
              </v-flex>
              <v-flex xs12>
                <v-select
                  class="type-dropdown excursion-selection"
                  item-text="name"
                  item-value="sid"
                  :items="excursionManagementVendors"
                  solo
                  v-model="vendorSid"
                  placeholder="Select Vendor Name"
                  required
                  :error-messages="vendorSidErrors"
                  @input="$v.vendorSid.$touch()"
                  @blur="$v.vendorSid.$touch()"
                  id="txtVendorId"
                >
                  <template slot="item" slot-scope="data">
                    <div
                      :id="`client-individual-modal--excursion-management-vendor--vendor-name--option-${String(
                        data.item.name
                      ).replaceAll(' ', '')}`"
                    >
                      {{ data.item.name }}
                    </div>
                  </template>
                </v-select>
              </v-flex>
              <v-flex>
                Vendor Client SID
                <span class="red--text">*</span>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  solo
                  v-model="vendorClientSid"
                  placeholder="Vendor Client SID"
                  class="name-textbox excursion-input"
                  required
                  :error-messages="vendorClientSidErrors"
                  @input="$v.vendorClientSid.$touch()"
                  @blur="$v.vendorClientSid.$touch()"
                  id="txtVendorClientSid"
                  @keyup="autoPopulateVenderClientSid()"
                />
              </v-flex>
              <v-flex>
                Vendor Client Name
                <span class="red--text">*</span>
              </v-flex>
              <v-flex>
                <v-text-field
                  solo
                  v-model="vendorClientName"
                  placeholder="Vendor Client Name"
                  class="name-textbox excursion-input"
                  required
                  :error-messages="vendorClientNameErrors"
                  @input="$v.vendorClientName.$touch()"
                  @blur="$v.vendorClientName.$touch()"
                  id="txtVendorClientName"
                />
              </v-flex>
              <v-flex> Excursion Management Vendor Webhook URL </v-flex>
              <v-flex>
                <v-text-field
                  solo
                  v-model="excursionManagementVendorWebhookUrl"
                  placeholder="Excursion Management Vendor Webhook URL"
                  class="name-textbox excursion-input"
                  required
                  :error-messages="excursionManagementVendorWebhookUrlErrors"
                  @input="$v.excursionManagementVendorWebhookUrl.$touch()"
                  @blur="$v.excursionManagementVendorWebhookUrl.$touch()"
                  id="client-individual-modal--excursion-management-vendor-webhook-url--input"
                />
              </v-flex>
              <v-flex> Excursion Management Vendor Shared Secret Key </v-flex>
              <v-flex>
                <v-text-field
                  solo
                  v-model="excursionManagementVendorSharedSecretKey"
                  placeholder="Excursion Management Vendor Shared Secret Key"
                  class="name-textbox excursion-input"
                  id="client-individual-modal--excursion-management-vendor-shared-secret-key--input"
                />
              </v-flex>
              <v-layout class="client-individual-checkbox-layout">
                <v-flex xs1>
                  <v-checkbox
                    v-model="vendorExcursionSidInputEnabled"
                    class="client-individual-checkbox"
                    id="cbxEnableVendorExcursionInput"
                  ></v-checkbox>
                </v-flex>
                <v-flex
                  xs11
                  id="client-individual-modal--enable-vendor-excursion-sid-input--label"
                  class="client-individual-checkbox-label"
                  @click="vendorExcursionSidInputEnabled = !vendorExcursionSidInputEnabled"
                  >Enable Vendor Excursion SID Input</v-flex
                >
              </v-layout>
              <v-layout class="client-individual-checkbox-layout">
                <v-flex xs1>
                  <v-checkbox
                    v-model="boardingSupportEnabled"
                    class="client-individual-checkbox"
                    id="boardingSupportEnabledCheckbox"
                  ></v-checkbox>
                </v-flex>
                <div
                  id="boardingSupportEnabledCheckboxLabel"
                  class="client-individual-checkbox-label"
                  @click="boardingSupportEnabled = !boardingSupportEnabled"
                >
                  Enable Boarding Support
                </div>
              </v-layout>
              <v-flex> School Types </v-flex>
              <v-flex xs12 class="client-individual-client-options">
                <v-card class="mx-auto client-individual-p-10" outlined>
                  <v-layout>
                    <v-flex xs11>
                      <v-text-field
                        solo
                        v-model="newSchoolType.name"
                        class="name-textbox excursion-input client-individual-p-r-10"
                        required
                        placeholder="Eg. Junior School or Primary"
                        :error-messages="newSchoolType.errorMessage"
                        id="newSchoolType"
                      />
                    </v-flex>
                    <v-flex>
                      <cs-button
                        small
                        class="client-individual-line-btn"
                        @click="addSchoolType()"
                        id="btnAddSchoolType"
                        label="Add"
                      ></cs-button>
                    </v-flex>
                  </v-layout>
                  <v-layout v-for="(school, index) in schoolTypes" :key="index">
                    <v-flex xs11>
                      <v-text-field
                        solo
                        v-model="school.name"
                        :id="getSchoolTypeTxtFieldId(index)"
                        class="name-textbox excursion-input client-individual-p-r-10"
                        placeholder="Eg. Junior School or Primary"
                        :error-messages="school.errorMessage"
                        @change.native="changeSchool(school, index)"
                      />
                    </v-flex>
                    <v-flex>
                      <cs-button
                        small
                        delete
                        class="client-individual-line-btn"
                        @click="deleteSchoolType(index)"
                        :id="getDeleteSchoolButtunId(index)"
                        label="Delete"
                      ></cs-button>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-tab-item>
            <v-tab-item>
              <v-flex> Provider Source Type </v-flex>
              <v-flex>
                <v-select
                  class="type-dropdown excursion-selection"
                  item-text="text"
                  item-value="value"
                  :items="providerSourceTypes"
                  solo
                  v-model="provider.sourceType"
                  placeholder="Select Provider Source Type"
                  clearable
                  id="ddlProviderSourceType"
                  @change="changeProviderSourceType()"
                >
                  <template slot="item" slot-scope="data">
                    <div
                      :id="`client-individual-modal--provider-source-type--option-${String(
                        data.item.text
                      ).replaceAll(' ', '')}`"
                    >
                      {{ data.item.text }}
                    </div>
                  </template>
                </v-select>
              </v-flex>
              <div v-if="displaySftp()">
                <v-flex> SFTP Path </v-flex>
                <v-flex>
                  <v-text-field
                    solo
                    v-model="provider.sftpPath"
                    placeholder="SFTP Path"
                    class="name-textbox excursion-input"
                    id="txtSftpPath"
                    :disabled="!clientProviderSftpPathUpdateActionEnabled"
                  />
                </v-flex>
              </div>
              <div v-if="displayApi()">
                <v-flex>
                  API Base URL
                  <span class="red--text">*</span>
                </v-flex>
                <v-flex>
                  <v-text-field
                    solo
                    v-model="provider.apiBaseUrl"
                    placeholder="API Base URL"
                    class="name-textbox excursion-input"
                    id="txtProviderApiBaseUrl"
                    required
                    :error-messages="providerApiBaseUrlErrors"
                    @blur="$v.provider.$touch()"
                  />
                </v-flex>
                <v-flex>
                  API Key
                  <span class="red--text">*</span>
                </v-flex>
                <v-flex>
                  <v-text-field
                    solo
                    v-model="provider.apiKey"
                    placeholder="API Key"
                    class="name-textbox excursion-input"
                    id="txtProviderApiKey"
                    required
                    :error-messages="providerApiKeyErrors"
                    @blur="$v.provider.$touch()"
                  />
                </v-flex>
                <v-flex>
                  API Tenant SID
                  <span class="red--text">*</span>
                </v-flex>
                <v-flex>
                  <v-text-field
                    solo
                    v-model="provider.apiTenantSid"
                    placeholder="API Tenant SID"
                    class="name-textbox excursion-input"
                    id="txtProviderApiTenantSid"
                    required
                    :error-messages="providerApiTenantSidErrors"
                    @blur="$v.provider.$touch()"
                  />
                </v-flex>
                <v-flex>
                  API Report SID
                  <span class="red--text">*</span>
                </v-flex>
                <v-flex>
                  <v-text-field
                    solo
                    v-model="provider.apiReportSid"
                    placeholder="API Report SID "
                    class="name-textbox excursion-input"
                    id="txtProviderApiReportSid"
                    required
                    :error-messages="providerApiReportSidErrors"
                    @blur="$v.provider.$touch()"
                  />
                </v-flex>
              </div>
            </v-tab-item>
            <v-tab-item>
              <client-content-engine
                ref="refClientContentEngine"
                :clientSid="clientSid"
                :subdomain="subdomain"
                :clientSaveActionInProgress="clientSaveActionInProgress"
                @contentEngineChanged="onClientContentEngineChanged"
              >
              </client-content-engine>
            </v-tab-item>
            <v-tab-item>
              <div>
                <v-flex> Vendor Name </v-flex>
                <v-flex>
                  <v-select
                    class="type-dropdown excursion-selection"
                    item-text="name"
                    item-value="sid"
                    :items="studentManagementVendors"
                    solo
                    v-model="activeStudentManagementVendorSid"
                    placeholder="Select Vendor Name"
                    @change="changeActiveStudentManagementVendorSid()"
                    required
                    clearable
                    id="ddlVendorId"
                  >
                    <template slot="item" slot-scope="data">
                      <div
                        :id="`client-individual-modal--student-management-vendor--vendor-name--option-${String(
                          data.item.name
                        ).replaceAll(' ', '')}`"
                      >
                        {{ data.item.name }}
                      </div>
                    </template>
                  </v-select>
                </v-flex>
              </div>
              <div v-for="(field, index) in studentManagementClientApiFields" :key="index">
                <div v-if="'text' === field.type">
                  <v-flex>
                    {{ field.label }}
                    <span class="red--text">*</span>
                  </v-flex>
                  <v-flex>
                    <v-text-field
                      solo
                      v-model="field.value"
                      :placeholder="field.label"
                      class="name-textbox excursion-input"
                      :id="`txt_${field.name}`"
                      required
                      :error-messages="field.errorMessage"
                      @input="getStudentManagementFieldErrors(field)"
                    />
                  </v-flex>
                </div>
                <div v-if="'json-textarea' === field.type">
                  <v-flex>
                    {{ field.label }}
                  </v-flex>
                  <v-flex>
                    <v-textarea
                      solo
                      v-model="field.value"
                      :placeholder="field.label"
                      :id="`txt_${field.name}`"
                      :error-messages="field.errorMessage"
                      @input="getStudentManagementJsonTextareaFieldErrors(field)"
                    ></v-textarea>
                  </v-flex>
                </div>
                <div v-if="'checkbox' === field.type">
                  <v-flex>
                    <v-checkbox
                      v-model="field.value"
                      class="client-api-metadata-schema-checkbox"
                      @change="isReadFromCache = false"
                      :id="`tickbox_${field.name}`"
                      :error-messages="field.errorMessage"
                      :label="field.label"
                    ></v-checkbox>
                  </v-flex>
                </div>
                <div v-if="'file' === field.type">
                  <div :key="index" v-for="(file, index) in studentManagementFiles">
                    <div v-if="!file.isDeleted">
                      <v-layout wrap>
                        <v-flex xs12>
                          <v-layout wrap>
                            <v-flex xs12>
                              <label :for="getUploadFileId('studentManagement', index)">
                                <input
                                  :id="getUploadFileId('studentManagement', index)"
                                  style="display: none"
                                  type="file"
                                  :accept="studentManagementFileExtensions"
                                  @change="handleStudentManagementFileChange($event, file, index)"
                                />
                              </label>
                              <cs-button
                                :id="getUploadFileBtnId('studentManagement', index)"
                                @click="uploadFileClick('studentManagement', index)"
                                primary
                                class="excursion-stepper-upload-btn"
                                leftIcon="fa fa-upload"
                                label="Upload Subjects CSV"
                              ></cs-button>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 class="form-label template-file-font"> File Name </v-flex>
                        <v-flex xs12>
                          <v-layout wrap>
                            <v-flex xs12 class="csv-file-name">
                              {{ file.filename }}
                            </v-flex>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                  </div>
                </div>
                <div v-if="'group-management' === field.type" class="d-flex flex-column">
                  <v-flex
                    id="testConnectionContainer"
                    v-if="showStudentManagementTestConnectionBtn"
                    d-flex
                    align-center
                  >
                    <cs-button
                      primary
                      class="btn-update-groups client-individual-float-left ml-2"
                      name="btnTestConnection"
                      id="btnTestConnection"
                      :disabled="studentManagementTestConnectionButtonDisabled"
                      @click="onClickStudentManagementTestConnection()"
                      :loading="studentManagementTestConnectionInProgress"
                      label="Test Connection"
                    ></cs-button>
                    <v-label
                      :class="
                        studentManagementTestConnectionFailedStatus.length > 0
                          ? 'generic-error-message'
                          : ''
                      "
                    >
                      {{ studentManagementTestConnectionStatusDisplay }}
                    </v-label>
                  </v-flex>
                  <v-flex class="client-individual-group-button-container">
                    <cs-button
                      :primary="!isReadFromCache"
                      name="btnAdminRetrieveGroupsFromVendor"
                      id="btnAdminRetrieveGroupsFromVendor"
                      :disabled="groupsLoading"
                      :loading="groupsLoading"
                      @click="updateGroups(false)"
                      label="Retrieve Groups from Vendor"
                    ></cs-button>
                    <cs-button
                      primary
                      id="btnAdminRetrieveGroupsFromCache"
                      :disabled="!isReadFromCache || groupsLoading"
                      :loading="groupsLoading"
                      @click="updateGroups(true)"
                      label="Retrieve Groups from Cache"
                    ></cs-button>
                  </v-flex>
                  <v-flex>
                    <v-autocomplete
                      class="client-individual-bulk-parent-select client-individual-float-left ml-2"
                      v-model="selectedApplyParentId"
                      :items="originalGroupList"
                      label="Set parent for selected group"
                      item-text="group"
                      item-value="id"
                      :cache-items="false"
                      clearable
                      :multiple="false"
                      ref="selectGroup"
                      hide-details
                      :hide-selected="hideSelected"
                      :deletable-chips="false"
                      solo
                    >
                      <template slot="item" slot-scope="data">
                        <span
                          :id="`client-individual-modal--set-parent-for-selected-group--option--${String(
                            data.item.group
                          ).replaceAll(' ', '')}`"
                        >
                          {{ data.item.group }}
                        </span>
                      </template>
                    </v-autocomplete>
                    <div class="client-individual-float-left client-individual-apply-option">
                      <v-checkbox
                        id="client-individual-apply-subject-checkbox"
                        class="client-individual-float-left mt-2"
                        v-model="applySubjectEnabled"
                        label="Subject"
                      />
                    </div>
                    <div class="client-individual-float-left client-individual-apply-option">
                      <v-checkbox
                        id="client-individual-apply-custom-checkbox"
                        class="client-individual-float-left mt-2"
                        v-model="applyCustomEnabled"
                        label="Custom"
                      />
                    </div>
                    <cs-button
                      primary
                      style="margin-left: 10px"
                      class="btn-update-groups client-individual-float-left"
                      name="btnApply"
                      id="btnApply"
                      :disabled="applyButtonDisabled()"
                      @click="applySelectedOption()"
                      label="Apply"
                    ></cs-button>
                  </v-flex>
                  <v-flex xs12 v-if="studentManagementGroupSyncDiffs.length > 0">
                    <student-group-sync-diff-alert
                      :studentGroupSyncDiffs="studentManagementGroupSyncDiffs"
                    />
                  </v-flex>
                  <v-flex xs8 class="student-management-group-search-container">
                    <v-text-field
                      v-model="searchTerm"
                      single-line
                      clearable
                      placeholder="Search Group Name"
                      id="student-management-search-input"
                      @click:clear="clearSearchInput()"
                    ></v-text-field>
                    <cs-button
                      primary
                      name="btnStudentGroupSearch"
                      id="btnClientIndividualEditorSearch"
                      @click="filterGroups()"
                      label="Search Group"
                    ></cs-button>
                  </v-flex>
                  <v-layout style="height: 62vh">
                    <v-flex style="overflow: auto">
                      <v-data-table
                        :headers="groupHeaders"
                        v-model="selectedGroupItems"
                        :items="groupList"
                        class="elevation-2 excursion-table"
                        :loading="groupsLoading"
                        no-data-text="No group was found"
                        :options.sync="studentGroupPaginationInfo"
                        :footer-props="footerProps"
                        show-select
                      >
                        <template slot="item" slot-scope="props">
                          <tr>
                            <td class="excursion-name-cell">
                              <v-checkbox
                                hide-details
                                v-model="props.isSelected"
                                class="client-individual-group-checkbox"
                                @click.native="clickCheckbox(props)"
                                :id="getStudentManagementCheckboxId(props.item.group)"
                              ></v-checkbox>
                            </td>
                            <td class="excursion-name-cell">
                              {{ props.item.group }}
                              <span v-if="vendorGroupIdDisplayEnabled" class="studentGroupId">
                                [{{ props.item.id }}]</span
                              >
                            </td>
                            <td class="excursion-name-cell">
                              <v-select
                                clearable
                                v-model="props.item.school"
                                class="type-dropdown client-individual-student-select"
                                :items="schoolTypes"
                                item-text="name"
                                item-value="id"
                                label="School type"
                                solo
                                :hide-details="true"
                                :id="getStudentManagementSchoolTypeSelectionId(props.item.group)"
                              >
                                <template slot="item" slot-scope="data">
                                  <div
                                    :id="`client-individual-modal--student-management--school-type--option-${String(
                                      data.item.name
                                    ).replaceAll(' ', '')}`"
                                  >
                                    {{ data.item.name }}
                                  </div>
                                </template>
                              </v-select>
                            </td>
                            <td class="text-xs-left">
                              <div v-if="!props.item.parentSelectionDisplayed">
                                <a
                                  :id="getStudentManagementParentSelectionId(props.item.group)"
                                  class="client-individual-parent"
                                  @click="displayParentSelection(props.item)"
                                >
                                  {{
                                    !props.item.parent
                                      ? '[select]'
                                      : getParentGroupName(props.item.parent).length === 0
                                      ? '[missing parent]'
                                      : getParentGroupName(props.item.parent)
                                  }}
                                </a>
                                <span
                                  v-if="vendorGroupIdDisplayEnabled && props.item.parent"
                                  class="studentGroupId"
                                >
                                  [{{ getParentGroupId(props.item.parent) }}]</span
                                >
                              </div>
                              <v-autocomplete
                                class="client-individual-student-select"
                                v-if="props.item.parentSelectionDisplayed"
                                v-model="props.item.parent"
                                :items="originalGroupList"
                                label="Parent"
                                item-text="group"
                                item-value="id"
                                :cache-items="false"
                                clearable
                                :multiple="false"
                                ref="selectGroup"
                                hide-details
                                :hide-selected="hideSelected"
                                :deletable-chips="false"
                                solo
                                @blur="parentSelected(props.item)"
                              >
                                <template slot="item" slot-scope="data">
                                  <span
                                    :id="`client-individual-modal--set-parent-for-selected-group--option--${String(
                                      data.item.group
                                    ).replaceAll(' ', '')}`"
                                  >
                                    {{ data.item.group }}
                                  </span>
                                </template>
                              </v-autocomplete>
                            </td>
                            <td class="text-xs-left">
                              <v-checkbox
                                v-model="props.item.subjectGroup"
                                :id="getStudentManagementSubjectGroupCheckboxId(props.item.group)"
                                class="client-individual-checkbox"
                              ></v-checkbox>
                            </td>
                            <td class="text-xs-left">
                              <v-checkbox
                                :id="getStudentManagementCustomGroupCheckboxId(props.item.group)"
                                v-model="props.item.customGroup"
                                class="client-individual-checkbox"
                              ></v-checkbox>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-flex>
                  </v-layout>
                </div>
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="additionalInfoList">
                <v-flex xs12 class="additional-info-list-info-text">
                  <i class="fas fa-info-circle"></i>
                  You can upload one file for client template.
                </v-flex>
                <div :key="index" v-for="(proformaTemplateFile, index) in clientTemplateFiles">
                  <div v-if="!proformaTemplateFile.isDeleted">
                    <v-layout wrap>
                      <v-flex xs12>
                        <v-layout wrap>
                          <v-flex xs12>
                            <label :for="getUploadFileId('proformaTemplate', index)">
                              <input
                                :id="getUploadFileId('proformaTemplate', index)"
                                style="display: none"
                                type="file"
                                :accept="proformaTemplateFileExtensions"
                                @change="
                                  handleTemplateFileChange($event, proformaTemplateFile, index)
                                "
                              />
                            </label>
                            <cs-button
                              :id="getUploadFileBtnId('proformaTemplate', index)"
                              @click="uploadFileClick('proformaTemplate', index)"
                              primary
                              class="excursion-stepper-upload-btn v-btn-upload"
                              leftIcon="fa fa-upload"
                              label="Upload"
                            ></cs-button>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 class="form-label template-file-font"> File Name </v-flex>
                      <v-flex xs12>
                        <v-layout wrap>
                          <v-flex xs12 style="margin-left: 3px">
                            {{ proformaTemplateFile.filename }}
                            <v-icon
                              :id="getDownloadFileBtnId('proformaTemplate', index)"
                              v-if="proformaTemplateFile.sid && !templateFileChanged"
                              @click="downloadFile(proformaTemplateFile)"
                              medium
                              style="font-size: 15px; height: 17px; margin-left: 0.7%"
                              >fa fa-download</v-icon
                            >
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex xs12 class="form-label template-file-font"> Uploaded Date </v-flex>
                      <v-flex xs12>
                        {{ getUploadDate(proformaTemplateFile.fileUpdateDate) }}
                      </v-flex>
                    </v-layout>
                  </div>
                </div>
              </div>
            </v-tab-item>
          </v-tabs>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-flex class="client-individual-error">
          {{ saveActionErrorMessage }}
        </v-flex>
        <v-spacer></v-spacer>
        <cs-button
          id="btnSave"
          primary
          label="Save All"
          @click="confirmSaveDialogDisplayed = true"
          :disabled="saveButtonDisabled"
          :loading="clientSaveActionInProgress"
        ></cs-button>
      </v-card-actions>
    </v-card>
    <cs-form-dialog
      v-model="invalidFileTypeDialogDisplayed"
      id="dlg-client-ind-editor-invalid-file-type"
      bodyId="dlg-client-ind-editor-invalid-file-type-body"
      heading="Client Individual Editor"
      :primaryAction="{
        label: 'OK',
        eventName: 'primary-click'
      }"
      @primary-click="invalidFileTypeDialogDisplayed = false"
    >
      <template v-slot:cs-form-dialog-content>
        Invalid File type:
        <span class="debug-error-message">{{ uploadFilename }}</span>
        <br />
        <span v-safe-html="invalidFileTypeAdditionalMessage"></span>
      </template>
    </cs-form-dialog>
    <cs-form-dialog
      v-model="genericDialogDisplayed"
      id="dlg-client-ind-editor-generic"
      bodyId="dlg-client-ind-editor-generic-body"
      heading="Client Individual Editor"
      :primaryAction="{
        label: 'OK',
        eventName: 'primary-click'
      }"
      @primary-click="genericDialogOkBtnClickHandle"
    >
      <template v-slot:cs-form-dialog-content>
        <div
          :class="isGenericDialogDisplayedAsError ? 'generic-error-message' : ''"
          v-safe-html="genericDialogMessage"
        ></div>
      </template>
    </cs-form-dialog>
    <cs-form-dialog
      v-model="confirmSaveDialogDisplayed"
      id="confirm-save-form-dialog"
      heading="Client Settings Editor"
      :primaryAction="{
        label: 'Continue',
        eventName: 'onClickContinue'
      }"
      @onClickContinue="studentManagementTestConnectionBeforeSave"
    >
      <template v-slot:cs-form-dialog-content>
        <div>
          <div v-if="studentManagementGroupSyncDiffs.length > 0">
            <student-group-sync-diff-alert
              :studentGroupSyncDiffs="studentManagementGroupSyncDiffs"
              :displayIgnoreMessage="true"
            />
          </div>
          <span>You are about to save the client settings. Are you sure you want to proceed?</span>
        </div>
      </template>
    </cs-form-dialog>
    <cs-form-dialog
      v-model="confirmTestConnectionFailedDialogDisplayed"
      id="confirm-test-connection-failed-dialog"
      heading="Client Settings Editor"
      :primaryAction="{
        label: 'Continue',
        eventName: 'onClickContinue'
      }"
      @onClickContinue="save"
    >
      <template v-slot:cs-form-dialog-content>
        <div>
          <span>
            Failed to connect to the student management vendor based on the current settings. Please
            check your configuration and try again.
          </span>
          <br /><br />
          <span>Do you still want to proceed with saving?</span>
        </div>
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
import * as types from '@/store/mutationTypes';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import attachmentCategory from '../lib/const/attachmentCategory';
import dbEntityRelation from '../lib/const/dbEntityRelation';
import errorCaseSid from '../lib/const/errorCaseSid';
import providerSourceType from '../lib/const/providerSourceType';
// import { deleteCacheMessages, populateErrorMessage } from '../lib/errorMessages';
import clientIndividualEditorSection from '../lib/const/clientIndividualEditorSection';
import studentGroupTableColumn from '../lib/const/studentGroupTableColumn';
import studentManagement from '../lib/studentManagement';
import ClientGeneral from './ClientGeneral';
import ClientContentEngine from './ClientContentEngine';

function validateKebabCasedString(value) {
  const regex = /^([a-z0-9]*)([-.][a-z0-9]+)*$/;
  const kebabCasedStringValid = regex.test(value);
  return kebabCasedStringValid;
}

function validateSubmomain(value) {
  if (!value || (value && value.length === 0)) {
    return true;
  }
  const regex = /^[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})*$/;
  const subdomainValid = regex.test(value);
  return subdomainValid;
}

function validateUrl(value) {
  if (!value || (value && value.length === 0)) {
    return true;
  }
  const regex = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w-]+)+[\w\-_~:/?#[\]@!&',;=.]+$/;
  const urlValid = regex.test(value);
  return urlValid;
}

export default {
  mixins: [validationMixin],

  components: {
    ClientGeneral,
    ClientContentEngine
  },

  validations: {
    clientName: {
      required
    },
    vendorSid: {
      required
    },
    vendorClientSid: {
      required,
      validateKebabCasedString
    },
    vendorClientName: {
      required
    },
    timezone: {
      required
    },
    subdomain: {
      required,
      validateSubmomain
    },
    idpUrl: {
      validateIdpUrl: validateUrl
    },
    excursionManagementVendorWebhookUrl: {
      validateExcursionManagementVendorWebhookUrl: validateUrl
    },
    provider: {
      apiBaseUrl: {
        validateApiBaseUrl: validateUrl
      }
    }
  },
  data() {
    return {
      studentManagementTestConnectionInProgress: false,
      studentManagementTestConnectionAt: '',
      studentManagementTestConnectionFailedStatus: '',
      propType: '',
      propClientSid: '',
      propClientIndividualModalRefreshed: true,
      activeSectionIndex: 0,
      excursionManagementVendorSharedSecretKey: '',
      auth0LoginEnabled: false,
      clientIndividualModalDisplayed: false,
      clientSid: '',
      clientName: '',
      csClientId: '-',
      vendorSid: '',
      vendorClientSid: '',
      vendorClientName: '',
      excursionManagementVendorWebhookUrl: '',
      clientLogoBackgroundColor: '',
      parentClientSid: '',
      searchParentClients: null,
      prevGetParent: 0,
      footerProps: {
        'items-per-page-options': [10, 30, 50]
      },
      studentGroupPaginationInfo: {
        itemsPerPage: 30,
        page: 1,
        sortDesc: [false],
        sortBy: ['']
      },
      pagination: {
        itemsPerPage: -1,
        descending: false,
        page: 1,
        sortDesc: [false],
        sortBy: ['']
      },
      excursionManagementVendors: [],
      studentManagementVendors: [],
      isGenericDialogDisplayedAsError: false,
      genericDialogDisplayed: false,
      genericDialogMessage: '',
      vendorExcursionSidInputEnabled: false,
      boardingSupportEnabled: false,
      vendorClientSidConflicted: false,
      excursionAdditionalInformationEnabled: false,
      excursionChecklistEnforcementBeforeSubmissionEnabled: false,
      excursionCopyActionEnabled: false,
      excursionDestinationContentEnabled: false,
      excursionHighRiskStudentMedicalEnabled: false,
      excursionPersonResponsibleEnabled: false,
      excursionPolicyViewActionEnabled: false,
      excursionInherentRiskRatingEnabled: false,
      idpRbaclEnabled: false,
      publicSchoolFeatureSetEnabled: false,
      querystringRefToTableColumnRefMap: {},
      tableColumnRefToQuerystringRefMap: {},
      imgSrc: '',
      cropImg: '',
      logoFile: '',
      searchDomain: '',
      logoFileChanged: false,
      originalLogoUsed: true,
      contentEngine: {
        ceApiUri: '',
        xSiteUri: '',
        moduleName: ''
      },
      timezone: '',
      clientSaveActionInProgress: false,
      ceParamErrorDisplayed: false,
      subdomain: '',
      idpUrl: '',
      subdomainConflicted: false,
      activeStudentManagementVendorSid: '',
      studentManagementClientApiFields: [],
      studentManagement: {},
      newSchoolType: {},
      schoolTypes: [],
      savedSchoolTypes: [],
      schoolTypeValid: true,
      groupHeaders: [
        {
          text: studentGroupTableColumn.GROUP.label,
          value: studentGroupTableColumn.GROUP.tableColumnRef,
          class: 'header',
          width: 200
        },
        {
          text: studentGroupTableColumn.SCHOOL_TYPE.label,
          value: studentGroupTableColumn.SCHOOL_TYPE.tableColumnRef,
          class: 'header',
          width: 200
        },
        {
          text: studentGroupTableColumn.PARENT.label,
          value: studentGroupTableColumn.PARENT.tableColumnRef,
          class: 'header',
          width: 200
        },
        {
          text: studentGroupTableColumn.SUBJECT_GROUP.label,
          value: studentGroupTableColumn.SUBJECT_GROUP.tableColumnRef,
          class: 'header',
          width: 110
        },
        {
          text: studentGroupTableColumn.CUSTOM_GROUP.label,
          value: studentGroupTableColumn.CUSTOM_GROUP.tableColumnRef,
          class: 'header',
          width: 110
        }
      ],
      selectedGroupItems: [],
      groupList: [],
      groupsLoading: false,
      selectedApplyParentId: 0,
      applySubjectEnabled: false,
      applyCustomEnabled: false,
      clientTemplateFiles: [{}],
      invalidFileTypeDialogDisplayed: false,
      invalidFileTypeAdditionalMessage: '',
      selectedTemplateFile: {},
      proformaTemplateFileExtensions: `.pdf,.doc,.docx,.xls,.xlsx`,
      proformaTemplateFileMimeTypeAllowed: [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      ],
      studentManagementFileExtensions: `.csv`,
      studentManagementFileMimeTypeAllowed: ['text/csv'],
      studentManagementFiles: [{}],
      uploadFilename: '',
      templateFileChanged: false,
      studentManagementFileChanged: false,
      providerSourceTypes: [
        {
          text: providerSourceType.SFTP,
          value: providerSourceType.SFTP
        },
        {
          text: providerSourceType.API,
          value: providerSourceType.API
        }
      ],
      provider: {},
      siteData: {},
      searchTerm: '',
      originalGroupList: [],
      ceParamErrorMessage: 'Invalid Content Engine parameter',
      apiProviderParamErrorMessage: 'Invalid Api Provider parameter',
      saveActionErrorMessage: '',
      studentManagementErrorHash: {},
      isClientSaved: false,
      clientStudentGroupSubsetAttributeKeys: ['sub_long', 'sub_short', 'sub_type', 'originalType'],
      invalidCredentials: `One or more of these three fields is invalid. Please confirm with the school or vendor and update. Invalid App Code / Company Code / Token Key`,
      hideSelected: false,
      studentManagementGroupSyncDiffs: [],
      confirmSaveDialogDisplayed: false,
      isReadFromCache: false,
      studentDetailsApiGroupOnlyEnabled: false,
      confirmTestConnectionFailedDialogDisplayed: false
    };
  },
  computed: {
    showStudentManagementTestConnectionBtn() {
      if (!this.activeStudentManagementVendorSid) {
        return false;
      }
      const vendorName = this.studentManagementVendors.filter(
        (item) => item.sid === this.activeStudentManagementVendorSid
      )[0].name;
      return vendorName === 'wonde';
    },
    studentManagementTestConnectionRequired() {
      if (!this.activeStudentManagementVendorSid) {
        return false;
      }
      const vendorName = this.studentManagementVendors.filter(
        (item) => item.sid === this.activeStudentManagementVendorSid
      )[0].name;
      return vendorName === 'wonde';
    },
    vendorGroupIdDisplayEnabled() {
      if (!this.activeStudentManagementVendorSid) {
        return false;
      }
      const vendorName = this.studentManagementVendors.filter(
        (item) => item.sid === this.activeStudentManagementVendorSid
      )[0].name;
      return vendorName === 'operoo';
    },
    vendorSidErrors() {
      const errors = [];
      if (!this.$v.vendorSid.$dirty) return errors;
      if (!this.$v.vendorSid.required) {
        errors.push('Vendor Name is required');
      }
      return errors;
    },
    vendorClientSidErrors() {
      const errors = [];
      if (!this.$v.vendorClientSid.$dirty) return errors;
      if (!this.$v.vendorClientSid.required) {
        errors.push('Vendor Client SID is required');
      } else if (!this.$v.vendorClientSid.validateKebabCasedString) {
        errors.push('Vendor Client SID must be a kebab-cased string');
      } else if (this.vendorClientSidConflicted) {
        errors.push('Vendor Client SID conflicted');
      }
      return errors;
    },
    vendorClientNameErrors() {
      const errors = [];
      if (!this.$v.vendorClientName.$dirty) return errors;
      if (!this.$v.vendorClientName.required) {
        errors.push('Vendor Client Name is required');
      }
      return errors;
    },

    clientIndividualModalRefreshed() {
      return this.propClientIndividualModalRefreshed;
    },
    inputInvalid() {
      return this.$v.$invalid;
    },
    providerInvalid() {
      return (
        this.provider.sourceType &&
        providerSourceType.API === this.provider.sourceType &&
        (!this.provider.apiBaseUrl ||
          !this.provider.apiKey ||
          !this.provider.apiTenantSid ||
          !this.provider.apiReportSid)
      );
    },
    saveButtonDisabled() {
      let studentManagementValid = true;
      if (
        this.studentManagementClientApiFields &&
        this.studentManagementClientApiFields.length > 0
      ) {
        // TODO: Refactor this since certain text fields might not be mandatory
        const validatedFields = this.studentManagementClientApiFields.filter(
          (f) => f.type === 'text'
        );
        if (validatedFields && validatedFields.length > 0) {
          studentManagementValid = validatedFields.every((f) => f.value && `${f.value}`.length > 0);
        }
      }
      return (
        this.inputInvalid ||
        this.clientSaveActionInProgress ||
        !studentManagementValid ||
        !(
          this.contentEngine.ceApiUri &&
          this.contentEngine.xSiteUri &&
          this.contentEngine.moduleName
        ) ||
        !this.schoolTypeValid ||
        this.providerInvalid ||
        Object.keys(this.studentManagementErrorHash).length > 0
      );
    },
    parentClientLoadActionInProgress() {
      return this.$store.state.common.parentClientList.loading;
    },
    parentClientItems() {
      return this.$store.state.common.parentClientList.items.filter(
        (item) => item.sid !== this.propClientSid
      );
    },
    parentClientCache() {
      return this.$store.state.common.parentClientList.itemsCache;
    },
    excursionManagementVendorWebhookUrlErrors() {
      const errors = [];
      if (!this.$v.excursionManagementVendorWebhookUrl.$dirty) return errors;
      if (
        !this.$v.excursionManagementVendorWebhookUrl.validateExcursionManagementVendorWebhookUrl
      ) {
        errors.push('Invalid Excursion Management Vendor Webhook URL');
      }
      return errors;
    },
    clientProviderSftpPathUpdateActionEnabled() {
      return this.$store.state.common.clientProviderSftpPathUpdateActionEnabled;
    },
    providerApiBaseUrlErrors() {
      const errors = [];
      if (!this.$v.provider.$dirty) return errors;
      if (!this.provider.apiBaseUrl) {
        errors.push('API Base URL is required');
      } else if (!this.$v.provider.apiBaseUrl.validateApiBaseUrl) {
        errors.push('Invalid API Base URL');
      }
      return errors;
    },
    providerApiKeyErrors() {
      const errors = [];
      if (!this.$v.provider.$dirty) return errors;
      if (!this.provider.apiKey) {
        errors.push('API Key is required');
      }

      return errors;
    },
    providerApiTenantSidErrors() {
      const errors = [];
      if (!this.$v.provider.$dirty) return errors;
      if (!this.provider.apiTenantSid) {
        errors.push('API Tenant SID is required');
      }
      return errors;
    },
    providerApiReportSidErrors() {
      const errors = [];
      if (!this.$v.provider.$dirty) return errors;
      if (!this.provider.apiReportSid) {
        errors.push('API Report SID is required');
      }
      return errors;
    },
    subdomainErrors() {
      const errors = [];
      if (this.subdomainConflicted) {
        errors.push('Client Subdomain conflicted');
      }
      return errors;
    },
    studentManagementTestConnectionButtonDisabled() {
      const validatedFields = this.studentManagementClientApiFields.filter(
        (f) => f.type === 'text'
      );

      if (validatedFields && validatedFields.length > 0) {
        return (
          !validatedFields.every((f) => f.value && `${f.value}`.length > 0) ||
          this.studentManagementTestConnectionInProgress
        );
      }

      return true;
    },
    studentManagementTestConnectionStatusDisplay() {
      if (
        this.studentManagementTestConnectionFailedStatus.length === 0 &&
        this.studentManagementTestConnectionAt.length > 0
      ) {
        return `Last Succeed At: ${this.$app
          .moment(this.studentManagementTestConnectionAt)
          .format('YYYY-MM-DD HH:mm:ss')}.`;
      }

      if (this.studentManagementTestConnectionAt.length > 0) {
        return `Last Failed At: ${this.$app
          .moment(this.studentManagementTestConnectionAt)
          .format('YYYY-MM-DD HH:mm:ss')}. ${this.studentManagementTestConnectionFailedStatus}`;
      }

      return '';
    }
  },
  methods: {
    getSchoolTypeTxtFieldId(index) {
      return `client-individual-modal--school-type--text-input-${index}`;
    },
    getStudentManagementCheckboxId(group) {
      return `client-individual-modal--student-management--checkbox-${group}`;
    },
    getStudentManagementParentSelectionId(group) {
      return `client-individual-modal--student-management-parent--selection-${group}`;
    },
    getStudentManagementSubjectGroupCheckboxId(group) {
      return `client-individual-modal--student-management-subject-group--checkbox-${group}`;
    },
    getStudentManagementCustomGroupCheckboxId(group) {
      return `client-individual-modal--student-management-custom-group--checkbox-${group}`;
    },
    getStudentManagementSchoolTypeSelectionId(group) {
      return `client-individual-modal--student-management-school-type--selection-${group}`;
    },
    getSelectedVendorName() {
      return this.excursionManagementVendors.reduce((result, vendor) => {
        let localResult = result;
        if (this.vendorSid === vendor.sid) {
          localResult = vendor.name;
        }
        return localResult;
      }, false);
    },
    async newClient(clientSid) {
      this.vendorClientSidConflicted = false;
      this.subdomainConflicted = false;

      if (clientSid !== '' && undefined !== clientSid) {
        let auth0LoginEnabled = false;
        let vendorClientSid = '';
        let vendorClientName = '';
        let vendorExcursionSidInputEnabled = false;
        let boardingSupportEnabled = false;
        let excursionAdditionalInformationEnabled = false;
        let excursionChecklistEnforcementBeforeSubmissionEnabled = false;
        let excursionCopyActionEnabled = false;
        let excursionDestinationContentEnabled = false;
        let excursionHighRiskStudentMedicalEnabled = false;
        let excursionPersonResponsibleEnabled = false;
        let excursionPolicyViewActionEnabled = false;
        let excursionInherentRiskRatingEnabled = false;
        let clientLogoBackgroundColor = '';
        let provider = {};
        let excursionManagementVendorWebhookUrl = '';
        let excursionManagementVendorSharedSecretKey = '';
        let idpRbaclEnabled = false;
        let publicSchoolFeatureSetEnabled = false;

        // get logo
        const requestParam = {
          'vendor-sid': this.$store.state.common.apiParam['vendor-sid'],
          'oauth-signature': this.$store.state.common.apiParam['oauth-signature']
        };

        const argHash = {
          sid: this.clientSid,
          requestParam,
          store: this.$store
        };

        const cbResponse = await this.$app.stbApiAdapter.getClients(argHash);

        this.imgSrc = '';
        if (!cbResponse.error) {
          const client = cbResponse.data[0];

          if (client.cs_client_id) {
            this.csClientId = client.cs_client_id;
          }

          if (client.computed.logoAttachmentUrl) {
            this.cropImg = client.computed.logoAttachmentUrl;
          }

          auth0LoginEnabled = client.metadata.auth0LoginEnabled
            ? client.metadata.auth0LoginEnabled
            : false;

          const activeVendorSid = client.metadata.activeVendorSid
            ? client.metadata.activeVendorSid
            : '';

          excursionAdditionalInformationEnabled = client.metadata
            .excursionAdditionalInformationEnabled
            ? client.metadata.excursionAdditionalInformationEnabled
            : false;

          excursionChecklistEnforcementBeforeSubmissionEnabled = client.metadata
            .excursionChecklistEnforcementBeforeSubmissionEnabled
            ? client.metadata.excursionChecklistEnforcementBeforeSubmissionEnabled
            : false;

          excursionCopyActionEnabled = client.metadata.excursionCopyActionEnabled
            ? client.metadata.excursionCopyActionEnabled
            : false;

          excursionDestinationContentEnabled = client.metadata.excursionDestinationContentEnabled
            ? client.metadata.excursionDestinationContentEnabled
            : false;

          excursionHighRiskStudentMedicalEnabled = client.metadata
            .excursionHighRiskStudentMedicalEnabled
            ? client.metadata.excursionHighRiskStudentMedicalEnabled
            : false;

          excursionPolicyViewActionEnabled = client.metadata.excursionPolicyViewActionEnabled
            ? client.metadata.excursionPolicyViewActionEnabled
            : false;

          excursionInherentRiskRatingEnabled = client.metadata.excursionInherentRiskRatingEnabled
            ? client.metadata.excursionInherentRiskRatingEnabled
            : false;

          idpRbaclEnabled = client.metadata.idpRbaclEnabled
            ? client.metadata.idpRbaclEnabled
            : false;

          publicSchoolFeatureSetEnabled = client.metadata.publicSchoolFeatureSetEnabled
            ? client.metadata.publicSchoolFeatureSetEnabled
            : false;

          if (client.metadata.provider) {
            provider = client.metadata.provider;
          }

          const vendors = client[dbEntityRelation.VENDOR_LIST];
          if (activeVendorSid !== '') {
            const vendor = vendors.find((v) => v.sid === `${activeVendorSid}`);
            if (vendor) {
              if (vendor['vendor-client-sid']) {
                vendorClientSid = vendor['vendor-client-sid'];
              }

              if (vendor.join_metadata.vendorClientName) {
                vendorClientName = vendor.join_metadata.vendorClientName;
              }

              if (vendor.join_metadata.vendorExcursionSidInputEnabled) {
                vendorExcursionSidInputEnabled =
                  vendor.join_metadata.vendorExcursionSidInputEnabled;
              }

              if (vendor.join_metadata.boardingSupportEnabled) {
                boardingSupportEnabled = vendor.join_metadata.boardingSupportEnabled;
              }

              if (vendor.join_metadata.excursionPersonResponsibleEnabled) {
                excursionPersonResponsibleEnabled =
                  vendor.join_metadata.excursionPersonResponsibleEnabled;
              }

              if (vendor.join_metadata.clientLogoBackgroundColor) {
                clientLogoBackgroundColor = vendor.join_metadata.clientLogoBackgroundColor;
              }

              if (vendor.join_metadata.excursionManagementVendorWebhookUrl) {
                excursionManagementVendorWebhookUrl =
                  vendor.join_metadata.excursionManagementVendorWebhookUrl;
              }

              if (vendor.join_metadata.excursionManagementVendorSharedSecretKey) {
                excursionManagementVendorSharedSecretKey =
                  vendor.join_metadata.excursionManagementVendorSharedSecretKey;
              }
            }
          }

          this.auth0LoginEnabled = auth0LoginEnabled;
          this.clientLogoBackgroundColor = clientLogoBackgroundColor;
          this.clientName = client.metadata.name;
          this.excursionAdditionalInformationEnabled = excursionAdditionalInformationEnabled;
          this.excursionCopyActionEnabled = excursionCopyActionEnabled;
          this.excursionDestinationContentEnabled = excursionDestinationContentEnabled;
          this.excursionHighRiskStudentMedicalEnabled = excursionHighRiskStudentMedicalEnabled;
          this.excursionPersonResponsibleEnabled = excursionPersonResponsibleEnabled;
          this.excursionInherentRiskRatingEnabled = excursionInherentRiskRatingEnabled;
          this.idpRbaclEnabled = idpRbaclEnabled;
          this.publicSchoolFeatureSetEnabled = publicSchoolFeatureSetEnabled;
          this.excursionPolicyViewActionEnabled = excursionPolicyViewActionEnabled;
          this.excursionChecklistEnforcementBeforeSubmissionEnabled =
            excursionChecklistEnforcementBeforeSubmissionEnabled;
          this.provider = provider;
          this.vendorClientName = vendorClientName;
          this.vendorClientSid = vendorClientSid;
          this.vendorExcursionSidInputEnabled = vendorExcursionSidInputEnabled;
          this.boardingSupportEnabled = boardingSupportEnabled;
          this.vendorSid = client.metadata.activeVendorSid;
          this.excursionManagementVendorWebhookUrl = excursionManagementVendorWebhookUrl;
          this.excursionManagementVendorSharedSecretKey = excursionManagementVendorSharedSecretKey;
          this.activeStudentManagementVendorSid = client.metadata.activeStudentManagementVendorSid;

          if (this.activeStudentManagementVendorSid !== '') {
            const vendor = vendors.find(
              (v) => v.sid === `${this.activeStudentManagementVendorSid}`
            );
            if (vendor && vendor.join_metadata && vendor.join_metadata.studentManagement) {
              this.studentManagement = vendor.join_metadata.studentManagement;
              this.isReadFromCache = this.studentManagement.isStudentGroupListCached;
            }
          }

          if (
            this.activeStudentManagementVendorSid &&
            this.studentManagement &&
            this.studentManagement.clientApiMetadata
          ) {
            this.studentManagementClientApiFields = [];
            this.studentDetailsApiGroupOnlyEnabled =
              !!this.studentManagement.clientApiMetadata.studentDetailsApiGroupOnlyEnabled;
            const vendor = this.studentManagementVendors.find((v) => {
              return v.sid === this.activeStudentManagementVendorSid;
            });

            let clientApiMetadataSchema = {};
            if (
              vendor &&
              vendor.studentManagement &&
              vendor.studentManagement.clientApiMetadataSchema
            ) {
              clientApiMetadataSchema = vendor.studentManagement.clientApiMetadataSchema;
            }

            Object.keys(clientApiMetadataSchema).forEach((clientApiFieldKey) => {
              const clientApiField = clientApiMetadataSchema[clientApiFieldKey];
              let clientApiFieldValue = '';

              if (
                typeof this.studentManagement.clientApiMetadata[clientApiFieldKey] !== 'undefined'
              ) {
                clientApiFieldValue = this.studentManagement.clientApiMetadata[clientApiFieldKey];
              }

              const apiField = {
                name: clientApiFieldKey,
                label: clientApiField.label,
                value: clientApiFieldValue,
                type: clientApiField.type,
                groupListQueryFieldFlag: clientApiField.groupListQueryFieldFlag
              };
              this.studentManagementClientApiFields.push(apiField);
            });

            if (this.studentManagement.clientApiMetadata.groupManagement) {
              this.groupsLoading = true;
              this.groupList = [];
              const { entryHash, associationHash } =
                this.studentManagement.clientApiMetadata.groupManagement;

              Object.keys(entryHash).forEach((entryHashKey) => {
                const entryHashValue = entryHash[entryHashKey];
                const clientStudentGroup = {
                  id: entryHashKey,
                  group: entryHashValue.name
                };

                this.clientStudentGroupSubsetAttributeKeys.forEach((subsetAttributeKey) => {
                  const subsetAttributeValue = entryHashValue[subsetAttributeKey];

                  if (typeof subsetAttributeValue !== 'undefined') {
                    clientStudentGroup[subsetAttributeKey] = subsetAttributeValue;
                  }
                });

                switch (entryHashValue.parentType) {
                  case 'group':
                    clientStudentGroup.parent = `${entryHashValue.parentId}`;
                    break;

                  case 'school':
                    clientStudentGroup.school = `${entryHashValue.parentId}`;
                    break;

                  default:
                    break;
                }

                if (associationHash.custom && associationHash.custom[entryHashKey]) {
                  clientStudentGroup.customGroup = true;
                }

                if (associationHash.subject && associationHash.subject[entryHashKey]) {
                  clientStudentGroup.subjectGroup = true;
                }

                this.groupList.push(clientStudentGroup);
              });
              this.originalGroupList = this.$app.lodash.cloneDeep(this.groupList);
              this.groupsLoading = false;
            }

            this.studentManagementTestConnectionAt = this.studentManagement.testConnectionAt || '';
            this.studentManagementTestConnectionFailedStatus =
              this.studentManagement.testConnectionFailedStatus || '';
          }

          this.contentEngine = {};
          if (client.metadata.contentEngine) {
            this.contentEngine = client.metadata.contentEngine;
            let { ceApiUri } = this.contentEngine;
            if (ceApiUri.substring(ceApiUri.length - 1) === '/') {
              ceApiUri = ceApiUri.substring(0, ceApiUri.lastIndexOf('/'));
            }
            this.contentEngine.ceApiUri = ceApiUri;
          }
          this.timezone = client.metadata.timezone ? client.metadata.timezone : '';
          if (client.parentClient && client.parentClient.metadata) {
            this.$store.state.common.parentClientList.items.push({
              sid: client.parentClient.sid,
              name: `${client.parentClient.sid} - ${client.parentClient.metadata.name}`
            });
            this.parentClientSid = client.parentClient.sid;
          }

          this.subdomain = client.metadata.subdomain ? client.metadata.subdomain : '';
          this.idpUrl = client.metadata.idpUrl ? client.metadata.idpUrl : '';

          this.schoolTypes = [];
          if (client.metadata.schoolType) {
            const { schoolType } = client.metadata;
            Object.keys(schoolType).forEach((schoolKey) => {
              this.schoolTypes.push({
                id: schoolKey,
                name: schoolType[schoolKey]
              });
            });

            this.savedSchoolTypes = JSON.parse(JSON.stringify(this.schoolTypes));
          }

          // Get template file
          if (client.computed.templateAttachment) {
            const file = client.computed.templateAttachment;
            this.clientTemplateFiles[0].filename = file.metadata.filename;
            this.clientTemplateFiles[0].fileUpdateDate = file.created_at;
            this.clientTemplateFiles[0].sid = file.sid;
          }

          this.siteData = client.metadata.siteData || {};
        }
      } else {
        this.clear();
      }
    },
    constructStudentManagementGroupManagement() {
      const groupManagement = {
        entryHash: {},
        associationHash: {
          year: {},
          class: {},
          custom: {},
          subject: {}
        }
      };
      if (
        this.groupList.length !== this.originalGroupList &&
        this.searchTerm &&
        String(this.searchTerm).trim().length !== 0
      ) {
        const parsedSearchTerm = String(this.searchTerm).trim().toLowerCase();
        const leftOriginalGroupList = this.originalGroupList.filter(
          (item) => !String(item.group).toLowerCase().includes(parsedSearchTerm)
        );
        const updatedGroupList = leftOriginalGroupList.concat(this.groupList);
        updatedGroupList.forEach((clientStudentGroup) => {
          const entryId = clientStudentGroup.id;
          const entry = {
            name: clientStudentGroup.group,
            parentId: '',
            parentType: ''
          };
          groupManagement.entryHash[entryId] = entry;

          this.clientStudentGroupSubsetAttributeKeys.forEach((subsetAttributeKey) => {
            const subsetAttributeValue = clientStudentGroup[subsetAttributeKey];

            if (typeof subsetAttributeValue !== 'undefined') {
              entry[subsetAttributeKey] = subsetAttributeValue;
            }
          });

          if (clientStudentGroup.school && !clientStudentGroup.parent) {
            entry.parentId = `${clientStudentGroup.school}`;
            entry.parentType = 'school';
            groupManagement.associationHash.year[entryId] = true;
          } else if (clientStudentGroup.school && clientStudentGroup.parent) {
            delete entry.school;
            entry.parentId = `${clientStudentGroup.parent}`;
            entry.parentType = 'group';
            if (clientStudentGroup.subjectGroup) {
              groupManagement.associationHash.subject[entryId] = true;
            } else {
              groupManagement.associationHash.class[entryId] = true;
            }
          } else if (clientStudentGroup.parent) {
            entry.parentId = `${clientStudentGroup.parent}`;
            entry.parentType = 'group';
            if (clientStudentGroup.subjectGroup) {
              groupManagement.associationHash.subject[entryId] = true;
            } else {
              groupManagement.associationHash.class[entryId] = true;
            }
          } else if (clientStudentGroup.customGroup) {
            groupManagement.associationHash.custom[entryId] = true;
          }
        });
      } else {
        this.groupList.forEach((clientStudentGroup) => {
          const entryId = clientStudentGroup.id;
          const entry = {
            name: clientStudentGroup.group,
            parentId: '',
            parentType: ''
          };
          groupManagement.entryHash[entryId] = entry;

          this.clientStudentGroupSubsetAttributeKeys.forEach((subsetAttributeKey) => {
            const subsetAttributeValue = clientStudentGroup[subsetAttributeKey];

            if (typeof subsetAttributeValue !== 'undefined') {
              entry[subsetAttributeKey] = subsetAttributeValue;
            }
          });

          if (clientStudentGroup.school) {
            entry.parentId = `${clientStudentGroup.school}`;
            entry.parentType = 'school';
            groupManagement.associationHash.year[entryId] = true;
          } else if (clientStudentGroup.parent) {
            entry.parentId = `${clientStudentGroup.parent}`;
            entry.parentType = 'group';
            if (clientStudentGroup.subjectGroup) {
              groupManagement.associationHash.subject[entryId] = true;
            } else {
              groupManagement.associationHash.class[entryId] = true;
            }
          } else if (clientStudentGroup.customGroup) {
            groupManagement.associationHash.custom[entryId] = true;
          }
        });
      }
      return groupManagement;
    },
    genericDialogOkBtnClickHandle() {
      this.genericDialogDisplayed = false;
      this.isGenericDialogDisplayedAsError = false;

      if (this.isClientSaved) {
        this.clearSearchInput();
        this.$router.replace('/admin/clients').catch(() => {});
      }
    },
    async save() {
      this.confirmTestConnectionFailedDialogDisplayed = false;
      this.confirmSaveDialogDisplayed = false;
      this.vendorClientSidConflicted = false;
      this.subdomainConflicted = false;
      this.ceParamErrorDisplayed = false;
      this.saveActionErrorMessage = '';
      this.clientSaveActionInProgress = true;

      const apiParam = JSON.parse(JSON.stringify(this.$store.state.common.apiParam));

      // check if subdomain and client name is valid
      /*
      const getClientIdentifiersArgHash = {
        domain: this.subdomain,
        store: this.$store
      };

      const cbGetClientIdentifiers = await this.$app.stbApiAdapter.getClientIdentifiers(
        getClientIdentifiersArgHash
      );
      const parsedClient = cbGetClientIdentifiers.data;
      */
      const client = {
        auth0LoginEnabled: this.auth0LoginEnabled,
        clientLogoBackgroundColor: this.clientLogoBackgroundColor,
        contentEngine: this.contentEngine,
        excursionAdditionalInformationEnabled: this.excursionAdditionalInformationEnabled,
        excursionChecklistEnforcementBeforeSubmissionEnabled:
          this.excursionChecklistEnforcementBeforeSubmissionEnabled,
        excursionCopyActionEnabled: this.excursionCopyActionEnabled,
        excursionDestinationContentEnabled: this.excursionDestinationContentEnabled,
        excursionHighRiskStudentMedicalEnabled: this.excursionHighRiskStudentMedicalEnabled,
        excursionPersonResponsibleEnabled: this.excursionPersonResponsibleEnabled,
        excursionPolicyViewActionEnabled: this.excursionPolicyViewActionEnabled,
        excursionInherentRiskRatingEnabled: this.excursionInherentRiskRatingEnabled,
        idpRbaclEnabled: this.idpRbaclEnabled,
        publicSchoolFeatureSetEnabled: this.publicSchoolFeatureSetEnabled,
        name: this.clientName,
        parentClientSid: this.parentClientSid,
        provider: this.provider,
        timezone: this.timezone,
        vendorClientName: this.vendorClientName,
        vendorClientSid: this.vendorClientSid,
        vendorExcursionSidInputEnabled: this.vendorExcursionSidInputEnabled,
        boardingSupportEnabled: this.boardingSupportEnabled,
        excursionManagementVendorWebhookUrl: this.excursionManagementVendorWebhookUrl,
        excursionManagementVendorSharedSecretKey: this.excursionManagementVendorSharedSecretKey,
        vendorSid: this.vendorSid,
        subdomain: this.subdomain,
        idpUrl: this.idpUrl,
        siteData: this.siteData
      };
      /*
      if (!cbGetClientIdentifiers.error) {
        client.csClientId = parsedClient.id;
      }
      */
      if (
        !client.contentEngine.ceApiUri &&
        !client.contentEngine.xSiteUri &&
        !client.contentEngine.moduleName
      ) {
        delete client.contentEngine;
      }

      if (this.activeStudentManagementVendorSid) {
        client.activeStudentManagementVendorSid = this.activeStudentManagementVendorSid;
        client.studentManagement = this.studentManagement;

        if (!client.studentManagement.clientApiMetadata) {
          client.studentManagement.clientApiMetadata = {};
        }

        if (
          this.studentManagementClientApiFields &&
          this.studentManagementClientApiFields.length > 0
        ) {
          this.studentManagementClientApiFields.forEach((f) => {
            let apiFieldsValue = '';
            if (typeof f.value !== 'undefined') {
              apiFieldsValue = f.value;
            }
            client.studentManagement.clientApiMetadata[f.name] = apiFieldsValue;
          });

          const groupManagement = this.constructStudentManagementGroupManagement();
          client.studentManagement.clientApiMetadata.groupManagement = groupManagement;
        }

        if (this.studentManagementTestConnectionRequired) {
          client.studentManagement.testConnectionFailedStatus =
            this.studentManagementTestConnectionFailedStatus;
          client.studentManagement.testConnectionAt = this.studentManagementTestConnectionAt;
        }
      }

      const schoolType = {};
      this.schoolTypes.forEach((s) => {
        schoolType[s.id] = s.name;
      });
      client.schoolType = schoolType;

      apiParam.metadata = client;

      if (apiParam.metadata.contentEngine) {
        delete apiParam.metadata.contentEngine.xAppKey;
        delete apiParam.metadata.contentEngine.xWebApiKey;
      }

      if (`${this.clientSid}` !== '') {
        if (this.templateFileChanged) {
          this.uploadClientTemplateFiles(this.clientSid);
        }
        if (this.studentManagementFileChanged) {
          const dataFromFile = await this.uploadStudentManagementFiles(this.clientSid);
          client.studentManagement = { clientApiMetadata: dataFromFile.clientApiMetadata };
          client.schoolType = dataFromFile.schoolType;
        }
        const argHash = {
          sid: this.clientSid,
          payload: apiParam,
          store: this.$store
        };

        const cbResponse = await this.$app.stbApiAdapter.putClients(argHash);
        if (cbResponse.errorDetected) {
          if (cbResponse.data && ['400', '409'].indexOf(`${cbResponse.data.statusCode}`) !== -1) {
            switch (`${cbResponse.data.errorCaseSid}`) {
              case errorCaseSid.CLIENT_SUBDOMAIN_CONFLICTED:
                this.subdomainConflicted = true;
                [this.saveActionErrorMessage] = this.subdomainErrors;
                break;

              case errorCaseSid.VENDOR_CLIENT_SID_CONFLICTED:
                this.vendorClientSidConflicted = true;
                [this.saveActionErrorMessage] = this.vendorClientSidErrors;
                break;

              case errorCaseSid.CONTENT_ENGINE_PARAMETER_INVALID:
                this.ceParamErrorDisplayed = true;
                this.saveActionErrorMessage = this.ceParamErrorMessage;
                break;

              case errorCaseSid.PROVIDER_PARAMETER_INVALID:
                this.saveActionErrorMessage = this.apiProviderParamErrorMessage;
                break;

              default:
                this.saveActionErrorMessage =
                  cbResponse.data.message || `${cbResponse.data.errorCaseSid}`;
                break;
            }
          } else {
            this.saveActionErrorMessage = cbResponse.data
              ? `${cbResponse.data.statusCode}: ${cbResponse.data.message}`
              : 'Error while saving client';
          }

          this.alertSaveResult(cbResponse);
        } else if (!cbResponse.error) {
          this.clientSid = cbResponse.data.sid;
          this.uploadClientTemplateFiles(this.clientSid);
          await this.saveLogo();
          this.alertSaveResult(cbResponse);

          this.hideSelected = false;
        } else {
          this.alertSaveResult(cbResponse);
        }
      } else {
        const apiArgHash = {
          payload: apiParam,
          store: this.$store
        };

        if (this.studentManagementFileChanged) {
          const dataFromFile = await this.uploadStudentManagementFiles(this.clientSid);
          apiArgHash.payload.metadata.studentManagement = {
            clientApiMetadata: dataFromFile.clientApiMetadata
          };
          apiArgHash.payload.metadata.schoolType = dataFromFile.schoolType;
        }

        const cbResponse = await this.$app.stbApiAdapter.postClients(apiArgHash);
        if (cbResponse.errorDetected) {
          if (cbResponse.data && ['400', '409'].indexOf(`${cbResponse.data.statusCode}`) !== -1) {
            switch (`${cbResponse.data.errorCaseSid}`) {
              case errorCaseSid.CLIENT_SUBDOMAIN_CONFLICTED:
                this.subdomainConflicted = true;
                [this.saveActionErrorMessage] = this.subdomainErrors;
                break;

              case errorCaseSid.VENDOR_CLIENT_SID_CONFLICTED:
                this.vendorClientSidConflicted = true;
                [this.saveActionErrorMessage] = this.vendorClientSidErrors;
                break;

              case errorCaseSid.CONTENT_ENGINE_PARAMETER_INVALID:
                this.ceParamErrorDisplayed = true;
                this.saveActionErrorMessage = this.ceParamErrorMessage;
                break;

              case errorCaseSid.PROVIDER_PARAMETER_INVALID:
                this.saveActionErrorMessage = this.apiProviderParamErrorMessage;
                break;

              default:
                this.saveActionErrorMessage =
                  cbResponse.data.message || `${cbResponse.data.errorCaseSid}`;
                break;
            }
          } else {
            this.saveActionErrorMessage = cbResponse.data
              ? `${cbResponse.data.statusCode}: ${cbResponse.data.message}`
              : 'Error while saving client';
          }

          this.alertSaveResult(cbResponse);
        } else if (!cbResponse.error) {
          this.clientSid = cbResponse.data.sid;
          this.uploadClientTemplateFiles(this.clientSid);

          await this.saveLogo(this.clientSid);
          this.alertSaveResult(cbResponse);

          this.hideSelected = false;
        } else {
          this.alertSaveResult(cbResponse);
        }
      }

      this.clientSaveActionInProgress = false;
    },

    async saveLogo() {
      const saveLogoRes = await this.$refs.refClientGeneral.saveLogo(this.clientSid);
      if (!saveLogoRes || saveLogoRes.error) {
        this.alertSaveResult('error', 'General Setting saved, but logo failed to be saved.');
        this.saveActionInProgress = false;
        return false;
      }
      return true;
    },

    alertSaveResult(cbResponse) {
      this.isGenericDialogDisplayedAsError = false;
      this.clientIndividualModalDisplayed = false;
      this.genericDialogDisplayed = true;

      if (cbResponse && !cbResponse.errorDetected && !cbResponse.error && cbResponse.data) {
        // success
        this.genericDialogMessage = 'Client has been saved.';
        if (
          this.groupList.length !== this.originalGroupList &&
          this.searchTerm &&
          String(this.searchTerm).trim().length !== 0
        ) {
          const parsedSearchTerm = String(this.searchTerm).trim().toLowerCase();
          const leftOriginalGroupList = this.originalGroupList.filter(
            (item) => !String(item.group).toLowerCase().includes(parsedSearchTerm)
          );
          const updatedGroupList = leftOriginalGroupList.concat(this.groupList);
          this.originalGroupList = this.$app.lodash.cloneDeep(updatedGroupList);
        }
        this.isClientSaved = true;
        this.clear();
        this.$emit('reloadClients');
      } else {
        // failed
        this.isClientSaved = false;
        this.isGenericDialogDisplayedAsError = true;
        this.genericDialogMessage = 'Failed to save Client information.';
      }
    },

    onSubdomainChanged() {
      this.subdomainConflicted = false;
      this.saveActionErrorMessage = '';
    },
    clear() {
      this.$v.$reset();
      this.auth0LoginEnabled = false;
      this.clientName = '';
      this.vendorSid = '';
      this.vendorClientSid = '';
      this.vendorClientName = '';
      this.vendorExcursionIdInputEnabled = false;
      this.excursionManagementVendorWebhookUrl = '';
      this.excursionManagementVendorSharedSecretKey = '';
      this.excursionPersonResponsibleEnabled = false;
      this.excursionAdditionalInformationEnabled = false;
      this.excursionChecklistEnforcementBeforeSubmissionEnabled = false;
      this.excursionCopyActionEnabled = false;
      this.excursionDestinationContentEnabled = false;
      this.excursionHighRiskStudentMedicalEnabled = false;
      this.excursionPolicyViewActionEnabled = false;
      this.excursionInherentRiskRatingEnabled = false;
      this.idpRbaclEnabled = false;
      this.publicSchoolFeatureSetEnabled = false;
      this.clientLogoBackgroundColor = '';
      this.contentEngine = {};
      this.timezone = '';
      this.clientSaveActionInProgress = false;
      this.ceParamErrorDisplayed = false;
      this.$store.commit(types.COMMON_RESET_PARENT_CLIENT);
      this.parentClientSid = '';
      this.subdomain = '';
      this.idpUrl = '';
      this.activeStudentManagementVendorSid = '';
      this.studentManagementClientApiFields = [];
      this.vendorExcursionSidInputEnabled = false;
      this.boardingSupportEnabled = false;
      this.schoolTypes = [];
      this.savedSchoolTypes = [];
      this.schoolTypeValid = true;
      this.newSchoolType = {};
      this.groupList = [];
      this.selectedGroupItems = [];
      this.applySubjectEnabled = false;
      this.applyCustomEnabled = false;
      this.clientTemplateFiles = [{}];
      this.studentManagementFiles = [{}];
      this.provider = {};
      this.saveActionErrorMessage = '';
      this.studentManagementErrorHash = {};
      this.isReadFromCache = false;
    },

    close() {
      this.clear();
    },

    changeActiveStudentManagementVendorSid() {
      this.studentManagementClientApiFields = [];

      if (this.activeStudentManagementVendorSid) {
        const vendor = this.studentManagementVendors.find(
          (v) => v.sid === this.activeStudentManagementVendorSid
        );

        if (
          vendor &&
          vendor.studentManagement &&
          vendor.studentManagement.clientApiMetadataSchema
        ) {
          const schema = vendor.studentManagement.clientApiMetadataSchema;
          Object.keys(schema).forEach((s) => {
            const field = {
              name: s,
              label: schema[s].label,
              type: schema[s].type,
              value: ''
            };
            this.studentManagementClientApiFields.push(field);
          });
        }
      }
    },
    getStudentManagementFieldErrors(field) {
      this.isReadFromCache = false;
      const localField = field;
      const errors = [];

      if (
        field.label === 'App Code' ||
        field.label === 'Token Key' ||
        field.label === 'Company Code'
      ) {
        const tokenKeyField = this.studentManagementClientApiFields.filter(
          (item) => item.label === 'Token Key'
        );
        if (
          tokenKeyField.length > 0 &&
          JSON.stringify(tokenKeyField[0].errorMessage)?.includes(this.invalidCredentials)
        ) {
          tokenKeyField[0].errorMessage = [];
        }
      }

      if (!field.value || (field.value && field.value.length === 0)) {
        errors.push(`${field.label} is required`);
      }
      localField.errorMessage = errors;
      this.$forceUpdate();
    },
    getStudentManagementJsonTextareaFieldErrors(field) {
      this.isReadFromCache = false;
      const localField = field;
      const errors = [];
      const fieldName = field.name;
      const fieldValue = field.value;

      if (typeof fieldValue !== 'undefined' && `${fieldValue}` !== '') {
        let parsedJson;

        try {
          parsedJson = JSON.parse(fieldValue);
        } catch (_err) {
          errors.push(`${field.label} needs to be a valid JSON`);
        }

        if (parsedJson) {
          const parsedJsonKeys = Object.keys(parsedJson);

          if (parsedJsonKeys.length <= 0) {
            errors.push(`${field.label} needs to be a non-empty JSON`);
          }
        }
      }

      const clonedStudentManagementErrorHash = JSON.parse(
        JSON.stringify(this.studentManagementErrorHash)
      );

      if (errors.length > 0) {
        clonedStudentManagementErrorHash[fieldName] = true;
      } else {
        delete clonedStudentManagementErrorHash[fieldName];
      }

      if (
        clonedStudentManagementErrorHash[fieldName] !== this.studentManagementErrorHash[fieldName]
      ) {
        // Reassign this variable to force recalculation of saveButtonDisabled
        this.studentManagementErrorHash = clonedStudentManagementErrorHash;
      }

      localField.errorMessage = errors;
      this.$forceUpdate();
    },
    autoPopulateVenderClientSid() {
      if (!this.vendorClientSid && this.clientSid) {
        this.vendorClientSid = this.clientSid;
      }
    },

    showShoolTypeWarning() {
      if (this.propClientSid !== '' && undefined !== this.propClientSid) {
        this.genericDialogDisplayed = true;
        this.isGenericDialogDisplayedAsError = true;
        this.genericDialogMessage = 'Doing school type changes may cause issues in PlanCheckGo!';
      }
    },
    addSchoolType() {
      let newSchoolTypeName = this.newSchoolType.name;
      const errors = [];
      this.newSchoolType.errorMessage = errors;
      this.$forceUpdate();
      if (!newSchoolTypeName || (newSchoolTypeName && newSchoolTypeName.trim().length === 0)) {
        errors.push('School Name is required');
        this.newSchoolType.errorMessage = errors;
        this.$forceUpdate();
      } else {
        newSchoolTypeName = newSchoolTypeName.trim();
        if (this.schoolTypes.some((s) => s.name === newSchoolTypeName)) {
          errors.push('School Name conflicted');
          this.newSchoolType.errorMessage = errors;
          this.$forceUpdate();
        }
      }

      if (errors.length === 0) {
        let addedId = 1;
        if (this.savedSchoolTypes.length > 0) {
          const savedSchoolType = this.savedSchoolTypes.find((s) => s.name === newSchoolTypeName);
          if (savedSchoolType) {
            addedId = savedSchoolType.id;
          }
        }

        if (this.schoolTypes.length > 0 && parseInt(addedId, 10) === 1) {
          const schoolTypeIds = this.schoolTypes.map((s) => parseInt(s.id, 10));
          schoolTypeIds.sort();
          addedId = schoolTypeIds[schoolTypeIds.length - 1] + 1;
        }

        this.schoolTypes.push({
          id: addedId,
          name: newSchoolTypeName
        });
        this.newSchoolType = {};
        this.showShoolTypeWarning();
      }
    },
    changeSchool(school) {
      const localSchool = school;
      let editName = school.name;
      const errors = [];
      localSchool.errorMessage = errors;
      this.$forceUpdate();
      if (!editName || (editName && editName.trim().length === 0)) {
        errors.push('School Name is required');
        localSchool.errorMessage = errors;
        this.$forceUpdate();
        this.schoolTypeValid = false;
      } else {
        editName = editName.trim();
        const conflictedSchool = this.schoolTypes.some(
          (s) => s.name === editName && s.id !== school.id
        );
        if (conflictedSchool) {
          errors.push('School Name conflicted');
          localSchool.errorMessage = errors;
          this.$forceUpdate();
          this.schoolTypeValid = false;
        }
      }

      if (errors.length === 0) {
        this.showShoolTypeWarning();
      }
    },
    deleteSchoolType(index) {
      this.schoolTypes.splice(index, 1);
      this.showShoolTypeWarning();
    },
    getDeleteSchoolButtunId(index) {
      return `btnDeleteSchoolButton${index}`;
    },
    async updateGroups(readFromCache) {
      this.clearSearchInput();
      const vendor = this.studentManagementVendors.find(
        (v) => v.sid === this.activeStudentManagementVendorSid
      );
      const studentVendorParams = {
        'module-name': vendor.module_name,
        readFromCache
      };
      this.studentManagementClientApiFields.forEach((clientApiField) => {
        if (clientApiField.groupListQueryFieldFlag) {
          if (clientApiField.type === 'checkbox') {
            if (clientApiField.value) {
              studentVendorParams[clientApiField.name] = true;
            }
          } else {
            studentVendorParams[clientApiField.name] = clientApiField.value;
          }
        }
      });

      this.groupsLoading = true;
      const argHash = {
        studentVendorParams,
        store: this.$store
      };
      const cbResponse = await this.$app.stbApiAdapter.getClientStudentGroupList(argHash);

      const apiAuthListField = this.studentManagementClientApiFields.filter(
        (item) => item.label === 'API Auth List'
      )[0];
      this.groupsLoading = false;

      if (!cbResponse.error) {
        this.isReadFromCache = true;
        const remoteGroupList = cbResponse.data;
        const existingGroupList = JSON.parse(JSON.stringify(this.groupList));
        const existingGroupManagement = {
          ...this.studentManagement?.clientApiMetadata?.groupManagement
        };

        this.studentManagementGroupSyncDiffs = studentManagement.getGroupSyncDiffs(
          existingGroupManagement,
          remoteGroupList
        );

        this.groupList = remoteGroupList.map((remoteGroup) => {
          const existingGroup = existingGroupList.find((s) => `${s.id}` === `${remoteGroup.id}`);

          if (existingGroup && existingGroup.group !== remoteGroup.name) {
            existingGroup.group = remoteGroup.name;
          }

          const resolvedGroup = existingGroup
            ? JSON.parse(JSON.stringify(existingGroup))
            : {
                id: remoteGroup.id,
                group: remoteGroup.name
              };

          this.clientStudentGroupSubsetAttributeKeys.forEach((subsetAttributeKey) => {
            const subsetAttributeValue = remoteGroup[subsetAttributeKey];

            if (typeof subsetAttributeValue !== 'undefined') {
              resolvedGroup[subsetAttributeKey] = subsetAttributeValue;
            }
          });
          return resolvedGroup;
        });

        this.originalGroupList = this.$app.lodash.cloneDeep(this.groupList);
      } else if (errorCaseSid.VENDOR_TOKEN_KEY_INVALID === `${cbResponse.errorCaseSid}`) {
        const apiEndpointField = this.studentManagementClientApiFields.filter(
          (item) => item.label === 'Token Key'
        )[0];
        const errors = [];
        errors.push(`Invalid Token Key`);
        if (this.studentDetailsApiGroupOnlyEnabled) {
          apiAuthListField.errorMessage = errors;
        } else {
          apiEndpointField.errorMessage = errors;
        }
        document.getElementsByClassName('clientEditForm')[0].scrollTop = 200;
        this.$forceUpdate();
      } else if (errorCaseSid.VENDOR_API_CREDENTIALS_INVALID === `${cbResponse.errorCaseSid}`) {
        const tokenKeyField = this.studentManagementClientApiFields.filter(
          (item) => item.label === 'Token Key'
        )[0];
        const errors = [];
        errors.push(this.invalidCredentials);
        if (this.studentDetailsApiGroupOnlyEnabled) {
          apiAuthListField.errorMessage = errors;
        } else {
          tokenKeyField.errorMessage = errors;
        }
        document.getElementsByClassName('clientEditForm')[0].scrollTop = 200;
        this.$forceUpdate();
      } else if (errorCaseSid.VENDOR_API_KEY_INVALID === `${cbResponse.errorCaseSid}`) {
        const apiKeyField = this.studentManagementClientApiFields.filter(
          (item) => item.label === 'API Key'
        )[0];
        const errors = [];
        errors.push(`Invalid API key`);
        apiKeyField.errorMessage = errors;
        this.$forceUpdate();
      } else if (errorCaseSid.VENDOR_ORGANIZATION_ID_INVALID === `${cbResponse.errorCaseSid}`) {
        const organizationIdField = this.studentManagementClientApiFields.filter(
          (item) => item.label === 'Organization ID'
        )[0];
        const errors = [];
        errors.push(`Invalid Organization ID`);
        organizationIdField.errorMessage = errors;
        this.$forceUpdate();
      } else if (errorCaseSid.VENDOR_API_ENDPOINT_INVALID === `${cbResponse.errorCaseSid}`) {
        const apiEndpointField = this.studentManagementClientApiFields.filter(
          (item) => item.label === 'API Endpoint'
        )[0];
        const errors = [];
        errors.push(`Invalid API Endpoint`);
        if (this.studentDetailsApiGroupOnlyEnabled) {
          apiAuthListField.errorMessage = errors;
        } else {
          apiEndpointField.errorMessage = errors;
        }
        document.getElementsByClassName('clientEditForm')[0].scrollTop = 0;
        this.$forceUpdate();
      } else {
        this.$store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);

        this.genericDialogDisplayed = true;
        this.isGenericDialogDisplayedAsError = true;
        this.genericDialogMessage = cbResponse.message.replace(
          /: ses/,
          '<br/><br/>Sentinel Event ID: ses'
        );
      }
    },
    parentSelected(item) {
      const localItem = item;
      localItem.parentSelectionDisplayed = false;
      this.$forceUpdate();
    },
    getParentGroupName(parentId) {
      const parentGroup = this.originalGroupList.find((g) => `${g.id}` === `${parentId}`);
      return parentGroup ? parentGroup.group : '';
    },
    getParentGroupId(parentId) {
      const parentGroup = this.originalGroupList.find((g) => `${g.id}` === `${parentId}`);
      return parentGroup ? parentGroup.id : '';
    },
    displayParentSelection(item) {
      /* eslint-disable no-param-reassign */
      this.groupList.forEach((g) => {
        g.parentSelectionDisplayed = false;
        if (g.id === item.id) {
          g.parentSelectionDisplayed = true;
        }
      });
      /* eslint-enable no-param-reassign */
      this.groupList = this.$app.lodash.cloneDeep(this.groupList);
      this.$forceUpdate();
    },
    applyButtonDisabled() {
      return !this.selectedGroupItems || this.selectedGroupItems.length === 0;
    },
    applySelectedOption() {
      const { selectedApplyParentId } = this;
      if (this.selectedGroupItems && this.selectedGroupItems.length > 0) {
        this.selectedGroupItems.forEach((group) => {
          const localGroup = group;
          if (this.applySubjectEnabled) {
            localGroup.subjectGroup = true;
          }
          if (this.applyCustomEnabled) {
            localGroup.customGroup = true;
          }
          localGroup.parent = selectedApplyParentId;
        });
      }
      this.selectedGroupItems = [];
      this.selectedApplyParentId = 0;
      this.applySubjectEnabled = false;
      this.applyCustomEnabled = false;
      this.$forceUpdate();
    },
    getUploadFileId(category, index) {
      return `uploadFile__${category}__${index}`;
    },
    getUploadFileBtnId(category, index) {
      return `uploadFileBtn__${category}__${index}`;
    },
    getDownloadFileBtnId(category, index) {
      return `downloadFileBtn__${category}__${index}`;
    },
    uploadFileClick(category, index) {
      document.getElementById(`uploadFile__${category}__${index}`).click();
    },
    getUploadDate(uploadDate) {
      let timezoneDate = '';
      if (uploadDate) {
        const localDate = this.$app.moment(uploadDate);
        timezoneDate = this.$app
          .moment(localDate)
          .utc()
          .tz(this.$store.state.common.clientTimezone)
          .format('DD/MM/YYYY, hh:mm:ss A');
      }
      return timezoneDate;
    },
    handleTemplateFileChange(e, proformaTemplateFile) {
      if (e && e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        const filename = file.name;
        const fileType = file.type;
        const extensionType = filename.split('.').pop();

        if (
          this.proformaTemplateFileExtensions.indexOf(extensionType) < 0 &&
          !this.proformaTemplateFileMimeTypeAllowed.includes(fileType)
        ) {
          this.invalidFileTypeDialogDisplayed = true;
          this.uploadFilename = filename;
          e.target.value = '';
          this.invalidFileTypeAdditionalMessage = '';
          return;
        }

        this.$set(proformaTemplateFile, 'file', file);
        this.$set(proformaTemplateFile, 'filename', file.name);
        this.$set(proformaTemplateFile, 'fileUpdateDate', '');
        this.$forceUpdate();
        this.templateFileChanged = true;
      }
    },
    handleStudentManagementFileChange(e, studentManagementFile) {
      if (e && e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
        const filename = file.name;
        const fileType = file.type;
        const extensionType = filename.split('.').pop();

        if (
          this.studentManagementFileExtensions.indexOf(extensionType) < 0 &&
          !this.studentManagementFileMimeTypeAllowed.includes(fileType)
        ) {
          this.invalidFileTypeDialogDisplayed = true;
          this.invalidFileTypeAdditionalMessage = `Please select a <span class="debug-error-message">.csv</span> file.`;
          this.uploadFilename = filename;
          e.target.value = '';
          return;
        }

        this.$set(studentManagementFile, 'file', file);
        this.$set(studentManagementFile, 'filename', file.name);
        this.$forceUpdate();
        this.studentManagementFileChanged = true;
      }
    },
    async downloadFile(proformaTemplateFile) {
      const apiQuery = JSON.parse(JSON.stringify(this.$store.state.common.apiParam));

      const argHash = {
        queryParam: apiQuery,
        sid: proformaTemplateFile.sid,
        store: this.$store
      };
      const response = await this.$app.stbApiAdapter.getAttachments(argHash);
      if (response.data) {
        const attachments = response.data;
        if (attachments.length > 0) {
          const file = attachments[0];
          if (file && file.computed && file.metadata) {
            const filePath = file.computed.url.replace(/^\//, '');
            const apiUrl = this.$app.env.API_URL.replace(/\/$/, '');
            const fileUrl = `${apiUrl}/${filePath}`;
            const { filename } = file.metadata;
            const excursionCommonAdapter = new this.$app.excursionCommon.Adapter({
              store: this.$store,
              app: this.$app
            });
            excursionCommonAdapter.downloadFile(fileUrl, filename, this.$app.deviceDetector);
          }
        }
      }
    },
    async uploadClientTemplateFiles() {
      if (this.clientTemplateFiles && this.clientTemplateFiles.length > 0) {
        const fd = new FormData();
        fd.append('category', attachmentCategory.PROFORMA_TEMPLATE);
        const addTemplateFilesList = this.clientTemplateFiles.filter(
          (a) => typeof a.file !== 'undefined'
        );
        if (addTemplateFilesList && addTemplateFilesList.length > 0) {
          for (let i = 0; i < addTemplateFilesList.length; i += 1) {
            const proformaTemplateFile = addTemplateFilesList[i];
            fd.append('file', proformaTemplateFile.file);
          }
          const argHash = {
            sid: this.clientSid,
            payload: fd,
            store: this.$store
          };
          await this.$app.stbApiAdapter.postClientAttachmentCategories(argHash);
        }
      }
    },
    async uploadStudentManagementFiles() {
      let clientApiMetadata = {};
      if (this.studentManagementFiles && this.studentManagementFiles.length > 0) {
        const fd = new FormData();
        fd.append('category', attachmentCategory.STUDENT_MANAGEMENT);
        const addStudentManagementFilesList = this.studentManagementFiles.filter(
          (a) => typeof a.file !== 'undefined'
        );
        if (addStudentManagementFilesList && addStudentManagementFilesList.length > 0) {
          for (let i = 0; i < addStudentManagementFilesList.length; i += 1) {
            const studentManagementFile = addStudentManagementFilesList[i];
            fd.append('file', studentManagementFile.file);
          }
          const argHash = {
            payload: fd,
            store: this.$store
          };
          clientApiMetadata = await this.$app.stbApiAdapter.postClientSubjectImportPreviews(
            argHash
          );
        }
      }
      return clientApiMetadata;
    },
    changeProviderSourceType() {
      if (providerSourceType.SFTP === this.provider.sourceType) {
        delete this.provider.apiBaseUrl;
        delete this.provider.apiKey;
        delete this.provider.apiTenantSid;
        delete this.provider.apiReportSid;
      } else if (providerSourceType.API === this.provider.sourceType) {
        delete this.provider.sftpPath;
      } else {
        delete this.provider.sourceType;
        delete this.provider.apiBaseUrl;
        delete this.provider.apiKey;
        delete this.provider.apiTenantSid;
        delete this.provider.apiReportSid;
        delete this.provider.sftpPath;
      }
    },
    displaySftp() {
      return providerSourceType.SFTP === this.provider.sourceType;
    },
    displayApi() {
      return providerSourceType.API === this.provider.sourceType;
    },
    clickCheckbox(props) {
      if (props.isSelected) {
        this.selectedGroupItems.push(props.item);
      } else {
        this.selectedGroupItems = this.selectedGroupItems.filter(
          (item) => item.id !== props.item.id
        );
      }
    },
    getStudentGroupFilterData(paginationOptions) {
      const parsedSection = this.getSectionNameByIndex(this.activeSectionIndex);
      const searchQuery = {
        section: parsedSection,
        page: paginationOptions.page,
        length: paginationOptions.itemsPerPage
      };
      if (paginationOptions.sortBy.length !== 0 && paginationOptions.sortBy[0] !== '') {
        searchQuery.order = this.tableColumnRefToQuerystringRefMap[paginationOptions.sortBy[0]];
        if (paginationOptions.sortDesc.length !== 0) {
          searchQuery.direction = paginationOptions.sortDesc[0] ? 'DESC' : 'ASC';
        }
      }
      if (clientIndividualEditorSection.STUDENT_MANAGEMENT === parsedSection) {
        if (this.groupList.length === 0) {
          delete searchQuery.page;
          delete searchQuery.length;
          delete searchQuery.direction;
          delete searchQuery.order;
        }
        if (this.searchTerm && String(this.searchTerm).trim().length !== 0) {
          searchQuery.s = String(this.searchTerm).trim().toLocaleLowerCase();
        }
        this.$router.replace({ query: searchQuery }, () => {});
      }
    },
    resetPage() {
      this.studentGroupPaginationInfo.itemsPerPage = 30;
      this.studentGroupPaginationInfo.page = 1;
      this.studentGroupPaginationInfo.sortDesc[0] = true;
    },
    getSectionNameByIndex(index) {
      switch (index) {
        case 0:
          return clientIndividualEditorSection.GENERAL;
        case 1:
          return clientIndividualEditorSection.GROUP;
        case 2:
          return clientIndividualEditorSection.EXCURSION_MANAGEMENT;
        case 3:
          return clientIndividualEditorSection.PROVIDERS;
        case 4:
          return clientIndividualEditorSection.CONTENT_ENGINE;
        case 5:
          return clientIndividualEditorSection.STUDENT_MANAGEMENT;
        case 6:
          return clientIndividualEditorSection.TEMPLATES;
        default:
          return clientIndividualEditorSection.GENERAL;
      }
    },
    updateQueryString(query) {
      if (query) {
        this.$router.replace({ query }, () => {});
        return;
      }
      const searchQuery = {};
      searchQuery.section = clientIndividualEditorSection.GENERAL;
      if (Object.keys(this.$route.query).length !== 0) {
        const {
          section: parsedSection,
          page: parsedPage,
          length: parsedLength,
          direction: parsedDirection,
          order: parsedSortBy,
          s: parsedSearchGroup
        } = this.$route.query;

        if (parsedSection && String(parsedSection).trim().length !== 0) {
          switch (parsedSection) {
            case clientIndividualEditorSection.GENERAL:
              this.activeSectionIndex = 0;
              break;
            case clientIndividualEditorSection.GROUP:
              this.activeSectionIndex = 1;
              searchQuery.section = clientIndividualEditorSection.GROUP;
              break;
            case clientIndividualEditorSection.EXCURSION_MANAGEMENT:
              searchQuery.section = clientIndividualEditorSection.EXCURSION_MANAGEMENT;
              this.activeSectionIndex = 2;
              break;
            case clientIndividualEditorSection.PROVIDERS:
              searchQuery.section = clientIndividualEditorSection.PROVIDERS;
              this.activeSectionIndex = 3;
              break;
            case clientIndividualEditorSection.CONTENT_ENGINE:
              searchQuery.section = clientIndividualEditorSection.CONTENT_ENGINE;
              this.activeSectionIndex = 4;
              break;
            case clientIndividualEditorSection.STUDENT_MANAGEMENT:
              searchQuery.section = clientIndividualEditorSection.STUDENT_MANAGEMENT;
              this.activeSectionIndex = 5;
              if (parsedLength === '10' || parsedLength === '30' || parsedLength === '50') {
                this.studentGroupPaginationInfo.itemsPerPage = parseInt(parsedLength, 10);
                searchQuery.length = parseInt(parsedLength, 10);
              } else {
                this.studentGroupPaginationInfo.itemsPerPage = 30;
                searchQuery.length = 30;
              }

              if (!Number.isNaN(parseInt(parsedPage, 10)) && parseInt(parsedPage, 10) > 0) {
                this.studentGroupPaginationInfo.page = parseInt(parsedPage, 10);
                searchQuery.page = parseInt(parsedPage, 10);
              }

              if (
                studentGroupTableColumn.GROUP.querystringRef === parsedSortBy ||
                studentGroupTableColumn.SCHOOL_TYPE.querystringRef === parsedSortBy ||
                studentGroupTableColumn.PARENT.querystringRef === parsedSortBy ||
                studentGroupTableColumn.SUBJECT_GROUP.querystringRef === parsedSortBy ||
                studentGroupTableColumn.CUSTOM_GROUP.querystringRef === parsedSortBy
              ) {
                this.studentGroupPaginationInfo.sortBy[0] =
                  this.querystringRefToTableColumnRefMap[parsedSortBy];
                searchQuery.order = parsedSortBy;
                if (parsedDirection === 'DESC' || parsedDirection === 'ASC') {
                  this.studentGroupPaginationInfo.sortDesc[0] = parsedDirection === 'DESC';
                  searchQuery.direction = parsedDirection;
                } else {
                  searchQuery.direction = 'ASC';
                }
              }

              if (parsedSearchGroup && String(parsedSearchGroup).trim().length !== 0) {
                this.searchTerm = String(parsedSearchGroup).trim().toLowerCase();
                searchQuery.s = this.searchTerm;
                this.groupList = this.originalGroupList.filter((item) =>
                  String(item.group).toLowerCase().includes(this.searchTerm)
                );
                this.groupList = this.$app.lodash.cloneDeep(this.groupList);
                this.$forceUpdate();
              }
              break;
            case clientIndividualEditorSection.TEMPLATES:
              searchQuery.section = clientIndividualEditorSection.TEMPLATES;
              this.activeSectionIndex = 6;
              break;
            default:
              this.activeSectionIndex = 0;
          }
        }
      }

      this.$router.replace({ query: searchQuery }, () => {});
    },

    filterGroups() {
      this.resetStudentGroupPaginationInfo();
      const { query } = this.$route;
      const searchQuery = this.$app.lodash.cloneDeep(query);
      const parsedSearchTerm = String(this.searchTerm).trim().toLowerCase();
      if (this.searchTerm && parsedSearchTerm.length !== 0) {
        searchQuery.s = parsedSearchTerm;
        this.groupList = this.originalGroupList.filter((item) =>
          String(item.group).toLowerCase().includes(parsedSearchTerm)
        );
        this.groupList = this.$app.lodash.cloneDeep(this.groupList);
        this.$forceUpdate();
        this.updateQueryString(searchQuery);
      } else {
        this.clearSearchInput();
      }
    },
    clearSearchInput() {
      this.searchTerm = '';
      this.groupList = this.$app.lodash.cloneDeep(this.originalGroupList);
      this.$forceUpdate();
    },
    resetStudentGroupPaginationInfo() {
      if (this.studentGroupPaginationInfo.itemsPerPage !== 30) {
        this.studentGroupPaginationInfo.itemsPerPage = 30;
      }
      if (this.studentGroupPaginationInfo.page !== 1) {
        this.studentGroupPaginationInfo.page = 1;
      }
      if (this.studentGroupPaginationInfo.sortDesc[0]) {
        this.studentGroupPaginationInfo.sortDesc = [false];
      }
      if (this.studentGroupPaginationInfo.sortBy[0]) {
        this.studentGroupPaginationInfo.sortBy = [''];
      }
    },
    async studentManagementTestConnection() {
      const currentVendor = this.studentManagementVendors.find(
        (vendor) => vendor.sid === this.activeStudentManagementVendorSid
      );

      const studentVendorParams = {
        studenVendor: currentVendor.module_name
      };

      this.studentManagementClientApiFields.forEach((apiField) => {
        const apiFieldValue = (() => {
          if (apiField.type === 'checkbox') {
            if (apiField.value) {
              return true;
            }

            return false;
          }

          return typeof apiField.value !== 'undefined' ? apiField.value : '';
        })();

        studentVendorParams[apiField.name] = apiFieldValue;
      });

      const result = await this.$app.stbApiAdapter.getClientStudentTestConnection(
        studentVendorParams.studenVendor,
        studentVendorParams.apiEndpoint,
        studentVendorParams.tokenKey,
        studentVendorParams.schoolId
      );

      return result;
    },
    async onClickStudentManagementTestConnection() {
      this.studentManagementTestConnectionInProgress = true;

      const result = await this.studentManagementTestConnection();

      this.$store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);
      this.studentManagementTestConnectionInProgress = false;
      this.genericDialogDisplayed = true;

      if (!result.error) {
        this.studentManagementTestConnectionAt = result.data.connectionTestedAt;
        this.studentManagementTestConnectionFailedStatus = '';
        this.isGenericDialogDisplayedAsError = false;
        this.genericDialogMessage = [
          `Successfully connect with student management vendor for school "${result.data.schoolName}".`,
          '<br/><br/>',
          'Please click "Save" to save the current settings.'
        ].join('');
      } else {
        this.studentManagementTestConnectionAt = this.$app.moment().toISOString();
        this.studentManagementTestConnectionFailedStatus = result.message;
        this.isGenericDialogDisplayedAsError = true;
        this.genericDialogMessage = result.message;
      }
    },
    async studentManagementTestConnectionBeforeSave() {
      if (!this.studentManagementTestConnectionRequired) {
        this.save();
        return;
      }

      this.confirmTestConnectionFailedDialogDisplayed = false;
      this.confirmSaveDialogDisplayed = false;
      this.vendorClientSidConflicted = false;
      this.subdomainConflicted = false;
      this.ceParamErrorDisplayed = false;
      this.saveActionErrorMessage = '';
      // this.cropButtonDisabled = true;
      this.clientSaveActionInProgress = true;

      const result = await this.studentManagementTestConnection();

      this.$store.commit(types.COMMON_SET_IS_RESPONSE_WITH_ERROR, false);

      if (!result.error) {
        this.studentManagementTestConnectionAt = result.data.connectionTestedAt;
        this.studentManagementTestConnectionFailedStatus = '';
        this.save();
      } else {
        this.studentManagementTestConnectionAt = this.$app.moment().toISOString();
        this.studentManagementTestConnectionFailedStatus = result.message;
        this.confirmTestConnectionFailedDialogDisplayed = true;
        this.clientSaveActionInProgress = false;
      }
    },
    onClientGeneralChanged(clientGeneralData) {
      if (!clientGeneralData) {
        return;
      }
      const { clientName, subdomain, idpUrl, timezone } = clientGeneralData;
      this.clientName = clientName;
      this.timezone = timezone;
      this.idpUrl = idpUrl;
      this.subdomain = subdomain;
    },
    onClientLogoChanged(clientLogo) {
      if (!clientLogo) {
        return;
      }
      const { clientLogoBackgroundColor } = clientLogo;
      this.clientLogoBackgroundColor = clientLogoBackgroundColor;
    },
    onClientOptionsChanged(clientOptions) {
      const {
        excursionAdditionalInformationEnabled,
        auth0LoginEnabled,
        excursionCopyActionEnabled,
        excursionChecklistEnforcementBeforeSubmissionEnabled,
        excursionHighRiskStudentMedicalEnabled,
        excursionPersonResponsibleEnabled,
        excursionPolicyViewActionEnabled,
        excursionInherentRiskRatingEnabled,
        idpRbaclEnabled,
        publicSchoolFeatureSetEnabled
      } = clientOptions;

      this.auth0LoginEnabled = auth0LoginEnabled;
      this.excursionAdditionalInformationEnabled = excursionAdditionalInformationEnabled;
      this.excursionChecklistEnforcementBeforeSubmissionEnabled =
        excursionChecklistEnforcementBeforeSubmissionEnabled;
      this.excursionCopyActionEnabled = excursionCopyActionEnabled;

      this.excursionHighRiskStudentMedicalEnabled = excursionHighRiskStudentMedicalEnabled;
      this.excursionPersonResponsibleEnabled = excursionPersonResponsibleEnabled;

      this.excursionPolicyViewActionEnabled = excursionPolicyViewActionEnabled;
      this.excursionInherentRiskRatingEnabled = excursionInherentRiskRatingEnabled;
      this.idpRbaclEnabled = idpRbaclEnabled;
      this.publicSchoolFeatureSetEnabled = publicSchoolFeatureSetEnabled;
    },
    onClientGeneralSaved() {
      // go to Excursion Management section
      this.activeSectionIndex = 2;
    },
    onClientContentEngineChanged(contentEngine) {
      const { ceApiUri, xSiteUri, moduleName } = contentEngine;
      this.contentEngine = { ceApiUri, xSiteUri, moduleName };
    }
  },
  async mounted() {
    const { params } = this.$route;
    if (params && params.id) {
      this.propClientSid = params.id;
      this.propType = 'update';
    }

    Object.keys(studentGroupTableColumn).forEach((key) => {
      this.querystringRefToTableColumnRefMap[studentGroupTableColumn[key].querystringRef] =
        studentGroupTableColumn[key].tableColumnRef;
      this.tableColumnRefToQuerystringRefMap[studentGroupTableColumn[key].tableColumnRef] =
        studentGroupTableColumn[key].querystringRef;
    });

    this.logoFileChanged = false;
    this.templateFileChanged = false;
    this.studentManagementFileChanged = false;
    const { axiosInstance, eventPluginCommonAdapter, stbApiAdapter } = this.$app;
    await this.$store.dispatch('authorisation/validateAndSetUserTokenOrLogout', {
      query: this.$route.query,
      axiosInstance
    });
    await this.$store.dispatch('common/initClientConfig', {
      eventPluginCommonAdapter,
      stbApiAdapter
    });
    this.excursionManagementVendors =
      await this.$app.stbApiAdapter.getExcursionManagementVendorList({ store: this.$store });

    const cbResponse = await this.$app.stbApiAdapter.getStudentManagementVendorList({
      store: this.$store
    });
    this.studentManagementVendors = cbResponse;
    this.logoFileChanged = false;

    this.clientSid = this.propClientSid;

    await this.newClient(this.propClientSid);
    this.clientIndividualModalDisplayed = true;

    this.updateQueryString();
    this.hideSelected = true;
  },
  watch: {
    async clientIndividualModalRefreshed() {
      this.logoFileChanged = false;
      this.templateFileChanged = false;
      this.studentManagementFileChanged = false;
      this.clientSid = this.propClientSid;
      await this.newClient(this.propClientSid);
      this.clientIndividualModalDisplayed = true;
    },
    searchTerm() {
      this.resetStudentGroupPaginationInfo();
    },
    async searchParentClients(val) {
      let cacheItem;

      if (this.parentClientCache && this.parentClientCache.length > 0) {
        cacheItem = this.parentClientCache.find((item) => {
          return item.key === val;
        });
      }

      if (cacheItem) {
        this.$store.commit(types.COMMON_SET_PARENT_CLIENT_ITEMS, cacheItem.items);
      } else {
        this.$store.commit(types.COMMON_SET_PARENT_CLIENT_LIST_LOADING, true);
        const filterParam = {
          s: val,
          page: this.pagination.page,
          direction: this.pagination.sortDesc[0] === false ? 'ASC' : 'DESC'
        };

        Object.keys(filterParam).forEach((key) => {
          if (filterParam[key] === '') {
            delete filterParam[key];
          }
        });
        const argHash = {
          stbApiAdapter: this.$app.stbApiAdapter,
          filterParam,
          store: this.$store
        };
        await this.$store.dispatch('common/getParentClientList', argHash);
      }
    },

    studentGroupPaginationInfo: {
      handler(val) {
        this.getStudentGroupFilterData(val);
      },
      deep: true
    },
    '$route.query': {
      handler(val) {
        this.updateQueryString(val);
      },
      deep: true,
      immediate: true
    },
    activeSectionIndex(val) {
      const searchQuery = {};
      searchQuery.section = this.getSectionNameByIndex(val);
      this.clearSearchInput();
      if (searchQuery.section === clientIndividualEditorSection.STUDENT_MANAGEMENT) {
        searchQuery.length = this.studentGroupPaginationInfo.itemsPerPage;
        searchQuery.page = this.studentGroupPaginationInfo.page;
        searchQuery.direction = this.studentGroupPaginationInfo.sortDesc[0] ? 'DESC' : 'ASC';
      }
      if (
        this.studentGroupPaginationInfo.sortBy.length !== 0 &&
        this.studentGroupPaginationInfo.sortBy[0] !== ''
      ) {
        searchQuery.order =
          this.tableColumnRefToQuerystringRefMap[this.studentGroupPaginationInfo.sortBy[0]];
        if (this.studentGroupPaginationInfo.sortDesc.length !== 0) {
          searchQuery.direction = this.studentGroupPaginationInfo.sortDesc[0] ? 'DESC' : 'ASC';
        }
      }
      if (
        this.groupList.length === 0 ||
        searchQuery.section !== clientIndividualEditorSection.STUDENT_MANAGEMENT
      ) {
        delete searchQuery.page;
        delete searchQuery.length;
        delete searchQuery.direction;
        delete searchQuery.order;
        this.resetPage();
      }
      this.$router.replace({ query: searchQuery }, () => {});
    }
  }
};
</script>
<style scoped>
#btnClientIndividualEditorSearch {
  margin-bottom: 30px;
  margin-left: 30px;
}

#student-management-search-input {
  width: 300px;
  padding-left: 4px;
}

.client-edit-form {
  min-height: 70vh;
}

.buildIndexContainer {
  display: flex;
  align-items: center;
  color: green;
}

.noIndexBuild {
  color: red;
}

.student-management-group-search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 100px;
}

.studentGroupId {
  color: gray;
}

#testConnectionContainer {
  margin-bottom: 20px;
}

.generic-error-message {
  color: var(--v-error-base);
}
</style>
