<template>
  <div class="content">
    <page-loading-animation :displayed="pageLoadingAnimationDisplayed()"></page-loading-animation>
    <cs-form-dialog
      id="dlg-student-medical-conditions-loading"
      bodyId="dlg-body-student-medical-conditions-loading"
      heading="Retrieving Student Medical Conditions"
      v-model="studentMedicalConditionsLoadingAnimationDisplayed"
    >
      <template v-slot:cs-form-dialog-content>
        <v-card>
          <v-card-text>
            <div>
              Standby whilst we retrieve student medical data from your student information system.
              <br />This may take some time as we need to retrieve data for each individual student
              selected. <br />Please report any errors to our support team.
            </div>
            <div style="margin-top: 20px; text-align: center">
              <v-progress-circular
                :size="70"
                color="rgb(29, 175, 236)"
                class="loading-img"
                indeterminate
              ></v-progress-circular>
            </div>
          </v-card-text>
        </v-card>
      </template>
    </cs-form-dialog>
    <div class="excursion-stepper" v-show="!pageLoadingAnimationDisplayed()">
      <v-layout
        v-show="clientLogoMarginDisplayed()"
        :class="vendorClientLogoEnabled ? 'mt-10' : 'mt-6'"
      >
      </v-layout>
      <v-layout xm xs v-show="vendorClientLogoEnabled && isAuthenticated">
        <div class="client-logo">
          <img
            id="clientLogo"
            :style="{ background: clientLogoBackgroundColor }"
            alt="client logo"
          />
        </div>
      </v-layout>
      <form class="mt-10" v-if="showError || !isAuthenticated">
        <v-layout class="mb-20">
          <v-img max-width="280px" :src="stbHorizontalLogo"></v-img>
        </v-layout>
        <v-layout class="mt-2 mb-3">
          <v-flex>
            You are not authorized to view SafeTrip Builder, please provide a correct auth token.
          </v-flex>
        </v-layout>
      </form>
      <form
        for="form"
        id="form"
        :class="vendorClientLogoEnabled ? 'mt-10' : ''"
        v-if="!showError && isAuthenticated"
      >
        <v-stepper v-model="step" vertical class="stepper-padding-bottom">
          <v-layout>
            <v-flex></v-flex>
          </v-layout>
          <v-layout class="safe-trip-title" v-show="stbLogoDisplayed">
            <v-img id="clientStepLogo" max-width="280px" :src="stbHorizontalLogo"> </v-img>
          </v-layout>
          <v-layout class="safe-trip-title-remark">
            <v-flex class="excursion-page-title-heading">
              <i class="fas fa-info-circle"></i>
              Fill in your excursion details and complete checklists to produce your excursion risk
              assessment
            </v-flex>
          </v-layout>
          <!-- step 1: Excursion Details-->
          <v-stepper-step :complete="step > 1" step="1">
            <div
              id="excursion-stepper--step-header-excursion-details--label"
              @click="turnToStep($v.$invalid, highRiskStudentMedicalStep - 1)"
              :class="this.step >= 1 ? 'safe-trip-step-header' : ''"
            >
              {{ excursionStepperHeadingLabel.EXCURSION_DETAILS }}
            </div>
          </v-stepper-step>
          <v-stepper-content step="1" id="step1">
            <v-layout wrap v-if="vendorExcursionSidInputEnabled">
              <v-flex xs12 class="form-label safe-trip-font">
                Excursion Manager ID
                <span class="red--text">&ast;</span>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  id="excursion-stepper--excursion-manager-id"
                  solo
                  placeholder="Excursion Manager ID"
                  class="name-textbox excursion-input safe-excursion-detail-text"
                  v-model="vendorExcursionSid"
                  :error-messages="vendorExcursionSidErrors"
                  @input="$v.vendorExcursionSid.$touch()"
                  @blur="$v.vendorExcursionSid.$touch()"
                  required
                  :disabled="isExcursionInitiatedFromPcg"
                />
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 class="form-label safe-trip-font">
                Excursion Name
                <span class="red--text">&ast;</span>
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  id="excursion-stepper--excursion-name"
                  solo
                  placeholder="Name"
                  class="name-textbox safe-trip-font excursion-input safe-excursion-detail-text"
                  v-model="name"
                  :error-messages="nameErrors"
                  required
                  :disabled="isExcursionInitiatedFromPcg"
                  @input="$v.name.$touch()"
                  @blur="$v.name.$touch()"
                />
              </v-flex>
            </v-layout>
            <v-layout wrap>
              <v-flex xs12 class="form-label safe-trip-font">
                Excursion Type
                <span class="red--text">&ast;</span>
                <v-tooltip right color="#ffffff" max-width="650px">
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                      id="excursion-stepper--excursion-type-tooltip--button"
                      v-on="{ ...tooltip }"
                      class="provider-tooltip-button"
                      icon
                    >
                      <i class="fas fa-info-circle"></i>
                    </v-btn>
                  </template>
                  <div class="type-tooltip-content">
                    <div
                      v-for="(excursionTypeInfoEntry, index) in excursionTypeInfoEntryList"
                      :key="index"
                    >
                      <!-- eslint-disable-next-line max-len -->
                      <b>{{ excursionTypeInfoEntry.label }}</b> -
                      {{ excursionTypeInfoEntry.description }}
                    </div>
                  </div>
                </v-tooltip>
              </v-flex>
              <v-flex xs12 d-flex>
                <v-select
                  id="excursion-stepper--excursion-type"
                  class="type-dropdown safe-trip-font excursion-selection safe-excursion-detail-text"
                  v-model="excursionType"
                  :items="excursionTypeChoiceList"
                  item-text="name"
                  item-value="id"
                  solo
                  :label="'Choose an Excursion Type'"
                  :error-messages="selectErrors"
                  required
                  @blur="$v.excursionType.$touch()"
                  @change="handleExcursionTypeSelectionChange()"
                  :menu-props="{
                    closeOnClick: false,
                    closeOnContentClick: false,
                    openOnClick: false,
                    maxHeight: 400
                  }"
                  :disabled="!formControlEnabled || isExcursionInitiatedFromPcg"
                  :loading="excursionTypeLoaded"
                >
                  <template slot="item" slot-scope="data">
                    <div
                      :id="`excursion-stepper--excursion-type--option-${String(
                        data.item
                      ).replaceAll(' ', '')}`"
                    >
                      {{ data.item }}
                    </div>
                  </template>
                </v-select>
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="showJurisdiction">
              <v-flex xs12 class="form-label safe-trip-font">
                Excursion Destination
                <span class="red--text">&ast;</span>
                <v-menu open-on-hover right offset-x max-width="650px">
                  <template v-slot:activator="{ on: menu }">
                    <v-btn
                      id="excursion-stepper--excursion-destination-tooltip--button"
                      v-on="{ ...menu }"
                      class="provider-tooltip-button"
                      icon
                    >
                      <i class="fas fa-info-circle"></i>
                    </v-btn>
                  </template>
                  <v-list class="jurisdiction-tooltip-content">
                    <v-list-item>
                      <p class="py-2 mb-0">
                        {{ jurisdictionTooltip }}
                        Click
                        <a
                          id="excursion-stepper--excursion-juristiction-tooltip--link"
                          href="https://complispace-jira.atlassian.net/wiki/spaces/CS/pages/448056/STB+Excursion+Details"
                          target="_blank"
                        >
                          here
                        </a>
                        to learn more.
                      </p>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-flex>
              <v-flex xs12 d-flex>
                <v-select
                  id="excursion-stepper--excursion-jurisdiction"
                  class="type-dropdown safe-trip-font excursion-selection safe-excursion-detail-text"
                  v-model="jurisdiction"
                  :items="jurisdictions"
                  solo
                  :label="'Choose an Excursion State'"
                  :error-messages="selectErrors"
                  required
                  :menu-props="{
                    closeOnClick: false,
                    closeOnContentClick: false,
                    openOnClick: false,
                    maxHeight: 400
                  }"
                  :disabled="!formControlEnabled"
                  :loading="excursionJurisdictionLoaded"
                >
                  <template slot="item" slot-scope="data">
                    <div
                      :id="`excursion-stepper--excursion-destination--option-${String(
                        data.item
                      ).replaceAll(' ', '')}`"
                    >
                      {{ data.item }}
                    </div>
                  </template>
                </v-select>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              class="excursion-stepper--excursion-date-picker-container"
              v-if="excursionStartEndDateSetEnabled"
            >
              <v-flex xs12 class="form-label safe-trip-font"> Excursion Start Date </v-flex>
              <v-flex xs12>
                <v-menu
                  ref="excursionStartDateMenu"
                  v-model="excursionStartDateMenuDisplayed"
                  :close-on-content-click="false"
                  :return-value.sync="excursionStartDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="excursion-stepper--excursion-start-date"
                      v-model="formattedExcursionStartDate"
                      label="Excursion Start Date"
                      prepend-icon="fa-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                      :disabled="isExcursionInitiatedFromPcg"
                      @click:clear="excursionStartDate = ``"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="excursionStartDate"
                    no-title
                    scrollable
                    :allowed-dates="checkIfExcursionStartDateAllowed"
                    id="excursion-stepper--excursion-start-date-picker"
                    :disabled="isExcursionInitiatedFromPcg"
                  >
                    <v-spacer></v-spacer>
                    <cs-button
                      id="excursion-stepper--excursion-start-date--cancel--button"
                      @click="excursionStartDateMenuDisplayed = false"
                      label="Cancel"
                    ></cs-button>
                    <cs-button
                      primary
                      id="excursion-stepper--excursion-start-date--ok--button"
                      @click="$refs.excursionStartDateMenu.save(excursionStartDate)"
                      label="OK"
                    ></cs-button>
                  </v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
            <v-layout
              wrap
              class="excursion-stepper--excursion-date-picker-container"
              v-if="excursionStartEndDateSetEnabled"
            >
              <v-flex xs12 class="form-label safe-trip-font"> Excursion End Date </v-flex>
              <v-flex xs12>
                <v-menu
                  ref="excursionEndDateMenu"
                  v-model="excursionEndDateMenuDisplayed"
                  :close-on-content-click="false"
                  :return-value.sync="excursionEndDate"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      id="excursion-stepper--excursion-end-date"
                      v-model="formattedExcursionEndDate"
                      label="Excursion End Date"
                      prepend-icon="fa-calendar"
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      clearable
                      @click:clear="excursionEndDate = ``"
                      :disabled="disableDayExcursionEndDate || isExcursionInitiatedFromPcg"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="excursionEndDate"
                    no-title
                    scrollable
                    :allowed-dates="checkIfExcursionEndDateAllowed"
                    id="excursion-stepper--excursion-end-date-picker"
                    :disabled="isExcursionInitiatedFromPcg"
                  >
                    <v-spacer></v-spacer>
                    <cs-button
                      id="excursion-stepper--excursion-end-date--cancel--button"
                      @click="excursionEndDateMenuDisplayed = false"
                      label="Cancel"
                    ></cs-button>
                    <cs-button
                      primary
                      id="excursion-stepper--excursion-end-date--ok--button"
                      @click="$refs.excursionEndDateMenu.save(excursionEndDate)"
                      label="OK"
                    ></cs-button>
                  </v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
            <cs-button
              primary
              class="continue-btn"
              id="btnStep1Continue"
              :disabled="$v.$invalid || excursionSaveActionInProgress || unselectedJurisdiction"
              :loading="buttonLoaderDisplayed"
              @click="
                saveDraftByExcursionStepperHeading(excursionStepperHeadingLabel.EXCURSION_DETAILS)
              "
              :label="buttonContinueText"
            ></cs-button>
            <cs-button
              id="excursion-stepper--excursion-step1-clear-and-restart"
              @click="resetConfirmationDialogDisplayed = true"
              label="Clear and Restart"
            ></cs-button>
            <v-tooltip v-if="displayExcursionSyncButton()" top color="#ffffff">
              <template v-slot:activator="{ on: tooltip }">
                <div class="d-inline-block" v-on="{ ...tooltip }">
                  <cs-button
                    primary
                    class="btnSave continue-btn"
                    id="excursion-stepper--excursion-sync-button"
                    name="excursion-stepper--excursion-sync-button"
                    :disabled="excursionSyncActionInProgress || !syncAvailable"
                    :loading="excursionSyncActionInProgress"
                    @click="excursionSyncConfirmationDialogDisplayed = true"
                    label="Synchronise"
                  ></cs-button>
                </div>
              </template>
              <span class="submit-tooltip-content">Synchronise Excursion Management Data</span>
            </v-tooltip>
          </v-stepper-content>

          <!-- step 2: Student Medical and Ratios-->
          <v-stepper-step
            v-if="excursionHighRiskStudentMedicalEnabled"
            :complete="step > highRiskStudentMedicalStep"
            :step="highRiskStudentMedicalStep"
          >
            <div
              id="excursion-stepper--step-header-student-medical-and-ratios--label"
              @click="turnToStep($v.$invalid, highRiskStudentMedicalStep)"
              :class="this.step >= highRiskStudentMedicalStep ? 'safe-trip-step-header' : ''"
            >
              {{ excursionStepperHeadingLabel.STUDENT_MEDICAL_AND_RATIOS }}
            </div>
          </v-stepper-step>
          <v-stepper-content
            v-if="excursionHighRiskStudentMedicalEnabled"
            :step="highRiskStudentMedicalStep"
            id="step2"
            style="height: auto"
          >
            <v-layout v-if="step === highRiskStudentMedicalStep" class="px-1">
              <v-flex>
                <student-selection
                  :formControlEnabled="formControlEnabled"
                  @toggle="toggleStudentSelection"
                  @save="saveDraftStudentSelection"
                ></student-selection>
                <risk-category
                  :riskCategories="highRiskStudentMedicalConditionsRisks"
                  :formControlEnabled="formControlEnabled"
                  :saveDraft="saveDraftOnMandatory"
                />
                <staff-student-supervision
                  :formControlEnabled="formControlEnabled"
                  @toggleSupervision="toggleStaffStudentSupervision"
                ></staff-student-supervision>
                <div
                  v-if="staffToStudentGenderRatioInfoEnabled && staffStudentSupervisionDisplayed"
                  class="sub-category-section"
                >
                  <v-flex xs12 class="sub-label gender-ratios-label">
                    <v-layout align-center justify-space-between>
                      <div>Supervisor & Student Gender Ratios (Optional)</div>
                    </v-layout>
                  </v-flex>
                  <v-spacer></v-spacer>
                  <v-flex xs12>
                    <v-textarea
                      id="risk-assessment-notes"
                      :no-resize="true"
                      solo
                      placeholder="Enter any additional numbers, ratios or clarification on gender ratios where required by school policies"
                      row-height="100"
                      rows="100"
                      v-model="staffToStudentGenderRatioInfo"
                    ></v-textarea>
                  </v-flex>
                </div>
                <hr />
              </v-flex>
            </v-layout>
            <cs-button
              primary
              class="continue-btn"
              :id="`btnStep${highRiskStudentMedicalStep}Continue`"
              :disabled="
                $v.$invalid || excursionSaveActionInProgress || mandatoryLoadActionCompleted
              "
              :loading="buttonLoaderDisplayed"
              @click="
                saveDraftByExcursionStepperHeading(
                  excursionStepperHeadingLabel.STUDENT_MEDICAL_AND_RATIOS
                )
              "
              :label="buttonContinueText"
            ></cs-button>
            <cs-button
              id="excursion-stepper--excursion-step2-clear-and-restart"
              :disabled="mandatoryLoadActionCompleted"
              @click="resetConfirmationDialogDisplayed = true"
              label="Clear and Restart"
            ></cs-button>
          </v-stepper-content>

          <!-- step 3: Risk Categories-->
          <v-stepper-step
            :complete="step > highRiskStudentMedicalStep + 1"
            :step="highRiskStudentMedicalStep + 1"
          >
            <div
              id="excursion-stepper--step-header-risk-categories--label"
              @click="turnToStep($v.$invalid, highRiskStudentMedicalStep + 1)"
              :class="this.step >= highRiskStudentMedicalStep + 1 ? 'safe-trip-step-header' : ''"
            >
              {{ excursionStepperHeadingLabel.RISK_CATEGORIES }}
            </div>
          </v-stepper-step>
          <v-stepper-content :step="highRiskStudentMedicalStep + 1" id="step3">
            <v-layout v-if="step === highRiskStudentMedicalStep + 1">
              <v-flex>
                <v-flex class="update-provider-stepper" v-if="providerUpdateActionDisplayed">
                  <v-flex class="update-provider-stepper-text">
                    <i class="fas fa-info-circle"></i>
                    Click the Update Providers button for any immediate updates that are required.
                  </v-flex>
                  <v-flex class="update-provider-stepper-button">
                    <update-providers :buttonUpdateProvidersEnabled="true"> </update-providers>
                  </v-flex>
                </v-flex>
                <hr v-if="providerUpdateActionDisplayed" />
                <v-flex class="riskManifest">
                  <i class="fas fa-info-circle"></i>
                  <span>The SafeTripBuilder risk manifest is available </span>
                  <span class="riskManifestLink">
                    <span
                      id="excursion-stepper--excursion-risk-manifest--link"
                      @click="registerRiskManifestLinkClickEvent()"
                      >here</span
                    >
                  </span>
                  <span> if you want to view all supported risk selections. </span>
                </v-flex>
                <risk-category
                  :riskCategories="nonHighRiskStudentMedicalConditionsRisks"
                  :formControlEnabled="formControlEnabled"
                  :saveDraft="saveDraftOnMandatory"
                />
                <hr />
                <export-excursion-policy
                  style="margin-top: 24px"
                  :excursionPolicyExportActionEnabled="$v.$invalid || !riskCategoriesValid"
                  :submitExcursion="submit"
                  :valuesChanged="valuesChanged"
                  :excursionBuilderViewDisplayed="false"
                ></export-excursion-policy>
              </v-flex>
            </v-layout>
            <v-alert
              v-if="!activitySelectionValid"
              style="margin-top: 15px"
              color="#c80063"
              icon="info"
              outlined
            >
              An Activity has been selected in the excursion proposal. Please complete the Activity
              selection by selecting the individual risks.
            </v-alert>
            <v-layout>
              <cs-button
                primary
                class="continue-btn"
                :id="`btnStep${highRiskStudentMedicalStep + 1}Continue`"
                :disabled="
                  $v.$invalid ||
                  !riskCategoriesValid ||
                  excursionSaveActionInProgress ||
                  !selectTypeValid ||
                  !activitySelectionValid ||
                  checklistFetchActionInProgress
                "
                :loading="buttonLoaderDisplayed"
                @click="
                  saveDraftByExcursionStepperHeading(excursionStepperHeadingLabel.RISK_CATEGORIES)
                "
                :label="buttonContinueText"
              ></cs-button>
              <cs-button
                id="excursion-stepper--excursion-step4-clear-and-restart"
                @click="resetConfirmationDialogDisplayed = true"
                label="Clear and Restart"
              ></cs-button>
            </v-layout>
          </v-stepper-content>

          <!-- step 4: Additional Information-->
          <v-stepper-step
            v-if="excursionAdditionalInformationEnabled"
            :complete="step > additionalInformationStep"
            :step="additionalInformationStep"
          >
            <div
              id="excursion-stepper--step-header-additional-information--label"
              @click="turnToStep($v.$invalid, additionalInformationStep)"
              :class="this.step >= additionalInformationStep ? 'safe-trip-step-header' : ''"
            >
              {{ excursionStepperHeadingLabel.ADDITIONAL_INFORMATION }}
            </div>
          </v-stepper-step>
          <v-stepper-content
            v-if="excursionAdditionalInformationEnabled"
            :step="additionalInformationStep"
            id="step4"
          >
            <v-flex xs12 v-if="clientTemplate.sid">
              <v-layout wrap>
                <v-flex xs12 class="form-label safe-trip-font"> Pro Forma Template </v-flex>
                <v-flex xs12>
                  {{ clientTemplate.filename }}
                  <v-icon
                    id="excursion-stepper--download-file--button"
                    @click="downloadFile(clientTemplate)"
                    medium
                    style="font-size: 15px; height: 17px; margin-left: 0.7%"
                    >fa fa-download</v-icon
                  >
                </v-flex>
              </v-layout>
            </v-flex>
            <additional-info-list
              :additionalInformationItems="additionalInformationItems"
              @setAdditionalInformationValid="setAdditionalInformationValid"
              :formControlEnabled="formControlEnabled"
              ref="additionalInfoList"
            ></additional-info-list>
            <cs-button
              primary
              class="continue-btn"
              :id="`btnStep${additionalInformationStep}Continue`"
              :disabled="
                $v.$invalid ||
                !additionalInformationValid ||
                excursionAttachmentUploadActionInProgress ||
                excursionSaveActionInProgress
              "
              :loading="buttonLoaderDisplayed"
              @click="
                saveDraftByExcursionStepperHeading(
                  excursionStepperHeadingLabel.ADDITIONAL_INFORMATION
                )
              "
              :label="buttonContinueText"
            ></cs-button>
            <cs-button
              id="excursion-stepper--excursion-step5-clear-and-restart"
              @click="resetConfirmationDialogDisplayed = true"
              label="Clear and Restart"
            ></cs-button>
          </v-stepper-content>

          <!-- step 5: Excursion Risk Assessment-->
          <v-stepper-step
            :complete="step > additionalInformationStep + 1"
            :step="additionalInformationStep + 1"
          >
            <div
              id="excursion-stepper--step-header-excursion-risk-assessment--label"
              @click="turnToStep($v.$invalid, additionalInformationStep + 1)"
              :class="this.step >= additionalInformationStep + 1 ? 'safe-trip-step-header' : ''"
            >
              {{ excursionStepperHeadingLabel.EXCURSION_RISK_ASSESSMENT }}
            </div>
          </v-stepper-step>
          <v-stepper-content :step="additionalInformationStep + 1" id="step5">
            <v-layout v-if="excursionUserRiskRatingEnabled" class="mt-3" id="riskOverallContainer">
              <risk-rating-overall riskRatingRefname="overallRating"></risk-rating-overall>
            </v-layout>
            <v-layout
              class="mt-3"
              id="inherentRiskContainer"
              v-if="excursionInherentRiskRatingDetected"
            >
              <risk-rating-summary
                headingLabel="Inherent Rating"
                :tooltipBubbleText="inherentRiskRatingTooltipBubbleText"
                riskRatingRefname="inherent"
              ></risk-rating-summary>
            </v-layout>
            <v-layout class="mt-3" id="residualRiskContainer">
              <risk-rating-summary
                :headingLabel="residualRiskRatingSummaryHeadingLabel"
                :tooltipBubbleText="residualRiskRatingTooltipBubbleText"
                riskRatingRefname="residual"
              ></risk-rating-summary>
            </v-layout>
            <v-flex xs12 class="form-label safe-trip-font" v-if="riskAssessmentNotesFieldDisplayed">
              Notes
            </v-flex>
            <v-flex xs12 v-if="riskAssessmentNotesFieldDisplayed">
              <v-textarea
                id="risk-assessment-notes"
                solo
                placeholder="Notes (optional)"
                row-height="100"
                rows="100"
                v-model="riskAssessmentNotes"
              ></v-textarea>
            </v-flex>
            <v-layout align-start justify-start class="create-risk-assessment">
              <div>
                <export-excursion-risk-assessment
                  :excursionPolicyExportActionEnabled="exportPdfButtonDisabled"
                  :registerRiskAssessmentExportEvent="registerRiskAssessmentExportEvent"
                  :submitExcursion="submit"
                  valuesChanged="excursionUpdateThenRiskAssessmentExport"
                ></export-excursion-risk-assessment>
              </div>
              <div>
                <view-predeparture-checklist
                  :excursionPolicyExportActionEnabled="$v.$invalid"
                  :formControlEnabled="formControlEnabled"
                ></view-predeparture-checklist>
              </div>
            </v-layout>
            <cs-button
              primary
              class="continue-btn"
              :id="`btnStep${additionalInformationStep + 1}Continue`"
              :disabled="btnStep5ContinueButtonDisabled"
              :loading="buttonLoaderDisplayed"
              @click="
                saveDraftByExcursionStepperHeading(
                  excursionStepperHeadingLabel.EXCURSION_RISK_ASSESSMENT
                )
              "
              :label="buttonContinueText"
            ></cs-button>
            <cs-button
              id="excursion-stepper--excursion-step6-clear-and-restart"
              @click="resetConfirmationDialogDisplayed = true"
              label="Clear and Restart"
            ></cs-button>
          </v-stepper-content>

          <!-- step 6: Mandatory Excursion Checklists-->
          <v-stepper-step
            :complete="step > additionalInformationStep + 2"
            :step="additionalInformationStep + 2"
          >
            <div
              id="excursion-stepper--step-header-mandatory-excursion-checklists--label"
              @click="turnToStep($v.$invalid, additionalInformationStep + 2)"
              :class="this.step >= additionalInformationStep + 2 ? 'safe-trip-step-header' : ''"
            >
              {{ excursionStepperHeadingLabel.MANDATORY_EXCURSION_CHECKLISTS }}
            </div>
          </v-stepper-step>
          <v-stepper-content :step="additionalInformationStep + 2" id="step6" style="height: auto">
            <v-layout v-if="step === additionalInformationStep + 2">
              <v-flex>
                <div
                  class="checkBoxContainer"
                  v-if="
                    mandatoryChecklistBatchConfirmationAgreementEnabled &&
                    checklistAttestationEnabled
                  "
                >
                  <v-checkbox
                    color="#c80063"
                    v-model="mandatoryChecklistBatchConfirmationAgreement"
                    class="checkAll"
                    :checked="mandatoryChecklistBatchConfirmationAgreement"
                    id="`check-all-list`"
                    testing-id="mandatoryChecklistBatchConfirmationAgreementCheckbox"
                  ></v-checkbox>

                  <!--eslint-disable-next-line vuejs-accessibility/label-has-for-->
                  <label class="checkAllLabel" for="`check-all-list`">
                    I agree I have completed the following checklists
                  </label>
                </div>
                <mandatory-checklist
                  :saveDraft="saveDraftOnMandatory"
                  :formControlEnabled="formControlEnabled"
                />
              </v-flex>
            </v-layout>
            <cs-button
              primary
              class="continue-btn"
              :id="`btnStep${additionalInformationStep + 2}Continue`"
              :disabled="
                $v.$invalid || excursionSaveActionInProgress || mandatoryLoadActionCompleted
              "
              :loading="buttonLoaderDisplayed"
              @click="
                saveDraftByExcursionStepperHeading(
                  excursionStepperHeadingLabel.MANDATORY_EXCURSION_CHECKLISTS
                )
              "
              :label="buttonContinueText"
            ></cs-button>
            <cs-button
              id="excursion-stepper--excursion-step3-clear-and-restart"
              :disabled="mandatoryLoadActionCompleted"
              @click="resetConfirmationDialogDisplayed = true"
              label="Clear and Restart"
            ></cs-button>
          </v-stepper-content>
          <div>
            <cs-form-dialog
              v-model="riskCategoryChecklistsSkipConfirmationDialogDisplayed"
              id="dlg-risk-category-checklists-skip-confirmation"
              bodyId="dlg-body-risk-category-checklists-skip-confirmation"
              heading="Skip Risk Category Checklists"
              :primaryAction="{
                label: 'Continue',
                eventName: 'primary-click'
              }"
              @primary-click="saveDraftByRiskCategoriesSection"
              :secondaryAction1="{
                label: 'Cancel',
                eventName: 'secondary1-click'
              }"
              @secondary1-click="riskCategoryChecklistsSkipConfirmationDialogDisplayed = false"
            >
              <template v-slot:cs-form-dialog-content>
                <v-card>
                  <v-card-text>
                    Please note, you are continuing without completing all checklists items.
                  </v-card-text>
                </v-card>
              </template>
            </cs-form-dialog>
          </div>

          <!-- step 7: Excursion Risk Management Confirmation-->
          <v-stepper-step
            :complete="step > additionalInformationStep + 3"
            :step="additionalInformationStep + 3"
          >
            <div
              id="excursion-stepper--step-header-excursion-risk-management-confirmation--label"
              @click="turnToStep($v.$invalid, additionalInformationStep + 3)"
              :class="this.step >= additionalInformationStep + 3 ? 'safe-trip-step-header' : ''"
            >
              {{ excursionStepperHeadingLabel.EXCURSION_RISK_MANAGEMENT_CONFIRMATION }}
            </div>
          </v-stepper-step>
          <v-stepper-content :step="additionalInformationStep + 3" id="step7">
            <v-flex class="confirmation-info-text">
              <i class="fas fa-info-circle"></i>
              Confirm acknowledgement by reading, agreeing and ticking below.
            </v-flex>
            <excursion-confirmation
              :disabledCheckbox="excursionSaveActionInProgress"
            ></excursion-confirmation>
            <v-layout>
              <cs-button
                primary
                class="btnSave continue-btn save-excurison-button"
                id="btnSave"
                name="btnSave"
                :disabled="
                  $v.$invalid || !agreementConfirmationTicked || excursionSaveActionInProgress
                "
                @click="
                  saveDraftByExcursionStepperHeading(
                    excursionStepperHeadingLabel.EXCURSION_RISK_MANAGEMENT_CONFIRMATION
                  )
                "
                label="Save"
              ></cs-button>
              <cs-button
                id="btnClearAndRestart"
                :disabled="excursionSaveActionInProgress"
                @click="resetConfirmationDialogDisplayed = true"
                label="Clear and Restart"
              ></cs-button>
              <cs-button
                v-if="vendorExcursionSid"
                id="syncBtn"
                :disabled="!formControlEnabled || excursionSaveActionInProgress"
                :loading="buttonLoaderDisplayed"
                @click="checkThenDisplayStudentMedicalConditionChangeNotice"
                :label="syncExcursionBtnText"
              ></cs-button>
            </v-layout>
          </v-stepper-content>
          <!-- Excursion History -->
          <excursion-history v-if="excursionHistoryDisplayed" />
        </v-stepper>
        <div>
          <cs-form-dialog
            v-model="genericDialogDisplayed"
            id="dlg-excursion-stepper-generic"
            bodyId="dlg-body-excursion-stepper-generic"
            heading="Create Excursion"
            :primaryAction="{
              label: 'OK',
              eventName: 'primary-click'
            }"
            @primary-click="redirectToExcursionBuilder"
          >
            <template v-slot:cs-form-dialog-content>
              <v-card>
                <div v-safe-html="dialogMessage"></div>
              </v-card>
            </template>
          </cs-form-dialog>
        </div>
        <div>
          <cs-form-dialog
            v-model="resetConfirmationDialogDisplayed"
            id="dlg-reset-confirmation"
            bodyId="dlg-body-reset-confirmation"
            heading="Reset Excursion"
            :primaryAction="{
              label: 'Clear and Restart',
              eventName: 'primary-click'
            }"
            @primary-click="clear"
            :secondaryAction1="{
              label: 'Cancel',
              eventName: 'secondary1-click'
            }"
            @secondary1-click="resetConfirmationDialogDisplayed = false"
          >
            <template v-slot:cs-form-dialog-content>
              <v-card>
                <v-card-text
                  >Are you sure you want to clear and restart your excursion? Doing so will clear
                  all data. <br /><br />
                  Are you sure you want to continue?</v-card-text
                >
              </v-card>
            </template>
          </cs-form-dialog>
        </div>
        <div>
          <cs-form-dialog
            v-model="excursionTypeWarningDisplayed"
            id="dlg-excursion-type-warning"
            bodyId="dlg-body-excursion-type-warning"
            heading="Change Excursion Type"
            :primaryAction="{
              label: 'OK',
              eventName: 'primary-click'
            }"
            @primary-click="clearCategoriesOnExcursionTypeUpdate"
            :secondaryAction1="{
              label: 'Cancel',
              eventName: 'secondary1-click'
            }"
            @secondary1-click="cancelExcursionTypeUpdate"
          >
            <template v-slot:cs-form-dialog-content>
              <v-card>
                <v-card-text>
                  You are about to change your Excursion Type after previously saving your
                  selection. Doing so will clear your excursion and you will have to re-enter your
                  details. It will keep other information except risk categories.<br /><br />
                  Are you sure you want to continue?
                </v-card-text>
              </v-card>
            </template>
          </cs-form-dialog>
        </div>
        <div>
          <cs-form-dialog
            v-model="mandatoryChecklistsSkipConfirmationDialogDisplayed"
            id="dlg-mandatory-checklists-skip-confirmation"
            bodyId="dlg-body-mandatory-checklists-skip-confirmation"
            heading="Skip Mandatory Checklists"
            :primaryAction="{
              label: 'Continue',
              eventName: 'primary-click',
              disabled: mandatoryChecklistsContinueButtonDisabled
            }"
            @primary-click="saveDraftByMandatoryChecklistsSection"
            :secondaryAction1="{
              label: 'Cancel',
              eventName: 'secondary1-click'
            }"
            @secondary1-click="mandatoryChecklistsSkipConfirmationDialogDisplayed = false"
          >
            <template v-slot:cs-form-dialog-content>
              <v-card>
                <v-card-text>
                  Please note, you are continuing without completing all checklists items.
                </v-card-text>
              </v-card>
            </template>
          </cs-form-dialog>
        </div>
        <div>
          <cs-form-dialog
            v-model="highRiskChecklistsSkipConfirmationDialogDisplayed"
            id="dlg-high-risk-checklists-skip-confirmation"
            bodyId="dlg-body-high-risk-checklists-skip-confirmation"
            heading="Skip High Risk Checklists"
            :primaryAction="{
              label: 'Continue',
              eventName: 'primary-click'
            }"
            @primary-click="saveDraftByStudentMedicalSection"
            :secondaryAction1="{
              label: 'Cancel',
              eventName: 'secondary1-click'
            }"
            @secondary1-click="highRiskChecklistsSkipConfirmationDialogDisplayed = false"
          >
            <template v-slot:cs-form-dialog-content>
              <v-card>
                <v-card-text
                  v-if="highRiskChecklistsMessageDisplayed && !highRiskNumbersMessageDisplayed"
                >
                  Please note, you are continuing without completing all checklists items.
                </v-card-text>
                <v-card-text
                  v-if="!highRiskChecklistsMessageDisplayed && highRiskNumbersMessageDisplayed"
                >
                  {{ staffSupervisionAndStudentNumbersMessage }}
                </v-card-text>
                <v-card-text
                  v-if="highRiskChecklistsMessageDisplayed && highRiskNumbersMessageDisplayed"
                >
                  Please note, you are continuing without completing
                  <ul>
                    <li>All checklists items</li>
                    <li>Staff / supervision and student numbers</li>
                  </ul>
                </v-card-text>
              </v-card>
            </template>
          </cs-form-dialog>
        </div>
        <div>
          <cs-form-dialog
            v-model="excursionSyncConfirmationDialogDisplayed"
            id="dlg-sync-confirmation"
            bodyId="dlg-body-sync-confirmation"
            heading="Sync Excursion"
            :primaryAction="{
              label: 'Yes',
              eventName: 'primary-click'
            }"
            @primary-click="excursionSync"
            :secondaryAction1="{
              label: 'No',
              eventName: 'secondary1-click'
            }"
            @secondary1-click="excursionSyncConfirmationDialogDisplayed = false"
          >
            <template v-slot:cs-form-dialog-content>
              <v-card>
                <v-card-text>
                  This will reset the excursion and you will lose all information.
                </v-card-text>
              </v-card>
            </template>
          </cs-form-dialog>
        </div>
        <div>
          <cs-form-dialog
            v-model="activityRiskRequiredMessageDisplayed"
            id="dlg-activity-risk-required"
            bodyId="dlg-body-activity-risk-required"
            heading="Activity Risk Required"
            :primaryAction="{
              label: 'OK',
              eventName: 'primary-click'
            }"
            @primary-click="activityRiskRequiredMessageDisplayed = false"
          >
            <template v-slot:cs-form-dialog-content>
              <v-card>
                <v-card-text>
                  An Activity has been selected in the excursion proposal.<br />
                  Please complete the Activity selection by selecting the individual risks.
                </v-card-text>
              </v-card>
            </template>
          </cs-form-dialog>
        </div>
        <div>
          <cs-form-dialog
            v-model="studentMedicalConditionChangeDetectionDialogDisplayed"
            id="dlg-student-medical-condition-change-detection"
            bodyId="dlg-body-student-medical-condition-change-detection"
            heading="Student Medical Condition"
            :primaryAction="{
              label: 'OK',
              eventName: 'primary-click'
            }"
            @primary-click="studentMedicalConditionChangeDetectionDialogDisplayed = false"
          >
            <template v-slot:cs-form-dialog-content>
              <v-card>
                <v-card-text>{{ excursionUpToDateDialogText }}</v-card-text>
              </v-card>
            </template>
          </cs-form-dialog>
        </div>
        <div>
          <cs-form-dialog
            v-model="studentMedicalConditionSyncConfirmationDialogDisplayed"
            id="dlg-student-medical-condition-sync-confirmation"
            bodyId="dlg-body-student-medical-condition-sync-confirmation"
            heading="Student Medical Condition"
            :primaryAction="{
              label: 'Yes',
              eventName: 'primary-click',
              loading: buttonLoaderDisplayed
            }"
            @primary-click="syncStudentMedicalCondition"
            :secondaryAction1="{
              label: 'No',
              eventName: 'secondary1-click',
              disabled: excursionSaveActionInProgress
            }"
            @secondary1-click="studentMedicalConditionSyncConfirmationDialogDisplayed = false"
          >
            <template v-slot:cs-form-dialog-content>
              <v-card>
                <v-card-text>{{ syncExcursionDialogConfirmText }}</v-card-text>
              </v-card>
            </template>
          </cs-form-dialog>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped>
@import '../assets/css/components/_excursion-stepper.css';
</style>

<script>
import * as types from '@/store/mutationTypes';
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import excursionJurisdictions from '../lib/const/excursionJurisdictions';
import excursionStatus from '../lib/const/excursionStatus';
import excursionStepperHeading from '../lib/const/excursionStepperHeading';
import excursionTypeInfo from '../lib/const/excursionTypeInfo';
import riskCategoryLabel from '../lib/const/riskCategoryLabel';
import trackableEvent from '../lib/const/trackableEvent';
import stbHorizontalLogo from '../../public/StbHorizontalLogo.svg';
import defaultRiskMatrix from '../lib/const/riskMatrixTemplate';
import { populateErrorMessage } from '../lib/errorMessages';
import RiskCategory from './RiskCategory';
import ExportExcursionPolicy from './ExportExcursionPolicy';

export default {
  mixins: [validationMixin],

  components: {
    RiskCategory,
    ExportExcursionPolicy
  },

  validations: {
    name: {
      required
    },
    excursionType: {
      required
    },
    vendorExcursionSid: {
      required
    },
    excursionStartDate: {
      required: requiredIf('excursionStartEndDateSetRequired')
    },
    excursionEndDate: {
      required: requiredIf('excursionStartEndDateSetRequired')
    }
  },

  data() {
    return {
      initialStep: 1,
      stbHorizontalLogo,
      genericDialogDisplayed: false,
      riskCategories: [],
      resetConfirmationDialogDisplayed: false,
      mandatoryChecklistsSkipConfirmationDialogDisplayed: false,
      riskCategoryChecklistsSkipConfirmationDialogDisplayed: false,
      highRiskChecklistsSkipConfirmationDialogDisplayed: false,
      highRiskChecklistsMessageDisplayed: false,
      highRiskNumbersMessageDisplayed: false,
      highRiskChecklistsAllTicked: false,
      excursionId: 0,
      pdf: {
        title: 'master excursion risk assessment - sample',
        path: 'doc'
      },
      excursionSaveSuccessful: false,
      mandatoryChecklistSaveActionPerformed: false,
      excursionTypeWarningDisplayed: false,
      additionalInformationValid: true,
      buttonLoaderDisplayed: false,
      excursionAttachmentUploadActionInProgress: false,
      excursionStatusChanged: false,
      errorMessage: '',
      currentExcursion: {},
      excursionSaveActionInProgress: false,
      valuesChanged: false,
      firstTimeLoaded: false,
      excursionSyncConfirmationDialogDisplayed: false,
      excursionSyncActionInProgress: false,
      jurisdictionTooltip:
        "The content of the destination state should be considered when traveling interstate. Given that we research content across each State and Territory's Department of Education, in some cases there may be additional information to consider even if you are only travelling interstate for a day excursion.",
      activityRiskRequiredMessageDisplayed: false,
      buttonContinueText: 'Save and Continue',
      staffSupervisionAndStudentNumbersMessage:
        'Please note, you are continuing without completing staff / supervision and student numbers.',
      mandatoryChecklistsContinueButtonDisabled: false,
      studentMedicalConditionChangeDetectionDialogDisplayed: false,
      studentMedicalConditionSyncConfirmationDialogDisplayed: false,
      staffStudentSupervisionDisplayed: true,
      studentSelectionDisplayed: true,
      excursionStartDateMenuDisplayed: false,
      excursionEndDateMenuDisplayed: false,
      excursionCommonAdapter: false,
      checklistAttestationEnabled: false,
      autoSelectMedicalConditions: false
    };
  },

  computed: {
    ...mapState({
      isInitClientConfigInProgress: (state) => state.authorisation.isInitClientConfigInProgress,
      isAuthenticated: (state) => state.authorisation.isAuthenticated
    }),
    mandatoryChecklistBatchConfirmationAgreement: {
      get() {
        return this.$store.state.common.excursionDc.mandatoryChecklistBatchConfirmationAgreement;
      },
      set(value) {
        this.$store.commit(
          types.COMMON_SET_MANDATORY_CHECKLIST_BATCH_CONFIRMATION_AGREEMENT,
          value
        );
      }
    },
    step: {
      get() {
        let { currentStep } = this.$store.state.common.excursionDc;
        if (!currentStep) {
          currentStep = 1;
        }

        return currentStep;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_EXCURSION_STEP, value);
      }
    },
    name: {
      get() {
        return this.$store.state.common.excursionDc.name;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_EXCURSION_NAME, value);
      }
    },
    providerUpdateActionDisplayed() {
      return this.$store.state.common.providerUpdateActionDisplayed;
    },
    excursionType: {
      get() {
        return this.$store.state.common.excursionDc.typeId;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_EXCURSION_TYPE, value);
        if (value) {
          this.$store.commit(types.COMMON_SET_EXCURSION_DC_CATEGORIES, []);
        }
      }
    },
    jurisdiction: {
      get() {
        return this.$store.state.common.excursionDc.jurisdiction === '' ||
          !this.$store.state.common.excursionDc.jurisdiction
          ? this.$store.state.common.apiDataDefaultJurisdiction
          : this.$store.state.common.excursionDc.jurisdiction;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_EXCURSION_JURISDICTION, value);
      }
    },
    vendorExcursionSid: {
      get() {
        if (this.vendorExcursionSidInputEnabled) {
          return this.$store.state.common.excursionDc.vendorData.vendorExcursionSid;
        }
        return this.$store.state.common.excursionDc.vendorData.vendorExcursionSid || 0;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_VENDOR_EXCURSION_ID, value);
      }
    },
    excursionStartDate: {
      get() {
        return this.$store.state.common.excursionDc.startDate;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_EXCURSION_START_DATE, value);
      }
    },
    formattedExcursionStartDate: {
      get() {
        return this.formatExcursionDate(this.excursionStartDate);
      },
      set(value) {
        return value;
      }
    },
    excursionEndDate: {
      get() {
        return this.$store.state.common.excursionDc.endDate;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_EXCURSION_END_DATE, value);
      }
    },
    formattedExcursionEndDate: {
      get() {
        return this.formatExcursionDate(this.excursionEndDate);
      },
      set(value) {
        return value;
      }
    },
    vendorExcursionSidErrors() {
      const errors = [];

      if (this.vendorExcursionSidInputEnabled) {
        if (!this.$v.vendorExcursionSid.$dirty) return errors;
        if (!this.$v.vendorExcursionSid.required) {
          errors.push('Excursion Manager ID is required.');
        }
      }

      return errors;
    },
    excursionTypeChoiceList() {
      return Object.keys(this.$store.state.common.excursionTypeChoiceList);
    },
    showJurisdiction() {
      return this.$app.excursionCommon.showJurisdiction(this.$store.state);
    },
    jurisdictions() {
      return excursionJurisdictions;
    },
    unselectedJurisdiction() {
      return (
        this.$app.excursionCommon.isWithinAustralia(this.$store.state) &&
        this.$store.state.common.excursionDestinationContentEnabled &&
        this.$store.state.common.excursionDc.jurisdiction === '' &&
        this.$store.state.common.apiDataDefaultJurisdiction === ''
      );
    },
    excursionHighRiskStudentMedicalEnabled() {
      return this.$store.state.common.excursionHighRiskStudentMedicalEnabled;
    },
    selectErrors() {
      const errors = [];
      if (!this.$v.excursionType.$dirty) return errors;
      if (!this.$v.excursionType.required) {
        errors.push('Excursion Type is required');
      }
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      if (!this.$v.name.required) {
        errors.push('Excursion is required.');
      }
      return errors;
    },
    showError() {
      return this.$store.state.common.showError ? this.$store.state.common.showError : false;
    },
    allSelectedActivityRisk() {
      return this.$store.getters['common/getSelectedActivityClasses'][0];
    },
    additionalInformationItems: {
      get() {
        const localInfoItems = this.$store.state.common.excursionDc.additionalInformationItems;

        if (localInfoItems && localInfoItems.length > 0) {
          const localInfoItemsAdded = localInfoItems.filter((r) => !r.isDeleted);
          if (localInfoItemsAdded.length === 1) {
            localInfoItems.forEach((r) => {
              const localR = r;
              if (!localR.isDeleted) {
                if (localR.filename || localR.fileSummary || localR.comment) {
                  localR.showDeleteButton = true;
                }
              }
            });
          } else {
            localInfoItems.forEach((r) => {
              const localR = r;
              localR.showDeleteButton = true;
            });
          }

          return localInfoItems;
        }

        return [
          {
            fileSummary: '',
            comment: '',
            showDeleteButton: false
          }
        ];
      }
    },
    dialogMessage() {
      if (this.excursionSaveSuccessful) {
        return 'Save success';
      }
      let failedErrorMessage = 'Save failed';
      if (this.errorMessage) {
        failedErrorMessage = this.errorMessage;
      }
      return failedErrorMessage;
    },
    agreementConfirmationTicked() {
      if (!this.$store.state.common.excursionDc.agreementConfirmation) {
        return false;
      }
      return this.$store.state.common.excursionDc.agreementConfirmation.ticked;
    },
    vendorExcursionSidInputEnabled() {
      return this.$store.state.common.vendorExcursionSidInputEnabled;
    },
    excursionAdditionalInformationEnabled() {
      return this.$store.state.common.excursionAdditionalInformationEnabled;
    },
    additionalInformationStep() {
      return this.excursionAdditionalInformationEnabled
        ? this.highRiskStudentMedicalStep + 2
        : this.highRiskStudentMedicalStep + 1;
    },
    highRiskStudentMedicalStep() {
      return this.excursionHighRiskStudentMedicalEnabled ? 2 : 1;
    },
    riskCategoriesValid() {
      return this.$store.getters['common/getSelectedCategoryCount'] > 0;
    },
    selectTypeValid() {
      const categoriesResult = this.$store.state.common.excursionDc.categories.filter(
        (c) => c.types.length > 0
      );
      return categoriesResult.length > 0;
    },
    excursionDisplayReady() {
      return this.$store.state.common.excursionDisplayReady;
    },
    excursionStudentMedicalConditionsDisplayReady() {
      return this.$store.state.common.excursionStudentMedicalConditionsDisplayReady;
    },
    excursionLogoDisplayReady() {
      return this.$store.state.common.excursionLogoDisplayReady;
    },
    clientLogoBackgroundColor() {
      return this.$store.state.common.clientLogoBackgroundColor;
    },
    vendorClientLogoEnabled() {
      return this.$store.state.common.vendorClientLogoEnabled;
    },
    stbLogoDisplayed() {
      const { query } = this.$route;

      let stbLogoDisplayed = true;
      if (query && query.referrer && query.referrer.toLowerCase() === 'pcg') {
        stbLogoDisplayed = false;
      }

      return stbLogoDisplayed;
    },
    formControlEnabled() {
      return !(this.$store.state.common.excursionDc.status === excursionStatus.APPROVED);
    },
    excursionTypeLoaded() {
      return this.$store.state.common.excursionTypeLoaded;
    },
    excursionJurisdictionLoaded() {
      return this.$store.state.common.excursionJurisdictionLoaded;
    },
    mandatoryLoadActionCompleted() {
      return this.$store.state.common.mandatoryLoadActionCompleted;
    },
    isNotRated() {
      return this.$store.state.common.excursionDc.selectedRating === '';
    },
    selectedRisks() {
      return JSON.parse(JSON.stringify(this.$store.state.common.excursionDc.categories));
    },
    syncAvailable() {
      return this.$store.state.common.excursionDc.syncAvailable;
    },
    excursionSyncButtonDisplayed() {
      return this.$store.state.common.excursionSyncButtonDisplayed;
    },
    activitySelectionValid() {
      return this.$store.state.common.excursionDc.activitySelectionValid;
    },
    clientTemplate() {
      return this.$store.state.common.excursionDc.clientTemplate;
    },
    highRiskChecklistsNumbersMessageDisplayed() {
      return (
        !this.$store.state.common.excursionDc.staffSupervisorAttendanceCount ||
        !this.$store.state.common.excursionDc.studentAttendanceCount
      );
    },
    excursionHistoryDisplayed() {
      return this.$store.state.common.excursionDc.id > 0;
    },
    checklistFetchActionInProgress() {
      return this.$store.state.common.checklistFetchActionInProgress;
    },
    copyExcursion() {
      return this.$app.lodash.cloneDeep(this.$store.state.common.excursionDc);
    },
    excursionStepperHeadingLabel() {
      return excursionStepperHeading;
    },
    excursionTypeInfoEntryList() {
      return Object.values(excursionTypeInfo);
    },

    excursionStudentFetchTriggered() {
      return this.$store.state.common.excursionStudentFetchTriggered;
    },
    studentMedicalConditionsLoadingAnimationDisplayed() {
      return (
        !this.pageLoadingAnimationDisplayed() &&
        this.excursionStudentFetchTriggered &&
        !this.excursionStudentMedicalConditionsDisplayReady
      );
    },
    excursionRiskOverall() {
      return this.$store.state.common.excursionDc.riskOverall;
    },
    excursionInherentRiskRatingEnabled() {
      return this.$store.state.common.excursionInherentRiskRatingEnabled;
    },
    staffToStudentGenderRatioInfoEnabled() {
      return this.$store.state.common.staffToStudentGenderRatioInfoEnabled;
    },
    mandatoryChecklistBatchConfirmationAgreementEnabled() {
      return this.$store.state.common.mandatoryChecklistBatchConfirmationAgreementEnabled;
    },
    excursionInherentRiskRatingDetected() {
      this.pageLoadingAnimationDisplayed(); // Use this to force refresh after page load

      const riskOverall = this.excursionRiskOverall;
      const localExcursionInherentRiskRatingDetected =
        this.excursionInherentRiskRatingEnabled &&
        riskOverall &&
        riskOverall.residualRiskRating &&
        riskOverall.inherentRiskRating;

      return localExcursionInherentRiskRatingDetected;
    },
    residualRiskRatingSummaryHeadingLabel() {
      let localResidualRiskRatingSummaryHeadingLabel =
        'CompliSpace Safe Excursions Recommended Rating';

      if (this.excursionInherentRiskRatingDetected) {
        localResidualRiskRatingSummaryHeadingLabel = 'Residual Rating';
      }

      return localResidualRiskRatingSummaryHeadingLabel;
    },
    residualRiskRatingTooltipBubbleText() {
      return this.excursionInherentRiskRatingDetected
        ? 'Residual risk is the level of risk that remains after controls have been applied.'
        : '';
    },
    inherentRiskRatingTooltipBubbleText() {
      return this.excursionInherentRiskRatingDetected
        ? 'Inherent risk is the level of risk that exists where no controls have been applied.'
        : '';
    },
    exportPdfButtonDisabled() {
      const userRatingEnabled = this.$store.state.common.excursionDc.excursionUserRiskRatingEnabled;
      if (userRatingEnabled) {
        return this.$v.$invalid || !this.riskCategoriesValid || this.isNotRated;
      }
      return this.$v.$invalid || !this.riskCategoriesValid;
    },
    btnStep5ContinueButtonDisabled() {
      const userRatingEnabled = this.$store.state.common.excursionDc.excursionUserRiskRatingEnabled;
      if (userRatingEnabled) {
        return this.$v.$invalid || this.excursionSaveActionInProgress || this.isNotRated;
      }
      return this.$v.$invalid || this.excursionSaveActionInProgress;
    },
    excursionUserRiskRatingEnabled() {
      this.pageLoadingAnimationDisplayed(); // Use this to force refresh after page load

      /**
       * TODO: Consider fixing this temporary workaround
       * This computed variable works fine when updating existing excursion
       * However, it does not work when creating a new excursion since
       * 'excursionUserRiskRatingEnabled' is undefined
       */
      if (this.step !== 1) {
        return this.$store.state.common.excursionDc.excursionUserRiskRatingEnabled;
      }

      return this.$store.state.common.excursionDc.excursionUserRiskRatingEnabled;
    },
    staffToStudentGenderRatioInfo: {
      get() {
        return this.$store.state.common.excursionDc.staffToStudentGenderRatioInfo;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_STAFF_TO_STUDENT_GENDER_RATIO_INFO, value);
      }
    },
    riskAssessmentNotes: {
      get() {
        return this.$store.state.common.excursionDc.riskAssessmentNotes;
      },
      set(value) {
        this.$store.commit(types.COMMON_SET_EXCURSION_RISK_ASSESSMENT_NOTES, value);
      }
    },
    riskAssessmentNotesFieldDisplayed() {
      this.pageLoadingAnimationDisplayed(); // Use this to force refresh after page load

      /**
       * TODO: Consider fixing this temporary workaround
       * This computed variable works fine when updating existing excursion
       * However, it does not work when creating a new excursion since
       * 'riskAssessmentNotesEnabled' is undefined
       */
      if (this.step !== 1) {
        return this.$store.state.common.excursionDc.riskAssessmentNotesEnabled;
      }

      return this.$store.state.common.excursionDc.riskAssessmentNotesEnabled;
    },
    syncExcursionBtnText() {
      return this.$store.state.common.studentManagementExtraGroupFeatureSetEnabled
        ? 'Update Dates or Student Information'
        : 'Update Excursion Details and Supervision Ratio';
    },
    syncExcursionDialogConfirmText() {
      return this.$store.state.common.studentManagementExtraGroupFeatureSetEnabled
        ? `Name, dates, medical information or supervision ratios have been updated in your 
      school management system. Do you want to update the excursion details and review and confirm policies and checklists?`
        : `Name, dates or supervision ratios have been updated in your
      excursion management system. Do you want to update the excursion details and review and 
      confirm policies and checklists?`;
    },
    excursionUpToDateDialogText() {
      return this.$store.state.common.studentManagementExtraGroupFeatureSetEnabled
        ? 'Name, dates, medical information, and supervision ratios are up to date for this excursion.'
        : 'Name, dates and supervision ratios are up to date for this excursion.';
    },
    newExcursionDetected() {
      return (
        !this.$store.state.common.excursionDc.id ||
        `${this.$store.state.common.excursionDc.id}` === '0'
      );
    },
    excursionStartEndDateSetEnabled() {
      return (
        this.newExcursionDetected ||
        (!this.newExcursionDetected && this.$store.state.common.excursionStartEndDateSetEnabled)
      );
    },
    excursionStartEndDateSetRequired() {
      return (
        this.excursionStartEndDateSetEnabled &&
        ((typeof this.excursionStartDate !== 'undefined' && this.excursionStartDate !== '') ||
          (typeof this.excursionEndDate !== 'undefined' && this.excursionEndDate !== ''))
      );
    },
    mixedMedicalConditionHash() {
      return this.$store.state.common.mixedMedicalConditionHash;
    },
    disableDayExcursionEndDate() {
      return excursionTypeInfo?.DAY_EXCURSION.label === this.excursionType;
    },
    highRiskStudentMedicalConditionsRisks() {
      return this.$store.getters['common/getHighRiskStudentMedicalConditionsRisks'];
    },
    nonHighRiskStudentMedicalConditionsRisks() {
      return this.$store.getters['common/getNonHighRiskStudentMedicalConditionsRisks'];
    },
    isExcursionInitiatedFromPcg() {
      return (
        this.$store.state.common.userAuthSchemeApiParam?.['vendor-sid'] === '10000001' &&
        !!this.$store.state.common.excursionDc.vendorData.vendorExcursionSid &&
        !this.newExcursionDetected
      );
    }
  },

  methods: {
    formatExcursionDate(date) {
      return this.$app.excursionCommon.formatExcursionDate(date);
    },
    checkIfExcursionStartDateAllowed(date) {
      return this.$app.excursionCommon.checkIfExcursionStartEndDateSetValid({
        excursionStartDate: date,
        excursionEndDate: this.disableDayExcursionEndDate ? '' : this.excursionEndDate,
        excursionType: this.excursionType
      });
    },
    checkIfExcursionEndDateAllowed(date) {
      return this.$app.excursionCommon.checkIfExcursionStartEndDateSetValid({
        excursionStartDate: this.excursionStartDate,
        excursionEndDate: date,
        excursionType: this.excursionType
      });
    },
    handleExcursionStartEndDateIntegrity() {
      let excursionStartDateAllowed = true;
      if (this.excursionStartDate) {
        excursionStartDateAllowed = this.checkIfExcursionStartDateAllowed(this.excursionStartDate);
      }

      let excursionEndDateAllowed = true;
      if (this.excursionEndDate) {
        excursionEndDateAllowed = this.checkIfExcursionEndDateAllowed(this.excursionEndDate);
      }

      if (this.disableDayExcursionEndDate) {
        this.excursionEndDate = this.excursionStartDate;
      } else if (!(excursionStartDateAllowed && excursionEndDateAllowed)) {
        this.excursionEndDate = '';
      }
    },

    toggleStaffStudentSupervision(isShow) {
      this.staffStudentSupervisionDisplayed = isShow;
    },

    toggleStudentSelection(isShow) {
      this.studentSelectionDisplayed = isShow;
    },

    async saveDraftStudentSelection() {
      this.mandatoryChecklistSaveActionPerformed = true;
      this.autoSelectMedicalConditions = true;
      await this.submit();
    },

    async checkThenDisplayStudentMedicalConditionChangeNotice() {
      const excursionMetadata = JSON.parse(JSON.stringify(this.$store.state.common.excursionDc));
      this.buttonLoaderDisplayed = true;
      this.excursionSaveActionInProgress = true;
      const syncExcursionRiskCbResponse =
        await this.$app.stbApiAdapter.getExcursionStudentMedicalConditionSync({
          excursionId: excursionMetadata.id,
          store: this.$store
        });
      if (syncExcursionRiskCbResponse.data) {
        this.studentMedicalConditionSyncConfirmationDialogDisplayed = true;
        this.buttonLoaderDisplayed = false;
        this.excursionSaveActionInProgress = false;
      } else {
        this.studentMedicalConditionChangeDetectionDialogDisplayed = true;
        this.buttonLoaderDisplayed = false;
        this.excursionSaveActionInProgress = false;
      }
    },

    async syncStudentMedicalCondition() {
      const excursionMetadata = JSON.parse(JSON.stringify(this.$store.state.common.excursionDc));
      const query = JSON.parse(JSON.stringify(this.$route.query));
      const apiArgHash = {
        excursionId: excursionMetadata.id,
        query,
        store: this.$store
      };
      this.buttonLoaderDisplayed = true;
      this.excursionSaveActionInProgress = true;
      const syncExcursionRiskCbResponse =
        await this.$app.stbApiAdapter.putExcursionStudentMedicalConditionSync({
          excursionId: excursionMetadata.id,
          store: this.$store
        });

      let excursionStepModified = false;
      if (
        syncExcursionRiskCbResponse &&
        syncExcursionRiskCbResponse.data &&
        syncExcursionRiskCbResponse.data.computed
      ) {
        if (
          syncExcursionRiskCbResponse.data.computed.excursionStartEndDateSetChangeDetected ||
          syncExcursionRiskCbResponse.data.computed.studentMedicalConditionChangeDetected
        ) {
          const getExcursionStudentMedicalConditionListCbResponse =
            await this.$app.stbApiAdapter.getExcursionStudentMedicalConditionList(apiArgHash);
          const studentMedicalConditions =
            getExcursionStudentMedicalConditionListCbResponse.data.data;
          this.$store.commit(types.COMMON_SET_EXCURSION, syncExcursionRiskCbResponse);
          this.$store.commit(
            types.COMMON_SET_EXCURSION_STUDENT_MEDICAL_CONDITIONS,
            studentMedicalConditions
          );
          this.$store.commit(
            types.COMMON_SET_EXCURSION_STUDENT_MEDICAL_CONDITIONS_DISPLAY_READY,
            true
          );
          this.buttonLoaderDisplayed = false;
          this.excursionSaveActionInProgress = false;
          excursionStepModified = true;
          this.turnToStep(this.$v.$invalid, this.initialStep);
        } else if (
          syncExcursionRiskCbResponse.data.computed.excursionNameChangeDetected ||
          syncExcursionRiskCbResponse.data.computed.staffToStudentRatioChangeDetected
        ) {
          this.$store.commit(
            types.COMMON_SET_EXCURSION_NAME,
            syncExcursionRiskCbResponse.data.metadata.name
          );
          this.$store.commit(
            types.COMMON_SET_EXCURSION_DC_STAFF_SUPERVISOR_ATTENDANCE_COUNT,
            syncExcursionRiskCbResponse.data.metadata.staffSupervisorAttendanceCount
          );
          this.$store.commit(
            types.COMMON_SET_EXCURSION_DC_STUDENT_ATTENDANCE_COUNT,
            syncExcursionRiskCbResponse.data.metadata.studentAttendanceCount
          );
        }
      }

      if (!excursionStepModified) {
        this.buttonLoaderDisplayed = false;
        this.excursionSaveActionInProgress = false;
      }

      this.studentMedicalConditionSyncConfirmationDialogDisplayed = false;
    },

    getRiskAssessmentTrackableEventBaseArgHash() {
      const { apiDataDefaultJurisdiction, excursionDc } = this.$store.state.common;
      return this.$app.excursionCommon.getRiskAssessmentTrackableEventBaseArgHash(
        apiDataDefaultJurisdiction,
        excursionDc
      );
    },

    getRiskAssessmentTrackableEventOptionArgHash() {
      return this.$app.excursionCommon.getRiskAssessmentTrackableEventOptionArgHash();
    },

    registerRiskAssessmentExcursionDetailsUpdateEvent() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.eventPluginCommonAdapter.registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_EXCURSION_DETAILS_UPDATE,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash
      );
    },

    registerRiskAssessmentExportEvent() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.eventPluginCommonAdapter.registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_EXCURSION_RISK_ASSESSMENT_EXPORT,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash
      );
    },

    registerRiskManifestLinkClickEvent() {
      this.excursionCommonAdapter.registerRiskManifestLinkClickEvent();
    },

    // TODO: Simplify this to submit(submissionArgHash)
    async submit(
      status,
      statusChanged,
      callback,
      submissionModifier,
      targetExcursionStepperHeading
    ) {
      let localStatus = status;

      if (!this.mandatoryChecklistSaveActionPerformed) {
        this.$v.$touch();
        if (this.$v.$error) return false;
      }

      this.excursionSaveActionInProgress = true;
      const apiQuery = JSON.parse(JSON.stringify(this.$store.state.common.apiParam));
      const excursionMetadata = JSON.parse(JSON.stringify(this.$store.state.common.excursionDc));
      this.excursionId = excursionMetadata.id;

      delete excursionMetadata.id;
      delete excursionMetadata.additionalInformationItems;
      delete excursionMetadata.computed;
      delete excursionMetadata.activitySelectionValid;
      delete excursionMetadata.auditList;
      delete excursionMetadata.selectedLikelihood;
      delete excursionMetadata.selectedConsequence;
      delete excursionMetadata.selectedRating;
      delete excursionMetadata.client;

      excursionMetadata.name = this.name;
      excursionMetadata.typeId = this.excursionType;
      excursionMetadata.startDate = this.excursionStartDate;
      excursionMetadata.endDate = this.excursionEndDate;

      if (!excursionMetadata.studentMedicalConditionInfo) {
        excursionMetadata.studentMedicalConditionInfo = {};
      }
      excursionMetadata.studentMedicalConditionInfo.mixedMedicalConditionHash =
        this.mixedMedicalConditionHash;

      if (!excursionMetadata.currentStep) {
        await this.setDefaultJurisdiction(excursionMetadata);
      }

      let presaveActionPerformed = false;
      if (submissionModifier && submissionModifier === 'downloadExcursionPolicy') {
        presaveActionPerformed = true;
        this.excursionSaveActionInProgress = false;
      }

      let excursionSyncActionPerformed = false;
      if (submissionModifier && submissionModifier === 'excursionSync') {
        excursionSyncActionPerformed = true;
        this.excursionSaveActionInProgress = false;
        excursionMetadata.syncEnabled = true;
      }

      let excursionUserRatingSync = false;
      if (submissionModifier && submissionModifier === 'excursionUserRatingSync') {
        excursionUserRatingSync = true;
      }

      if (
        !this.mandatoryChecklistSaveActionPerformed &&
        !presaveActionPerformed &&
        !excursionSyncActionPerformed &&
        !excursionUserRatingSync
      ) {
        excursionMetadata.currentStep = this.step + 1;
      }
      excursionMetadata.riskOverallUserRating = {
        likelihood: this.$store.state.common.excursionDc.selectedLikelihood,
        consequence: this.$store.state.common.excursionDc.selectedConsequence,
        rating: this.$store.state.common.excursionDc.selectedRating
      };
      excursionMetadata.riskAssessmentNotes = this.riskAssessmentNotes;
      excursionMetadata.staffToStudentGenderRatioInfo = this.staffToStudentGenderRatioInfo;
      excursionMetadata.mandatoryChecklistBatchConfirmationAgreement =
        this.mandatoryChecklistBatchConfirmationAgreement;
      if (
        !this.isNotRated &&
        Object.keys(this.$store.state.common.excursionDc.riskMatrix).length === 0
      ) {
        this.$store.commit(
          types.COMMON_SET_EXCURSION_RISK_MATRIX,
          this.$store.state.common.clientSettingRiskMatrix
        );
        excursionMetadata.riskMatrix = this.$store.state.common.clientSettingRiskMatrix;
      }

      const apiPayload = {
        metadata: excursionMetadata
      };
      let excursionFromApi;

      if (`${this.excursionId}` !== '0') {
        // Risk Categories
        if (
          (this.step === 3 && !this.excursionHighRiskStudentMedicalEnabled) ||
          ((this.step === 4 || this.step === 2) && this.excursionHighRiskStudentMedicalEnabled)
        ) {
          const excursionRiskCombo = await this.excursionCommonAdapter.getExcursionRiskCombo(
            this.$route.query
          );
          if (excursionRiskCombo) {
            apiPayload.metadata.riskOverall = excursionRiskCombo.riskOverall;
            apiPayload.metadata.riskCategories = excursionRiskCombo.riskCategories;
          }
        }

        // set updateSignature
        if (!apiPayload.metadata.updateSignature) {
          apiPayload.metadata.updateSignature = this.$app.uuid.v4();
        }

        const query = JSON.parse(JSON.stringify(this.$route.query));

        if (
          this.step === 1 &&
          query &&
          query.referrer &&
          query.referrer.toLowerCase() === 'pcg' &&
          !this.syncAvailable
        ) {
          apiPayload.metadata.syncEnabled = true;
        }

        const putExcursionsApiArgHash = {
          store: this.$store,
          queryParam: apiQuery,
          id: this.excursionId,
          payload: apiPayload
        };
        const putExcursionsCbResponse = await this.$app.stbApiAdapter.putExcursions(
          putExcursionsApiArgHash
        );

        this.$store.dispatch('common/setActivitySelectionValid');
        this.buttonLoaderDisplayed = false;

        if (callback && typeof callback === 'function') {
          await callback();
        }
        if (!this.$store.state.common.isResponseWithError) {
          if (!putExcursionsCbResponse.error) {
            const { metadata } = putExcursionsCbResponse.data;
            this.currentExcursion = putExcursionsCbResponse;

            if (!localStatus && metadata.status === excursionStatus.SUBMITTED) {
              this.excursionStatusChanged = true;
              localStatus = excursionStatus.DRAFT;
              metadata.status = localStatus;
            }

            if (
              this.excursionStatusChanged &&
              metadata.status === excursionStatus.DRAFT &&
              statusChanged
            ) {
              const putExcursionStatusesArgHash = {
                queryParam: apiQuery,
                newStatus: localStatus,
                store: this.$store,
                id: this.excursionId
              };
              const statusResponse = await this.$app.stbApiAdapter.putExcursionStatuses(
                putExcursionStatusesArgHash
              );
              this.excursionStatusChanged = false;
              if (!statusResponse.error) {
                this.currentExcursion.data.metadata.status = localStatus;
                this.excursionSaveSuccessful = true;
                this.$store.commit(types.COMMON_SET_EXCURSION, this.currentExcursion);
                this.$store.commit(types.COMMON_SET_SHOW_EXCURSION_STEPPER, true);

                if (
                  localStatus === excursionStatus.SUBMITTED &&
                  !this.mandatoryChecklistSaveActionPerformed
                ) {
                  this.genericDialogDisplayed = true;
                }
              }
            } else {
              this.excursionSaveSuccessful = true;
              if (localStatus && localStatus === excursionStatus.SUBMITTED) {
                this.genericDialogDisplayed = true;
              } else {
                this.$store.commit(types.COMMON_SET_EXCURSION, this.currentExcursion);
                this.$store.commit(types.COMMON_SET_SHOW_EXCURSION_STEPPER, true);
              }
            }

            // Handle student medical conditions
            excursionFromApi = putExcursionsCbResponse.data;

            // Remove additional information during sync
            if (apiPayload.metadata.syncEnabled) {
              const localInfoItems = this.$app.lodash.cloneDeep(
                this.$store.state.common.excursionDc.additionalInformationItems
              );
              if (localInfoItems && localInfoItems.length > 0) {
                localInfoItems.forEach((a) => {
                  const localA = a;
                  localA.isDeleted = true;
                });
              }

              await this.updateAdditionalInformationList(localInfoItems);

              this.$store.commit(types.COMMON_RESET_RISKS_DISABLED);
            }
          } else {
            this.excursionSaveSuccessful = false;
            this.errorMessage = putExcursionsCbResponse.message;

            this.errorMessage = populateErrorMessage({
              message: this.errorMessage,
              store: this.$store
            });
            this.genericDialogDisplayed = true;
          }
        }
        this.excursionSaveActionInProgress = false;
      } else {
        const postExcursionsApiArgHash = {
          store: this.$store,
          queryParam: apiQuery,
          payload: apiPayload
        };
        const postExcursionsCbResponse = await this.$app.stbApiAdapter.postExcursions(
          postExcursionsApiArgHash
        );
        this.buttonLoaderDisplayed = false;

        if (callback && typeof callback === 'function') {
          await callback();
        }
        if (!this.$store.state.common.isResponseWithError) {
          if (!postExcursionsCbResponse.error) {
            if (this.$store.state.common.clientTemplate) {
              this.$store.commit(
                types.COMMON_SET_EXCURSION_DC_CLIENT_TEMPLATE,
                this.$store.state.common.clientTemplate
              );
            }

            excursionFromApi = postExcursionsCbResponse.data;
            const computedQuery = JSON.parse(JSON.stringify(excursionFromApi.computed.query));

            if (!this.mandatoryChecklistSaveActionPerformed) {
              const query = JSON.parse(JSON.stringify(this.$route.query));

              this.$router.replace(`/excursions/${computedQuery['excursion-id']}`);

              if (query && query['oauth-signature'] && computedQuery) {
                this.$router.replace({ query: computedQuery });
              }
            }

            // Update the store excursion so subsequent savings send PUT request instead of POST
            await this.$store.dispatch('common/getExcursions', {
              stbApiAdapter: this.$app.stbApiAdapter,
              store: this.$store,
              excursionId: excursionFromApi.id,
              query: computedQuery
            });

            this.excursionSaveSuccessful = true;
          } else {
            this.errorMessage = postExcursionsCbResponse.message;
            this.excursionSaveSuccessful = false;

            this.errorMessage = populateErrorMessage({
              message: this.errorMessage,
              store: this.$store
            });

            this.genericDialogDisplayed = true;
          }
        }
        this.excursionSaveActionInProgress = false;
      }

      if (excursionFromApi) {
        if (excursionFromApi.computed) {
          const excursionStudentFetchTriggered =
            !!excursionFromApi.computed.excursionStudentFetchTriggered;

          if (excursionStudentFetchTriggered) {
            const query = JSON.parse(JSON.stringify(this.$route.query));

            this.$store.commit(
              types.COMMON_SET_EXCURSION_STUDENT_FETCH_TRIGGERED,
              excursionStudentFetchTriggered
            );
            this.$store.commit(
              types.COMMON_SET_EXCURSION_STUDENT_MEDICAL_CONDITIONS_DISPLAY_READY,
              false
            );

            const excursionIdFromApi = excursionFromApi.id;
            const getExcursionStudentMedicalConditionListApiArgHash = {
              excursionId: excursionIdFromApi,
              query,
              store: this.$store
            };
            const getExcursionStudentMedicalConditionListCbResponse =
              await this.$app.stbApiAdapter.getExcursionStudentMedicalConditionList(
                getExcursionStudentMedicalConditionListApiArgHash
              );

            const studentMedicalConditions =
              getExcursionStudentMedicalConditionListCbResponse.data.data;
            this.$store.commit(
              types.COMMON_SET_EXCURSION_STUDENT_MEDICAL_CONDITIONS,
              studentMedicalConditions
            );
            this.$store.commit(
              types.COMMON_SET_EXCURSION_STUDENT_MEDICAL_CONDITIONS_DISPLAY_READY,
              true
            );
            if (this.autoSelectMedicalConditions) {
              this.autoSelectHighRiskMedicalConditions(studentMedicalConditions);
              this.autoSelectMedicalConditions = false;
            }
          }
        }
      }

      if (excursionStepperHeading.EXCURSION_DETAILS === targetExcursionStepperHeading) {
        this.registerRiskAssessmentExcursionDetailsUpdateEvent();
      }

      this.$nextTick(() => {
        this.valuesChanged = false;
      });

      if (this.excursionSaveSuccessful) {
        return true;
      }

      return false;
    },
    autoSelectHighRiskMedicalConditions(studentMedicalConditions) {
      const categoryName = riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS;
      this.$store.dispatch('common/setAllRiskClassEnabled', {
        typeName: this.highRiskStudentMedicalConditionsRisks[0].types[0].name,
        categoryName
      });
      const newExcursionCategories = this.selectedRisks.filter(
        (selectedRisk) => selectedRisk.name !== categoryName
      );
      const newAllRisks = this.$store.state.common.allRisks.map((allRiskCategory) => {
        if (allRiskCategory.name === categoryName && allRiskCategory.CompleteChecklist) {
          const { CompleteChecklist, ...rest } = allRiskCategory;
          return rest;
        }
        return allRiskCategory;
      });
      this.$store.commit(types.COMMON_SET_EXCURSION_CATEGORIES, newExcursionCategories);
      this.$store.commit(types.COMMON_SET_ALL_RISKS, newAllRisks);

      studentMedicalConditions.forEach((studentMedicalCondition) => {
        if (!studentMedicalCondition.supported) return;

        this.$store.dispatch('common/setRiskClassDisabled', {
          typeName: this.highRiskStudentMedicalConditionsRisks[0].types[0].name,
          categoryName,
          className: studentMedicalCondition.name,
          disabled: true
        });
        this.$store.dispatch('common/changeSelectRiskClass', {
          riskClass: studentMedicalCondition,
          riskTypeName: this.highRiskStudentMedicalConditionsRisks[0].types[0].name,
          riskCategory: {
            name: categoryName
          }
        });
      });
    },
    async setDefaultJurisdiction(excursionMetadata) {
      const localExcursionMetadata = excursionMetadata;

      if (localExcursionMetadata.jurisdiction === '') {
        localExcursionMetadata.jurisdiction = this.$store.state.common.apiDataDefaultJurisdiction;
        // set default jurisdiction value if user didn't select any
      }
    },
    async saveDraft(targetExcursionStepperHeading) {
      this.mandatoryChecklistsSkipConfirmationDialogDisplayed = false;
      this.riskCategoryChecklistsSkipConfirmationDialogDisplayed = false;
      this.highRiskChecklistsSkipConfirmationDialogDisplayed = false;
      this.mandatoryChecklistSaveActionPerformed = false;
      this.buttonLoaderDisplayed = true;

      if (
        !this.callSaveDraft &&
        this.$store.state.common.originalTypeId &&
        `${this.$store.state.common.excursionDc.typeId}` !==
          `${this.$store.state.common.originalTypeId}` &&
        this.step <= 1
      ) {
        this.excursionTypeWarningDisplayed = true;
        return;
      }

      this.callSaveDraft = false;

      if (excursionStepperHeading.ADDITIONAL_INFORMATION === targetExcursionStepperHeading) {
        if (this.additionalInformationItems && this.additionalInformationItems.length > 0) {
          const excursionMetadata = JSON.parse(
            JSON.stringify(this.$store.state.common.excursionDc)
          );
          this.excursionId = excursionMetadata.id;

          const sortedAdditionalInformationItems = [];
          for (let i = 0; i < this.additionalInformationItems.length; i += 1) {
            this.additionalInformationItems[i].orderId = i + 1;
            sortedAdditionalInformationItems.push(this.additionalInformationItems[i]);
          }

          // add
          const fd = new FormData();
          const addAdditionalList = sortedAdditionalInformationItems.filter(
            (a) => typeof a.file !== 'undefined'
          );
          if (addAdditionalList && addAdditionalList.length > 0) {
            const addItems = [];
            for (let i = 0; i < addAdditionalList.length; i += 1) {
              const additional = addAdditionalList[i];
              fd.append(`file-${i}`, additional.file);
              addItems.push({
                summary: additional.fileSummary,
                description: additional.comment,
                orderId: additional.orderId
              });
            }

            fd.append('items', JSON.stringify(addItems));
            this.excursionAttachmentUploadActionInProgress = true;
            const argHash = {
              queryParam: this.$store.state.common.apiParam,
              excursionId: this.excursionId,
              payload: fd,
              store: this.$store
            };

            const attachmentResponse = await this.$app.stbApiAdapter.postExcursionAttachments(
              argHash
            );

            if (attachmentResponse && attachmentResponse.data) {
              const attachments = attachmentResponse.data;
              if (attachments && attachments.length > 0) {
                sortedAdditionalInformationItems.forEach((item) => {
                  const localItem = item;
                  const fileFound = attachments.find(
                    (file) => file.metadata.orderId === localItem.orderId
                  );

                  if (fileFound) {
                    localItem.sid = fileFound.sid;
                  }
                });
              }
            }

            this.excursionAttachmentUploadActionInProgress = false;
          }
          // update
          const updateAdditionalInformationList = sortedAdditionalInformationItems.filter(
            (a) => a.sid && a.sid.length > 0
          );

          await this.updateAdditionalInformationList(updateAdditionalInformationList);

          this.$store.commit(
            types.COMMON_SET_EXCURSION_DC_ADDITIONAL_INFORMATION_NOTES,
            sortedAdditionalInformationItems
              .filter((item) => !item.isDeleted)
              .map((item) => {
                const additionalItem = {
                  orderId: item.orderId,
                  comment: item.comment,
                  fileSummary: item.fileSummary,
                  notes: item.notes
                };
                if (item.sid) {
                  additionalItem.sid = item.sid;
                }
                return additionalItem;
              })
          );
        }
      }

      await this.submit(null, true, null, null, targetExcursionStepperHeading);
    },

    saveDraftByStudentMedicalSection() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.eventPluginCommonAdapter.registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_STUDENT_MEDICAL_AND_RATIOS_UPDATE,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash
      );
      this.saveDraft();
    },
    confirmOrSaveDraftByStudentMedicalSection() {
      // tlrc stands for: top level risk category
      const selectedHighRisk = this.$store.state.common.excursionDc.categories.find((tlrc) => {
        return tlrc.name === riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS;
      });

      if (selectedHighRisk && selectedHighRisk.CompleteChecklist) {
        const checklist = selectedHighRisk.CompleteChecklist;
        const checklistString = JSON.stringify(checklist);
        const checkboxCount = (checklistString.match(/"contentType":"checkbox"/g) || []).length;
        const tickedCheckboxCount = (checklistString.match(/"ticked":true/g) || []).length;
        this.highRiskChecklistsAllTicked = checkboxCount === tickedCheckboxCount;
      }

      this.highRiskChecklistsMessageDisplayed =
        selectedHighRisk && !this.highRiskChecklistsAllTicked;
      this.highRiskNumbersMessageDisplayed = this.highRiskChecklistsNumbersMessageDisplayed;

      if (this.highRiskChecklistsMessageDisplayed || this.highRiskNumbersMessageDisplayed) {
        this.highRiskChecklistsSkipConfirmationDialogDisplayed = true;
        this.$store.commit(types.COMMON_SET_EXCURSION_CONFIRMATION, false);
      } else {
        this.saveDraftByStudentMedicalSection();
      }
    },

    saveDraftByMandatoryChecklistsSection() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.eventPluginCommonAdapter.registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_MANDATORY_EXCURSION_CHECKLISTS_UPDATE,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash
      );
      this.mandatoryChecklistsContinueButtonDisabled = true;
      this.saveDraft();
    },
    confirmOrSaveDraftByMandatoryChecklistsSection() {
      const untickedMandatoryChecklistFound =
        this.$store.state.common.tickedMandatoryChecklists.some(
          (checklist) => checklist.ticked === false
        );
      if (untickedMandatoryChecklistFound) {
        this.mandatoryChecklistsSkipConfirmationDialogDisplayed = true;
        this.mandatoryChecklistsContinueButtonDisabled = false;
      } else {
        this.saveDraftByMandatoryChecklistsSection();
      }
    },

    saveDraftByRiskCategoriesSection() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.eventPluginCommonAdapter.registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_RISK_CATEGORIES_UPDATE,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash
      );

      const { apiDataDefaultJurisdiction, excursionDc } = this.$store.state.common;
      this.excursionCommonAdapter.registerSelectedRisksEvent(
        apiDataDefaultJurisdiction,
        excursionDc
      );
      this.saveDraft();
    },
    confirmOrSaveDraftByRiskCategoriesSection() {
      const { tickedRiskCategoryChecklists } = this.$store.state.common;
      const tickedRiskCategoryChecklistsKeys = Object.keys(tickedRiskCategoryChecklists).filter(
        (t) => t !== riskCategoryLabel.HIGH_RISK_STUDENT_MEDICAL_CONDITIONS
      );
      const untickedRiskCategoriesChecklistFound = tickedRiskCategoryChecklistsKeys.some(
        (t) => !tickedRiskCategoryChecklists[t]
      );

      if (tickedRiskCategoryChecklistsKeys.length === 0 || untickedRiskCategoriesChecklistFound) {
        this.riskCategoryChecklistsSkipConfirmationDialogDisplayed = true;
        this.mandatoryChecklistsContinueButtonDisabled = false;
      } else {
        this.saveDraftByRiskCategoriesSection();
      }
    },

    saveDraftByExcursionRiskManagementConfirmationSection() {
      const trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
      const trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
      this.$app.eventPluginCommonAdapter.registerTrackableEvent(
        trackableEvent.RISK_ASSESSMENT_EXCURSION_RISK_MANAGEMENT_CONFIRMATION_UPDATE,
        trackableEventBaseArgHash,
        trackableEventOptionArgHash
      );
      this.excursionStatusChanged = true;
      this.submit(excursionStatus.SUBMITTED, true);
    },

    async saveDraftOnMandatory(params, statusChanged, callback) {
      this.mandatoryChecklistSaveActionPerformed = true;
      await this.submit(params, statusChanged, callback);
    },

    incrementStep() {
      if (this.excursionSaveSuccessful) {
        this.step += 1;
      }
    },

    resetJurisdiction() {
      this.$store.commit(types.COMMON_SET_EXCURSION_JURISDICTION, '');
    },

    handleExcursionTypeSelectionChange() {
      this.resetJurisdiction();
      this.handleExcursionStartEndDateIntegrity();
    },

    clearExcursionStartEndDateSet() {
      this.excursionStartDate = '';
      this.excursionEndDate = '';
    },
    clear() {
      const currentExcursionName = this.name;
      const currentExcursionType = this.excursionType;
      const currentVendorExcursionSid = this.vendorExcursionSid;
      if (!this.isExcursionInitiatedFromPcg) {
        this.clearExcursionStartEndDateSet();
      }

      const updateAdditionalInformationList = this.additionalInformationItems.filter(
        (a) => a.sid && a.sid.length > 0
      );
      updateAdditionalInformationList.forEach((a) => {
        const localA = a;
        localA.isDeleted = true;
      });
      this.updateAdditionalInformationList(updateAdditionalInformationList);

      this.resetConfirmationDialogDisplayed = false;
      this.$v.$reset();

      const excludedAttributeList = [
        'excursionTypeChoiceList',
        'userAuthSchemeApiParam',
        'apiParam',
        'excursionDc',
        'excursionAdditionalInformationEnabled',
        'excursionBuilderButtonDisplayed',
        'excursionCreationButtonDisplayed',
        'excursionDestinationContentEnabled',
        'excursionDisplayReady',
        'excursionHighRiskStudentMedicalEnabled',
        'excursionLogoDisplayReady',
        'excursionPersonResponsibleEnabled',
        'excursionStudentFetchTriggered',
        'excursionStudentMedicalConditionsDisplayReady',
        'logoAttachmentUrl',
        'providerUpdateActionEnabled',
        'excursionSyncButtonDisplayed',
        'userInitials',
        'vendorClientLogoEnabled',
        'vendorExcursionSidInputEnabled',
        'excursionStartEndDateSetEnabled',
        'clientSettingRiskMatrix',
        'clientUsersTabDisplayed',
        'usersTabDisplayed',
        'clientsTabDisplayed',
        'clientSiteSettingsButtonDiplayed',
        'clientSelfGroupManagementDisplayed',
        'publicSchoolFeatureSetEnabled',
        'riskCategoryManagementMenuItemDisplayed',
        'operationManagementMenuItemDisplayed',
        'studentManagementSubjectManagementDisplayed'
      ];
      const argHash = {
        excludedAttributeList,
        storeModuleName: ''
      };
      this.$store.commit(types.COMMON_RESET_STORE_MODULE_STATE, argHash);
      this.$store.commit(types.COMMON_RESET_EXCURSION_STORE_STATE, argHash);
      this.$store.commit(types.COMMON_SET_EXCURSION_STUDENT_MEDICAL_CONDITIONS, []);
      this.$store.commit(types.COMMON_SET_STAFF_TO_STUDENT_GENDER_RATIO_INFO, '');

      const isExcursionInitiatedFromPcg =
        this.$store.state.common.userAuthSchemeApiParam?.['vendor-sid'] === '10000001' &&
        !!currentVendorExcursionSid &&
        !this.newExcursionDetected;

      if (isExcursionInitiatedFromPcg) {
        this.vendorExcursionSid = this.vendorExcursionSidInputEnabled
          ? currentVendorExcursionSid
          : this.vendorExcursionSid;
        this.name = currentExcursionName;
        this.excursionType = currentExcursionType;
      }
      this.step = 1;
    },
    turnToStep(invalid, step) {
      if (this.excursionSaveActionInProgress) {
        return;
      }

      if (step > 1) {
        if (invalid) {
          return;
        }
      }

      if (!this.step) {
        this.step = 1;
      }

      if (this.step < step) {
        return;
      }
      this.step = step;
    },

    redirectToExcursionBuilder() {
      if (this.excursionSaveSuccessful) {
        window.location.reload();
      } else {
        this.genericDialogDisplayed = false;
      }
    },

    async clearCategoriesOnExcursionTypeUpdate() {
      this.excursionTypeWarningDisplayed = false;
      this.callSaveDraft = true;
      this.handleExcursionStartEndDateIntegrity();
      this.$store.commit(types.COMMON_SET_ALL_RISKS, []);

      // Clear and delete all attachments
      if (this.additionalInformationItems && this.additionalInformationItems.length > 0) {
        this.additionalInformationItems.forEach((item) => {
          const localItem = item;
          localItem.isDeleted = true;
        });

        const additionalInformationItemsRemoved = this.$app.lodash.cloneDeep(
          this.additionalInformationItems
        );
        await this.updateAdditionalInformationList(additionalInformationItemsRemoved);
        this.$store.commit(types.COMMON_SET_EXCURSION_DC_ADDITIONAL_INFORMATION_NOTES, []);
      }

      this.saveDraft();
    },

    cancelExcursionTypeUpdate() {
      this.excursionTypeWarningDisplayed = false;
      this.buttonLoaderDisplayed = false;
      this.$store.commit(types.COMMON_SET_EXCURSION_TYPE, '');
      this.newExcursionType = '';
      this.$nextTick(() => {
        this.$store.commit(
          types.COMMON_SET_EXCURSION_TYPE,
          this.$store.state.common.originalTypeId ? this.$store.state.common.originalTypeId : ''
        );
        this.handleExcursionStartEndDateIntegrity();
      });
    },

    async updateAdditionalInformationList(updateAdditionalInformationList) {
      if (!updateAdditionalInformationList || updateAdditionalInformationList.length === 0) {
        return;
      }

      updateAdditionalInformationList.forEach(async (item) => {
        if (item.sid) {
          if (item.isDeleted) {
            this.$refs.additionalInfoList.deleteAttachment(item);
          } else {
            const requestData = {};

            requestData.metadata = {
              summary: item.fileSummary,
              description: item.comment,
              orderId: item.orderId
            };

            if (
              typeof requestData.metadata.summary !== 'undefined' &&
              requestData.metadata.summary.trim() === ''
            ) {
              delete requestData.metadata.summary;
            }

            if (
              typeof requestData.metadata.description !== 'undefined' &&
              requestData.metadata.description.trim() === ''
            ) {
              delete requestData.metadata.description;
            }
            const argHash = {
              queryParam: this.$store.state.common.apiParam,
              excursionId: this.excursionId,
              attachmentSid: item.sid,
              payload: requestData,
              store: this.$store
            };
            await this.$app.stbApiAdapter.putExcursionAttachments(argHash);
          }
        }
      });
    },

    setAdditionalInformationValid(valid) {
      this.additionalInformationValid = valid;
    },

    clientLogoMarginDisplayed() {
      const { query } = this.$route;
      if (query.referrer || query['vendor-sid'] || query['oauth-signature']) {
        return false;
      }
      return true;
    },

    displayExcursionSyncButton() {
      const { query } = this.$route;
      const excursionId = this.$route.params ? this.$route.params.id : '0';

      let excursionSyncButtonDisplayed = false;
      if (
        query &&
        query.referrer &&
        query.referrer.toLowerCase() === 'pcg' &&
        excursionId > 0 &&
        this.excursionSyncButtonDisplayed
      ) {
        excursionSyncButtonDisplayed = true;
      }

      return excursionSyncButtonDisplayed;
    },

    async excursionSync() {
      this.excursionSyncConfirmationDialogDisplayed = false;
      this.excursionSyncActionInProgress = true;
      await this.submit(null, false, null, 'excursionSync');
      this.excursionSyncActionInProgress = false;
    },

    async downloadFile(proformaTemplateFile) {
      const apiQuery = JSON.parse(JSON.stringify(this.$store.state.common.apiParam));

      const argHash = {
        queryParam: apiQuery,
        sid: proformaTemplateFile.sid,
        store: this.$store
      };
      const response = await this.$app.stbApiAdapter.getAttachments(argHash);
      if (response.data) {
        const attachments = response.data;
        if (attachments.length > 0) {
          const file = attachments[0];
          if (file && file.computed && file.metadata) {
            const filePath = file.computed.url.replace(/^\//, '');
            const apiUrl = this.$app.env.API_URL.replace(/\/$/, '');
            const fileUrl = `${apiUrl}/${filePath}`;
            const { filename } = file.metadata;
            this.excursionCommonAdapter.downloadFile(fileUrl, filename, this.$app.deviceDetector);
          }
        }
      }
    },

    pageLoadingAnimationDisplayed() {
      let loadingAnimationDisplayed = false;

      if (this.isInitClientConfigInProgress) {
        loadingAnimationDisplayed = true;
      } else if (
        this.isAuthenticated &&
        (!this.excursionDisplayReady || !this.excursionLogoDisplayReady)
      ) {
        loadingAnimationDisplayed = true;
      }

      return loadingAnimationDisplayed;
    },

    saveDraftByExcursionStepperHeading(targetExcursionStepperHeading) {
      let trackableEventBaseArgHash;
      let trackableEventOptionArgHash;
      switch (targetExcursionStepperHeading) {
        case excursionStepperHeading.EXCURSION_DETAILS:
          this.saveDraft(targetExcursionStepperHeading);
          break;

        case excursionStepperHeading.STUDENT_MEDICAL_AND_RATIOS:
          this.confirmOrSaveDraftByStudentMedicalSection();
          break;

        case excursionStepperHeading.MANDATORY_EXCURSION_CHECKLISTS:
          this.confirmOrSaveDraftByMandatoryChecklistsSection();
          break;

        case excursionStepperHeading.RISK_CATEGORIES:
          this.confirmOrSaveDraftByRiskCategoriesSection();
          break;

        case excursionStepperHeading.ADDITIONAL_INFORMATION:
          trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
          trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
          this.$app.eventPluginCommonAdapter.registerTrackableEvent(
            trackableEvent.RISK_ASSESSMENT_ADDITIONAL_INFORMATION_UPDATE,
            trackableEventBaseArgHash,
            trackableEventOptionArgHash
          );
          this.saveDraft(targetExcursionStepperHeading);
          break;

        case excursionStepperHeading.EXCURSION_RISK_ASSESSMENT:
          trackableEventBaseArgHash = this.getRiskAssessmentTrackableEventBaseArgHash();
          trackableEventOptionArgHash = this.getRiskAssessmentTrackableEventOptionArgHash();
          this.$app.eventPluginCommonAdapter.registerTrackableEvent(
            trackableEvent.RISK_ASSESSMENT_EXCURSION_RISK_ASSESSMENT_UPDATE,
            trackableEventBaseArgHash,
            trackableEventOptionArgHash
          );
          this.saveDraft();
          break;

        case excursionStepperHeading.EXCURSION_RISK_MANAGEMENT_CONFIRMATION:
          this.saveDraftByExcursionRiskManagementConfirmationSection();
          break;

        default:
          this.saveDraft();
          break;
      }
    },
    async updateRiskMatrix() {
      const { query } = this.$route;
      const cbResponse = await this.$app.stbApiAdapter.getClientRiskMatrix({
        queryParam: query,
        store: this.$store
      });

      if (!cbResponse.error) {
        const {
          data: { likelihood, riskRating, consequence }
        } = cbResponse;
        this.$store.commit(types.COMMON_SET_CLIENT_SETTING_RISK_MATRIX, {
          likelihood,
          riskRating,
          consequence
        });
        this.$store.commit(types.COMMON_SET_RISK_MATRIX_SELF_DEFINED_DETECTED, true);
      } else {
        this.$store.commit(types.COMMON_SET_CLIENT_SETTING_RISK_MATRIX, {
          likelihood: defaultRiskMatrix.likelihood,
          consequence: defaultRiskMatrix.consequence,
          riskRating: defaultRiskMatrix.riskRating
        });
        this.$store.commit(types.COMMON_SET_RISK_MATRIX_SELF_DEFINED_DETECTED, false);
      }
    },
    setChecklistAttestationEnabled(argChecklistAttestationEnabled) {
      this.checklistAttestationEnabled = argChecklistAttestationEnabled;
    }
  },

  async mounted() {
    this.excursionCommonAdapter = new this.$app.excursionCommon.Adapter({
      store: this.$store,
      app: this.$app
    });
    this.$store.commit(types.COMMON_SET_SHOW_EXCURSION_STEPPER, true);
    this.$store.commit(types.COMMON_SET_PREV_ROUTE_NAME, 'SafeTripBuilder');
  },

  watch: {
    async step() {
      if (
        (this.step === 3 && !this.excursionHighRiskStudentMedicalEnabled) ||
        (this.step === 4 && this.excursionHighRiskStudentMedicalEnabled)
      ) {
        const { query } = this.$route;
        await this.$store.dispatch(
          'common/getAllRisksByExcursionTypeName',
          {
            stbApiAdapter: this.$app.stbApiAdapter,
            store: this.$store,
            queryParam: query,
            excursionTypeName: this.excursionType
          },
          query
        );
      } else if (
        (this.step === 4 && !this.excursionHighRiskStudentMedicalEnabled) ||
        (this.step === 5 && this.excursionHighRiskStudentMedicalEnabled)
      ) {
        const genericFrcs = this.excursionCommonAdapter.getRiskCategoryParams();
        const activityFrcs = this.excursionCommonAdapter.getActivityParams();
        // frc stands for: factor risk category
        const frcs = genericFrcs.concat(activityFrcs);
        frcs.push([this.$store.state.common.excursionDc.typeId]);

        const { query } = this.$route;
        const argHash = {
          stbApiAdapter: this.$app.stbApiAdapter,
          store: this.$store,
          queryParam: query,
          payload: {
            'risk-categories': frcs
          }
        };
        if (frcs) {
          await this.$store.dispatch('common/postPredepartureStrategies', argHash);
        }
      } else if (
        !this.$store.state.common.allRisks ||
        this.$store.state.common.allRisks.length <= 0
      ) {
        await this.$store.dispatch('common/getAllRisksByExcursionTypeName', {
          stbApiAdapter: this.$app.stbApiAdapter,
          store: this.$store,
          queryParam: this.$route.query,
          excursionTypeName: this.$store.state.common.excursionDc.typeId
        });
      }
    },
    selectedRisks() {
      if (this.firstTimeLoaded) {
        this.valuesChanged = true;
      } else {
        this.firstTimeLoaded = true;
      }
    },
    mixedMedicalConditionHash() {},
    excursionStartDate() {
      this.handleExcursionStartEndDateIntegrity();
    },
    async isInitClientConfigInProgress(newVal, oldVal) {
      if (oldVal && !newVal) {
        if (!this.isAuthenticated) return;
        await this.updateRiskMatrix();
      }
    }
  }
};
</script>
