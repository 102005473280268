<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div>
    <cs-form-dialog
      v-model="propClientUserImportModalDisplayed"
      id="client-user-import-dialog"
      heading="Client Users Import"
      :primaryAction="{
        label: 'Save',
        eventName: 'primary-click',
        disabled: clientUserImportModalActionInProgress,
        loading: clientUserImportModalActionInProgress
      }"
      :secondaryAction1="{
        label: 'Cancel',
        eventName: 'secondary1-click'
      }"
      @primary-click="saveClientUserImportModal"
      @secondary1-click="resetThenCloseClientUserImportModal"
      @input="onClientUserImportModalDisplayedChange"
    >
      <template v-slot:cs-form-dialog-content>
        <v-form ref="client-user-import-modal--form" lazy-validation>
          <v-container grid-list-md>
            <div class="client-row client-modal-text">
              <v-flex xs12>
                <v-layout wrap>
                  <v-flex xs12 style="margin-bottom: 20px">
                    <v-layout wrap>
                      <v-flex xs12>
                        <div class="client-modal-text">
                          <span
                            >User list import/export is limited to roles: admin, admin assistant,
                            approver, and user. Other roles are excluded.</span
                          >
                        </div>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs12>
                        <!-- eslint-disable-next-line vuejs-accessibility/anchor-has-content -->
                        <a href="/public/User_Import_Template.csv" target="_blank">
                          <cs-button
                            id="client-user-import-modal--user-list-template-download-button"
                            primary
                            class="excursion-stepper-upload-btn"
                            style="margin-bottom: 20px"
                            :disabled="clientUserImportModalActionInProgress"
                            leftIcon="fa fa-download"
                            label="Download User List CSV Template"
                            :loading="clientUserImportModalActionInProgress"
                          ></cs-button>
                        </a>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs12>
                        <cs-button
                          id="client-user-import-modal--user-list-export-csv-button"
                          primary
                          class="excursion-stepper-upload-btn"
                          style="margin-bottom: 20px"
                          :disabled="clientUserImportModalActionInProgress"
                          leftIcon="fa fa-download"
                          label="Download User List CSV (Export)"
                          :loading="clientUserImportModalActionInProgress"
                          @click="handleUserImportDownloadUserExportClick"
                        ></cs-button>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12>
                    <v-layout wrap>
                      <v-flex xs12>
                        <input
                          :id="clientUserImportFileInputId"
                          style="display: none"
                          type="file"
                          :accept="clientUserImportFileExtensionAttr"
                          @change="handleUserImportFileInputChange($event)"
                        />
                        <cs-button
                          id="client-user-import-modal--user-import-file-selection-button"
                          primary
                          class="excursion-stepper-upload-btn"
                          :disabled="clientUserImportModalActionInProgress"
                          leftIcon="fa fa-upload"
                          label="Upload User List CSV"
                          :loading="clientUserImportModalActionInProgress"
                          @click="handleUserImportFileSelectionClick"
                        ></cs-button>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    v-if="clientUserImportFileInfo"
                    xs12
                    class="form-label template-file-font"
                  >
                    File Name
                  </v-flex>
                  <v-flex v-if="clientUserImportFileInfo" xs12>
                    <v-layout wrap>
                      <v-flex xs12 style="margin-left: 3px">
                        {{ clientUserImportFileInfoName }}
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex
                    v-if="clientUserImportFileInputErrorMessage"
                    class="client-user-import-modal-error"
                  >
                    {{ clientUserImportFileInputErrorMessage }}
                  </v-flex>
                </v-layout>
              </v-flex>
            </div>
          </v-container>
        </v-form>
      </template>
    </cs-form-dialog>
    <cs-form-dialog
      v-model="clientUserImportGenericDialogDisplayed"
      id="dlg-client-user-import-generic"
      :heading="genericDialogHeader"
      bodyId="client-user-import-modal--generic-dialog"
      :primaryAction="{ label: 'OK', eventName: 'primary-click' }"
      @primary-click="clientUserImportGenericDialogDisplayed = false"
    >
      <template v-slot:cs-form-dialog-content>
        <v-card-text>
          <div v-safe-html="clientUserImportGenericDialogMessage"></div>
        </v-card-text>
      </template>
    </cs-form-dialog>
  </div>
</template>

<script>
import { populateErrorMessage } from '../lib/errorMessages';

export default {
  name: 'ClientUserImportDialog',

  props: {
    propClientUserImportModalDisplayed: Boolean
  },
  data() {
    return {
      clientUserImportFileExtensionAttr: `.csv`,
      clientUserImportFileMimeAllowed: ['text/csv'],
      clientUserImportFileInfo: '',
      clientUserImportFileInputErrorMessage: '',
      clientUserImportFileInputId: 'client-user-import-modal--user-import-file-input',
      clientUserImportGenericDialogDisplayed: false,
      clientUserImportGenericDialogMessage: '',
      clientUserImportModalActionInProgress: false,
      genericDialogHeader: 'Client Users Import - Status'
    };
  },
  computed: {
    clientUserImportFileInfoName() {
      return this.clientUserImportFileInfo && this.clientUserImportFileInfo.name
        ? this.clientUserImportFileInfo.name
        : '';
    }
  },
  methods: {
    onClientUserImportModalDisplayedChange(boolOpen) {
      if (!boolOpen) {
        this.resetThenCloseClientUserImportModal();
      }
    },
    resetClientUserImportFileInputErrorMessage() {
      this.genericDialogHeader = 'Client Users Import - Status';
      this.clientUserImportGenericDialogMessage = '';
      this.clientUserImportFileInputErrorMessage = '';
    },
    resetThenCloseClientUserImportModal() {
      this.resetClientUserImportFileInputErrorMessage();
      this.$refs['client-user-import-modal--form'].reset();
      this.clientUserImportFileInfo = '';

      const clientUserImportFileInputElement = document.getElementById(
        this.clientUserImportFileInputId
      );
      clientUserImportFileInputElement.value = null;

      this.$emit('modalClosed', false);
    },
    handleUserImportFileSelectionClick() {
      document.getElementById(this.clientUserImportFileInputId).click();
    },
    showDialog(message, type = null) {
      this.genericDialogHeader =
        type === 'error' ? 'Client Users Import - Error' : 'Client Users Import - Status';
      this.clientUserImportGenericDialogMessage = message;
      this.clientUserImportGenericDialogDisplayed = true;
    },
    handleUserImportFileInputChange(event) {
      this.resetClientUserImportFileInputErrorMessage();

      if (event && event.target && event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];
        const filename = file.name;
        const extensionType = filename.split('.').pop();
        const fileType = file.type;

        if (
          this.clientUserImportFileExtensionAttr.indexOf(extensionType) < 0 &&
          !this.clientUserImportFileMimeAllowed.includes(fileType)
        ) {
          this.showDialog(
            `Invalid file type: <span class="debug-error-message">${filename}</span> <br>
            Please select a <span class="debug-error-message">.csv</span> file.`,
            'error'
          );
          document.getElementById(this.clientUserImportFileInputId).value = '';
          return;
        }
        // eslint-disable-next-line prefer-destructuring
        this.clientUserImportFileInfo = event.target.files[0];
      }
    },
    async saveClientUserImportModal() {
      this.resetClientUserImportFileInputErrorMessage();

      if (this.clientUserImportFileInfo) {
        const formData = new FormData();
        formData.append('file', this.clientUserImportFileInfo);

        const argHash = {
          payload: formData,
          store: this.$store
        };

        this.clientUserImportModalActionInProgress = true;
        const cbResponse = await this.$app.stbApiAdapter.postClientUserImports(argHash);
        this.clientUserImportModalActionInProgress = false;

        this.resetThenCloseClientUserImportModal();

        if (!cbResponse.error) {
          this.showDialog('User list has been imported.');

          this.$emit('userListImported');
        } else {
          let dialogMessage = 'User list cannot be imported.';

          if (cbResponse.refinedMessage) {
            dialogMessage = `${dialogMessage} ${cbResponse.refinedMessage}.`;
          }

          this.showDialog(
            populateErrorMessage({
              message: dialogMessage,
              store: this.$store
            })
          );
        }
      } else {
        this.clientUserImportFileInputErrorMessage = 'User List CSV is required';
      }
    },
    async handleUserImportDownloadUserExportClick() {
      const argHash = {
        store: this.$store
      };

      this.clientUserImportModalActionInProgress = true;
      const cbResponse = await this.$app.stbApiAdapter.getClientUserExports(argHash);
      this.clientUserImportModalActionInProgress = false;

      if (!cbResponse.error && cbResponse.data) {
        const blob = new Blob([cbResponse.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'User_Export.csv';
        link.click();
      } else {
        let dialogMessage = 'User list cannot be exported.';

        if (cbResponse.refinedMessage) {
          dialogMessage = `${dialogMessage} ${cbResponse.refinedMessage}.`;
        }

        this.showDialog(
          populateErrorMessage({
            message: dialogMessage,
            store: this.$store
          })
        );
      }
    }
  },
  mounted() {},
  watch: {}
};
</script>
