import getters from './getters';
import actions from './actions/index';
import mutations from './mutations';

const state = {
  clearModuleCacheEnabled: false,
  ceEnvironment: {}
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
